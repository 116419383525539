import React from "react";
import AcumuladorDeLikes from "./acumulador_de_likes";
import AcumuladorDePuntos from "./acumulador_de_puntos";
import ComentadorNato from "./comentador_nato";
import PosteadorNato from "./posteador_nato";
import ShouteadorNato from "./shouteador_nato";
import NoPerdamosLaEsperanza from "./no_perdamos_la_esperanza";
import MundialDeFutbol from "./mundial_de_futbol";
import IconDefault from "./default";
import Navidad from "./navidad";
import GranPatriarca from "./gran_patriarca";
import TaringaDead from "./taringa_dead";
import Acolito from "./acolito";
import Trofeo from "./trofeo";

export const MedallaAllIcons = ({ icon, ...args }: any) => {
  if (icon === "acumulador_de_likes") return <AcumuladorDeLikes {...args} />;
  if (icon === "acumulador_de_puntos") return <AcumuladorDePuntos {...args} />;
  if (icon === "comentador_nato") return <ComentadorNato {...args} />;
  if (icon === "posteador_nato") return <PosteadorNato {...args} />;
  if (icon === "shouteador_nato") return <ShouteadorNato {...args} />;
  if (icon === "no_perdamos_la_esperanza") return <NoPerdamosLaEsperanza {...args} />;
  if (icon === "mundial2022") return <MundialDeFutbol {...args} />;
  if (icon === "trofeo") return <Trofeo {...args} />;
  if (icon === "navidad") return <Navidad {...args} />;
  if (icon === "taringa_dead") return <TaringaDead {...args} />;
  if (icon === "gran_patriarca") return <GranPatriarca {...args} />;
  if (icon === "acolito") return <Acolito {...args} />;
  return <IconDefault {...args} />;
};
