import React from 'react';

import RemoteUpdate from "../../../utils/remoteupdate";

type BarMessageProps = {
    Container?: any
}
const BarMessage:React.FC<BarMessageProps> = ({Container})=>{
    return (
        <RemoteUpdate className="message_bar" _key="oawngowañngknañwkgo" content={(msg="")=>{
            if(!msg) return null;
            const message = (
                <div className="center message_bar">
                    <span>{msg}</span>
                </div>
            );

            if(Container) return <Container>{message}</Container>
            return message;
        }}>

        </RemoteUpdate>
    );
}

export default BarMessage;