import React, { Fragment } from "react";
import { PImg } from "../../../../../../../contexts/EnvironmentContext/useMedia";
import { useCssHandles } from "../../../../../../../hooks/useCssHandles";
import "./index.scss";

export const Event_revolucion_de_mayo:React.FC = ()=>{
    const styles = useCssHandles("logo-event-trabajador",[
        'bandera_ar',
        'escarapela',
        'empanada',
        'mate'
    ] as const);

    return <Fragment>
    <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.bandera_ar} className={styles.bandera_ar} alt="bandera_ar" src="/resources/flags/ar.png"/>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.mate} className={styles.mate} alt="mate" src="/resources/logos/events/revolucion_de_mayo/mate.png"/>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.empanada} className={styles.empanada} alt="empanada" src="/resources/logos/events/revolucion_de_mayo/empanadas.png"/>
        
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.escarapela} className={styles.escarapela} alt="escarapela" src="/resources/logos/events/revolucion_de_mayo/escarapela.png"/>
        
    </Fragment>
}