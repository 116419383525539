import React, {lazy} from "react";
import { MemoHeightProvider, WidthRead } from "../../../../hooks/useMemoHeight";
import { ShoutOfLink } from "../../../../pages/components/Shout/item-shout";
import { refEnv } from "../../../../contexts/EnvironmentContext";
import { PItemProps } from "./PMain";
import { IPost, IShout } from "../../../../hooks/apis/usePost";
import { Link } from "react-router-dom";
import { Imagen } from "../../../Imagen/Imagen";
import { ItemPost } from "../../../../pages/components/Post/components/ItemPost";
import { ItemPostReco } from "../../../../pages/components/Post/components/ItemPostReco";
var he = require('he');

// import PTwitterTweetEmbed from "../utils/TwitterTweetEmbed";
const PTwitterTweetEmbed = lazy(() => import("../utils/TwitterTweetEmbed"));


function extractHostname(url:string) {
    var hostname:string;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

type INatives = {
    type: "shout",
    content: IShout
} |  {
    type: "post",
    content: IPost
}

export type PLinkProps = PItemProps<{
    _id: string,
    url: string,
    metas: Record<string, any>,
    myself: boolean,
    native: INatives
}, {}>;
export default ({content, ...setting}:PLinkProps)=>{
    //if(!content||!content.url) return null;
    let {url,metas,myself,native} = content;
    //if(!url) return null;
    if(myself) url = window.origin + content.url;
    
     if(myself && native){
        if(native.type === "shout" && native.content){
           const sharedId= `shared-${native.content?._id}`
            return (<div className="media-shout" id={sharedId}>
                <MemoHeightProvider name="shared">
                    <WidthRead/>
                    <ShoutOfLink shout={native.content}></ShoutOfLink>
                </MemoHeightProvider>
            </div>);
        }
        if(native.type === "post" && native.content){
            const url_img = native.content.portada;
            const title = native.content.title;
            const description = ` ${native.content.meta_comments}`;
            const url2 = `/post/${native.content._id}/${native.content.slug}`

            return <div className="h-full text-left">
                <ItemPostReco post={native.content as any} insertado/>
            </div>
        }
    }

    const twitter_url = /\/\/(?:twitter|x)\.com\/.*\/status\/(\d+)/.exec(url)
    if(twitter_url && !refEnv().tag.is("disableTwits")) return <PTwitterTweetEmbed twittId={twitter_url[1]} shoutId={content._id}/> 

    if(twitter_url && !metas?.title){
        metas = {
            "title": "Twitter",
            "description": "Desde las últimas noticias hasta temas de entretenimiento, deportes y política: obtén las historias completas con todos los comentarios en directo.",
            "images": [
              "https://imagedelivery.net/GKo274lICZsnSFlvHgcgdw/409b0338-4743-4579-84e0-ad65a25a7c00/public"
            ],
            "sitename": "X (formerly Twitter)",
            "favicon": "https://abs.twimg.com/favicons/twitter.3.ico",
            "duration": 67,
            "domain": "twitter.com",
            "url": "https://twitter.com/?lang=es",
            "source": "jsonlink"
          }
    }


    if(!metas) {
        metas= {
            "description": "Esta sitio no ofrece ninguna descripción.",
            "images": [],
            "url": "https://x.com/CarlosPagniOKK/status/1758823358244774251?s=20",
            "sitename": null,
            "favicon": "https://x.com/favicon.ico",
            "duration": 420,
            "domain": "x.com",
            "source": "fb",
            "_disabled": true
          }
    }

    var {title="",description="",image=[], images=[],canonical="", _disabled=false} = metas;
    var url_img:any = [];

    if(typeof(image) === "string")  url_img.push(image);
    else if(Array.isArray(image))  url_img.push(...image);
    else if(typeof(image) === "object")  url_img.push(image);

    if(typeof(images) === "string")  url_img.push(images);
    else if(Array.isArray(images))  url_img.push(...images);
    else if(typeof(image) === "object")  url_img.push(image);
    if(url_img.length>0) url_img = url_img[0];
    if(typeof url_img !== "string") {
        if(typeof url_img === "object" && "url" in url_img){
            url_img = url_img.url
        
        } else url_img = undefined
    }

    if(typeof description === typeof([]) && description.length>0) description = description[0];

    return <div className="media media-link" style={{textAlign: "left"}}>
        {url_img?(
            <a className="media-link-a_photo mr-2" href={url}>
                <img className="media-link-photo" src={url_img} />
            </a>
        ):null}
        <div className="content media-link-content">
            {title?(
                <a href={url}><h4 className="media-link-title line-clamp-3" title={title}>{title}</h4></a>
            ):null}
            {description?(
                <div className="media-link-description" style={_disabled?{color: "#317c9e"}:{}}>
                    {he.decode(description)}
                </div>
            ):null}
            <div className="media media-link-linkcontent items-center">
                <img className="media-link-icon ml2 pr2" src={`https://www.google.com/s2/favicons?domain_url=${extractHostname(url)}`}/>
                <a href={url} className='text-box-1 overflow-hidden ' title={url}>{url}</a>
            </div>
        </div>
    </div>
}