import React from "react"
import { PlaceholderPulse } from "../../../../components/utils/PlaceholderPulse"
import { RepeatNode } from "../../../../components/utils/RepeatChildren"
import cn from "classnames"
import { Panel } from "../../../../components/styleguide/components/Panel"

export const PlaceholderShout:React.FC<{addLine?: boolean, className?:string, panel?:boolean}> = ({addLine, className="", panel})=>{
    const Content = <>
        <div className="flex items-start h-[40px] relative">
            <PlaceholderPulse className="w-[40px] h-[40px]"/>
            <div className="absolute left-[calc(40px+.5rem)] right-0 ">
                <PlaceholderPulse.RangeSize className=" mr-3 h-[16px]" rangeWidth={[80, 150]}/>
                <div className="flex flex-col space-y-2 mt-2" >
                    <PlaceholderPulse.RangeSize className=" mr-3 h-[16px]" rangeWidth={[50, 50]}/>
                </div>
            </div>
        </div>
        <PlaceholderPulse.RangeSize className="h-[20px] mr-3 mt-2" rangeWidth={[100, 200]}/>
        <PlaceholderPulse.RangeSize className="w-full mt-2" rangeHeight={[200, 350]}/>
        <div className="relative mt-2">
            <PlaceholderPulse className="w-[50px] h-[25px] mr-2"/>
            <PlaceholderPulse className="w-[50px] h-[25px] absolute left-[calc(50px+.5rem)] top-0 mr-2"/>
            <PlaceholderPulse className="w-[50px] h-[25px] absolute left-[calc(100px+1rem)] top-0 mr-2"/>
            <PlaceholderPulse className="w-[50px] h-[25px] absolute right-0 top-0"/>
        </div>
    </>

    return <>
        {panel?(
            <Panel padding="none" className={cn("p-2",className)}>
                {Content}
            </Panel>
        ):(
            <div className={cn(className)}>
                {Content}
            </div>
        )}
        {addLine && <div className="h-4"/>}
    </>
}

export const PlaceholderShouts:React.FC<{count: number, className?:string, itemProps?: React.ComponentProps<typeof PlaceholderShout>}> = ({count, className, itemProps})=>{
    return <div className={className}>
        {RepeatNode(count, (_, last)=> <PlaceholderShout addLine={!last} {...itemProps}/>)}
    </div>
}