import { ManagerOptions, SocketOptions, io } from "socket.io-client";
import { SocketType } from "..";
import { TabChannel } from "../TabChannel";

export class SocketProxy{
    
    mode: "loading" | "socket" | "proxy" = "loading"
    lossed:boolean
    socket:SocketType
    get isMaster(){return this.mode == "socket"}
    get isLoading(){return this.mode == "loading"}

    constructor(public opts?: Partial<ManagerOptions & SocketOptions>){
        TabChannel.setChannel((event) => this.eventChannel(event));
        this.waitSing()
    }

    waitSing(){
        const winSing = ()=>{
          if(this.isMaster) return
          this.modoSocket()
        }
      
        TabChannel.postMessage({ type: 'IS_MASTER' });
        setTimeout(winSing, 500 + (Math.random()*500));
    }
    
    eventChannel(event: (Parameters<Parameters<typeof TabChannel["setChannel"]>[0]>[0])){
        const v = event.data
        if (v.type === 'IS_MASTER' && this.isMaster) TabChannel.postMessage({ type: 'MASTER_RESPONSE' });
        if (v.type === 'MASTER_RESPONSE') return this.modoProxy()
        if (v.type === 'WIN_MASTER') return this.modoProxy()
        if (v.type === 'NO_MASTER' ) this.retry()
        if (v.type === 'PROXY_EMIT' ) this.emit(v.eventName, ...v.eventArgs)
        if (v.type === 'PROXY_IN' ) this.exec_on(v.eventName, ...v.eventArgs)
    }

    retry(){
        setTimeout(()=>{
            this.lossed = true
            this.waitSing()
        }, 1000)
    }

    canToMode(mode: SocketProxy["mode"]){
        const lossed = this.lossed
        this.lossed = false
        if(!lossed) return this.mode == "loading";
        return this.mode != mode;
    }
    modoProxy(){
        if(!this.canToMode("proxy")) return
        this.mode = "proxy"
        console.log("✅ Proxy")
    }
    modoSocket(){
        if(!this.canToMode("socket")) return
        console.log("✅ Socket")
        this.mode = "socket"
        TabChannel.postMessage({ type: 'WIN_MASTER' });
        
        // Iniciamos el socket
        this.socket = io(this.opts);
        this.socket.onAny((event, ...args)=>{
            this.exec_on(event, ...args)
            TabChannel.postMessage({type: "PROXY_IN", "eventName": event, eventArgs: args})
        })
        
        // Añadimos el evento para cuando se cierra por cerrar la pagina
        window.addEventListener('beforeunload', ()=>{
            TabChannel.postMessage({ type: 'NO_MASTER' });
            return false;
        });
    }

    list:{key:string, fun:any}[] = []
    exec_on(event:string, ...args:any){
        console.log("▶️",event, ...args)
        const item = this.list.find(i=> i.key == event)
        if(!item) return
        item.fun(...args)
    }
    on(key:Parameters<SocketType["on"]>[0], fun:Parameters<SocketType["on"]>[1]){
        // console.log("➡️",key)
        const item = this.list.find(i=> i.key == key)
        if(item) item.fun = fun
        else this.list.push({key, fun})
    }

    emit(ev:any, ...args:any){
        if(this.mode == "socket"){
            this.socket.emit(ev, ...args)
        }
        if(this.mode == "proxy"){
            TabChannel.postMessage({type: "PROXY_EMIT", "eventName": ev, eventArgs: args})
        }
    }

    disconnect(){
        if(this.mode == "socket"){
            this.socket.disconnect()
        }
    }
}