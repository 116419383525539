import React from "react"
import { Scrolling, ScrollFetcherType } from "../../../../components/utils/Scrolling"

export type NextIdProps = {
    __pageSize: number,
    __nextId?: string 
}
export type NextIdItemProps = {
    _id: string,
}

export type ScrollByNextIdServer<T> = {
    isEnd: boolean
    list: T[]
    nextId: string
}

export type ScrollByNextIdProps = Omit<React.ComponentProps<typeof Scrolling>, "fetcher" | "initialSetting" | "children">  & {
    initialSetting: NextIdProps,
    fetcher: (props: NextIdProps)=> Promise<{
        newItems: NextIdItemProps[],
        nextId: string
        hasMore: boolean
    }>,
    children: (items: unknown[])=> JSX.Element
}

export const ScrollByNextId:React.FC<ScrollByNextIdProps> = ({
    children, 
    fetcher, 
    initialSetting, 
    ...args
})=>{
    
    const newFetcher:ScrollFetcherType<NextIdProps, NextIdItemProps> = (params, state, dispatch)=>{
        fetcher(params).then(({newItems, nextId, hasMore})=>{
            dispatch({
                type: "GET_OK",
                newItems: newItems,
                updateSetting: newItems.length > 0?{
                    __nextId: nextId
                }:{},
                hasMore
            })
        }).catch(()=> 
            dispatch({type: "GET_ERROR"})
        )
    }

    return <Scrolling 
        {...args}
        initialSetting={initialSetting} 
        fetcher={newFetcher} 
        children={children}
    />
}