import React from "react";
import Circulo from "../resources/Circulo";
import { MedallaAllIcons } from "../icons";
import cn from "classnames";
const Colr = require("colr");

const NIVEL_COLORS = [
  "#FFF",
  "#AEE5FF",
  "#BEFFB5",
  "#8ACCFF",
  "#FFB4B4",
  "#FFC387",
];

export const MedallaIconWithNivel: React.FC<
  React.SVGProps<SVGSVGElement> & {
    nivel: number;
    color: string;
    scale?: number;
    icon: string;
    active?: boolean
  }
> = ({ color, nivel, scale = 1.36, icon, className, active,  ...args }) => {
  const colors = {
    borde1: NIVEL_COLORS[nivel],
    // primary1: primary1,
    background1: color,
    backgroundShadow1: Colr.fromHex(color).darken(10).toHex(),
  };

  return (
    <svg
      {...args}
      xmlns="http://www.w3.org/2000/svg"
      width={29 * scale}
      height={29 * scale}
      className={cn({ "o-30": nivel === 0 }, className)}
    >
      <g transform={`scale(${scale}), translate(-4 -4)`}>
        <Circulo {...colors}>
          <g transform="translate(8.4 7.5)">
            <MedallaAllIcons icon={icon} {...colors} />
          </g>
        </Circulo>
      </g>
    </svg>
  );
};
