import React from "react";
import { useMediaQuery } from "react-responsive";
import "./index.scss";
import { useHandlerResize } from "../../hooks/useHandlerResize";

export enum WrapperSizes {
    W1440 = 1440,
    W1400 = 1400,
    W1200 = 1200,
    W1024 = 1024,
    W1000 = 1000,
    W768 = 768,
    W600 = 600
}

type WrapperProps = {
    children:React.ReactNode,
    max?: WrapperSizes,
    h100?: boolean 
}

export const Wrapper = ({
    children, 
    max: size = WrapperSizes.W1400,
    h100 = false
}: WrapperProps) => {

    return (
        <div className={`wrapper h100`} 
            style={{maxWidth: size, marginLeft:"auto", marginRight:"auto"}}>
            
            {children}
        </div>
    );
}

type PaddedProps = {
    children:React.ReactNode,
    notTop?:boolean,
    h100?: boolean 
}

Wrapper.Padded = ({children, notTop, h100}:PaddedProps) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const body_padding = isMobile? 0 : 15;

    return (
        <div className="wrapper-padded"  style={{
                padding: "0 "+body_padding+"px", 
                ...notTop?{}:{marginTop: body_padding},
                ...h100?{height: "100%"}:{}
            }}>
            {children}
        </div>
    );
}

Wrapper.VerticalFull = ({children}:React.PropsWithChildren) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div className="wrapper-h-full"  style={{
                height: isMobile? "calc(100vh - 48px)": "calc(100vh - 50px - 50px - 2rem)"
            }}>
            {children}
        </div>
    );
}

Wrapper.VerticalFullNoFooter = ({children}:React.PropsWithChildren) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    React.useEffect(()=>{
        setTimeout(()=> document.getElementById("footer")?.classList.add("hidden"), 100)
        return ()=> document.getElementById("footer")?.classList.remove("hidden")
    },[isMobile])

    return (
        <div className="wrapper-h-full"  style={{
                height: isMobile? "calc(100vh - 48px)": "calc(100vh - 50px)"
            }}>
            {children}
        </div>
    );
}

Wrapper.VerticalSpace = ({mul=1}:{mul?:number}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const body_padding = isMobile? 0 : 15;

    return (
        <div style={{marginBottom: body_padding * mul}}/>
    );
}

