import cn from "classnames"
import React from "react"



const elementY: React.FC<Omit<JSX.IntrinsicElements["div"], "children"> & {children: JSX.Element[]}> = ({children,className, ...args})=>{
    return <div {...args} className={cn("flex flex-col space-y-4", className)}>{
        children
    }</div>
}

const elementX: React.FC<Omit<JSX.IntrinsicElements["div"], "children"> & {children: JSX.Element[]}> = ({children,className, ...args})=>{
    return <div {...args} className={cn("flex space-x-4", className)}>{
        children
    }</div>
}

const LineMarginSyles = {
    none: "",
    base1: "mt-1 mb-1",
    base2: "mt-2 mb-2",
    base3: "mt-3 mb-3",
    base4: "mt-4 mb-4",
}

const lineStyles = {
    forPanel: "bg-[#e1e8ed] dark:bg-[#1d3853]",
    forBg: "bg-[#e1e8ed] dark:bg-black",
    none: ""
}

const Line:React.FC<Omit<JSX.IntrinsicElements["div"], "children"> & {margin?: keyof typeof LineMarginSyles, color?: keyof typeof lineStyles}> = ({className, color="forPanel", margin="base4", ...args})=>{

    return <div {...args} className={cn("h-px ", LineMarginSyles[margin], lineStyles[color], className)} />
}

const forScroll: React.FC<Omit<JSX.IntrinsicElements["div"], "children"> & { children: JSX.Element[] }> = ({ children, className, ...args }) => {
    children = children.reduce((items, child, index) => {
        items.push(child);
        if (index+1 !== children.length)
            items.push(<div key={index} className="h-4"/>);
        return items;
    }, [] as JSX.Element[]);

    return (
        <div {...args} className={cn(className)}>
            {children}
        </div>
    );
};

const withLine: React.FC<Omit<JSX.IntrinsicElements["div"], "children"> & { children: JSX.Element[], line?: React.ComponentProps<typeof Line> }> = ({ children,line, className, ...args }) => {
    children = children.reduce((items, child, index) => {
        items.push(child);
        if (index+1 !== children.length)
            items.push(<Line key={index} margin="base4" {...line} />);
        return items;
    }, [] as JSX.Element[]);

    return (
        <div {...args} className={cn(className)}>
            {children}
        </div>
    );
};


export const ConcatY = Object.assign(elementY, {
    withLine,
    Line,
    forScroll
})
export const ConcatX = elementX