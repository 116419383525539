import React from "react"
import { PlaceholderPulse } from "../../../../components/utils/PlaceholderPulse"
import { RepeatNode } from "../../../../components/utils/RepeatChildren"
import cn from "classnames"

export type PlaceholderCommentProps = {
    addLine?: boolean
    className?:string
    small?:boolean
}
export const PlaceholderComment:React.FC<PlaceholderCommentProps> = ({addLine, className, small})=>{
    return <div className={cn("relative",addLine?"h-[calc(64px+1px+2.75rem)]":"h-[calc(64px+1.75rem)]", className)}>
        <PlaceholderPulse className={cn("mr-3", small?"w-[40px] h-[40px]":"w-[50px] h-[50px]")}/>
        <div className={cn("absolute top-0 right-0", small?"left-[calc(40px+.5rem)]":"left-[calc(50px+.5rem)]")}>
            <PlaceholderPulse.RangeSize className=" mr-3 h-[16px] max-w-full" rangeWidth={[50, 150]}/>
            <div className="flex flex-col space-y-2 mt-3" >
                <PlaceholderPulse className=" mr-3 h-[16px] w-full"/>
                <PlaceholderPulse className=" mr-3 h-[16px] w-full"/>
                <PlaceholderPulse.RangeSize className=" mr-3 h-[16px] max-w-full" rangeWidth={[30, 250]}/>
            </div>
            {addLine && <PlaceholderPulse className="w-full h-[1px] mt-2 mb-2"/>}
        </div>
    </div>
}

export const PlaceholderComments:React.FC<{count: number, className?:string, itemProps?: PlaceholderCommentProps}> = ({count, className, itemProps})=>{
    return <div className={className}>
        {RepeatNode(count, (_, last)=> <PlaceholderComment addLine={!last} {...itemProps}/>)}
    </div>
}