import React from "react"
import { PlaceholderPulse } from "../../../../components/utils/PlaceholderPulse"
import { RepeatNode } from "../../../../components/utils/RepeatChildren"
import cn from "classnames"
import { ConcatY } from "../../../../components/styleguide/components/Concat"

export const PlaceholderPost:React.FC<{addLine?: boolean, className?:string, small?:boolean}> = ({addLine, className, small})=>{
    return <div className={cn("flex items-start", className)}>
        <PlaceholderPulse className={cn(small?"w-[60px] h-[42px]":"w-[90px] h-[67px]", "mr-3")}/>
        <div className="flex-grow-1">
            <PlaceholderPulse.RangeSize className={cn(small?"h-[18px]":"h-[23px]")} rangeWidth={[200, 300]}/>
            <div className={cn("flex flex-col space-y-2", small?"mt-3":"mt-3")}>
                <PlaceholderPulse.RangeSize className={"h-[16px]"} rangeWidth={[240, 260]}/>
            </div>
        </div>
    </div>
}

export const PlaceholderPosts:React.FC<{count: number, className?:string, itemProps?: React.ComponentProps<typeof PlaceholderPost>}> = ({count, className, itemProps})=>{
    return <ConcatY className={className}>
        {RepeatNode(count, (_, last)=> <PlaceholderPost addLine={!last} {...itemProps}/>)}
    </ConcatY>
}