import React from 'react';
import SR from "./resources";

var last_known_scroll_position = 0;
var ticking = false;

export class IrAlCielo extends React.Component {
    state={
        see: false
    };
    
    componentDidMount(){
        const _this = this;
        this.event_rest = function() {
            _this.Evaluate(_this);
        }
        window.addEventListener('scroll', this.event_rest);
        this.Evaluate(this);
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.event_rest);
    }

    Evaluate(_this){
        last_known_scroll_position = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(function() {
                 _this.setState({see: window.pageYOffset > 300});
                ticking = false;
            });
        }
        ticking = true;
    }

    render(){
        if(!this.state.see) return null;
        return (
            <button className="iralcielo" onClick={()=>{
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
                {SR._icons._up({size: 16})}
            </button>
        );
    }
}
