import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import SR from '../../../../components/utils/resources';
import cn from 'classnames';
import { PostOptions } from '../../../post/article/postOptions';
import { refEnv } from '../../../../contexts/EnvironmentContext';
import { useForceUpdate } from '../../../../components/utils/useForceUpdate';

import { Imagen } from '../../../../components/Imagen/Imagen';
import { IPost } from '../../../../hooks/apis/usePost';
import { PostMetas, PostMetasProps } from './metas';

const Title:React.FC<React.PropsWithChildren<{
    onSize?: (size:number)=> void
}>> = ({ onSize,children})=>{
    const refH5 = useRef<HTMLHRElement>()

    useLayoutEffect(()=>{
        if(onSize) onSize(refH5.current.clientHeight)
    },[onSize])

    return (
        <h5 ref={refH5} className="mt-0 mb-1 ">
            {children}
        </h5>
    )
}

export const ItemPost:React.FC<{
    post: IPost,
    IsFav?: boolean,
    isSticky?: boolean
    mini?: boolean,
    metas?: PostMetasProps
}> = (props)=>{
    const {forceUpdate} = useForceUpdate();
    const {post, IsFav = false} = props;
    const refLi = useRef<HTMLLIElement>()
    const [titleSize, setTitleSize] = useState(0)

    const onDeleteMe = ()=>{
        refLi.current.classList.add("opa0");
        setTimeout(() => refLi.current?.parentElement?.removeChild &&refLi.current.parentElement.removeChild(refLi.current), 500);
    }

    let {title, portada, meta_comments, meta_puntos, meta_vistas, author,_id, slug,created} = post;
    if(!author) author = window.user404;

    const _sticky_ = props.isSticky;
    const _stycky_large_ = titleSize > 40;

    portada = portada? portada: author.photo;

    const Portada = portada && (
        <Link title={title} to={`/post/${_id}/${slug}`}>
            <Imagen className={cn("mr-3","photo")} pId={"g7gbuPt1Dx"} src={portada? portada: author.photo} style={{backgroundPosition:"center", backgroundSize:"cover"}}/>
        </Link>
    )
    
    const options = <PostOptions preview={true} post={post} className=" options" IsFav={IsFav} IsSticky={_sticky_} forceUpdate={()=>{
        forceUpdate();
    }} DeleteMe={onDeleteMe}/>

    return (
            <li ref={refLi as any} className={cn(
                    "media",
                    `post_mini`,
                    {
                        "_sticky_": _sticky_,
                        "_stycky_large_": _stycky_large_,
                    }
                )} >

                {Portada}
                {options}
                <div className={cn("media-body")}>
                    <Link title={title} className="title" to={`/post/${_id}/${slug}`}>
                        <Title onSize={n=> _sticky_ && setTitleSize(n)}>
                            {_sticky_ && <span className='stickyTag mr3'>{SR._icons._pin({size:14})}<span className='ml1'>Sticky</span></span>}
                            {title}
                        </Title>
                    </Link>
                    {!_sticky_ && <PostMetas post={post} {...props.metas}/>}
                </div>
            </li>
    )
}
