// import { Cloudinary } from "cloudinary-core";
import Imgproxy from "imgproxy";
import { Base64 } from "js-base64";
import React from "react";
import { useMemo } from "react";
import { refEnv } from ".";
import { Imagen } from "../../components/Imagen/Imagen";
import { TempState } from "../../components/utils/LazyState";

// const CLIENT_CLOUDINARY = new Cloudinary({ cloud_name: "dwg42bynm" });
const CLIENT_IMGPROXY_A = new Imgproxy({
  baseUrl: "https://media.peluchan.net",
  //   key: process.env.IMGPROXY_KEY,
  //   salt: process.env.IMGPROXY_SALT,
  encode: true,
});

const CLIENT_IMGPROXY_B = new Imgproxy({
  baseUrl: "https://media.a248.ml",
  //   key: process.env.IMGPROXY_KEY,
  //   salt: process.env.IMGPROXY_SALT,
  encode: true,
});

const CLIENT_IMGPROXY_TEST = new Imgproxy({
  baseUrl: "https://media.a248.ml",
  //   key: process.env.IMGPROXY_KEY,
  //   salt: process.env.IMGPROXY_SALT,
  encode: true,
});

/**
 * Post portada, Post portada nano
 * Post portada full, Post body images, Post user image, Post recomendado portada
 * Commets user image, Comments body images, Comments body images full
 *
 * Shout user image, Shout image preview low, Shout image preview high, Shout image full
 * User user image, User seguidores/siguiendo image
 */

const __refEnv = () => refEnv();
const __refTempState = () => TempState;

//###  TYPESCRIPT  ###

type sizeType = { width?: number; height?: number };
 
export type MediaParamTypes = {
  size?: sizeType;
  format?: string;
};

//###  ##########  ###

const MediaLogic = () => {
  //###  UTILS  ###
  const isGIF = (url: string, { format = "" } = {}) => {
    if (format.toLocaleLowerCase() === "gif") return true;
    return !!url.toLocaleLowerCase().includes(".gif");
  };
  const isTag = (tagName: string) => __refEnv().tag.is(tagName);

  const isFailed = (url:string, newUrl)=>{
    const urls:string[] = __refTempState().get(encodeURIComponent("img-fail-"+url)) || []
    return urls.includes(newUrl)
  }

  const isHost = (url:string, host:string)=>{
    if(url.indexOf(`https://${host}`) == 0) return true
    if(url.indexOf(`http://${host}`) == 0) return true
    return false
  }

  const isCloudFlarePaid = (url: string) => {
    return isHost(url, "imagedelivery.net/GKo274lICZsnSFlvHgcgdw")
  };
  
  //###  PROXYS  ###

  const PROXY_CLOUDFLARE_WORKER = (url: string)=>{
    if(isHost(url, "cdn1.rivieranick4.workers.dev")) return url
    return `https://cdn1.rivieranick4.workers.dev/proxy?proxyUrl=${url}`
  }
  const PROXY_PELUCHAN = (url: string)=>{
    if(isHost(url, "media.peluchan.net/unsafe/raw:1/")) return url
    return  `https://media.peluchan.net/unsafe/raw:1/${window.btoa(url)}`
  }

  //###  CDN  ###

  const CDN_FILES_CLOUDFLARE_PAID = (url: string, variant: string)=>{
    const regex = /^https:\/\/imagedelivery\.net\/GKo274lICZsnSFlvHgcgdw\/([\w\-]*)\/.*$/.exec(url)
    if(!regex) return url
    return `https://imagedelivery.net/GKo274lICZsnSFlvHgcgdw/${regex[1]}/${variant}`
  }


  const CDN_CLOUDFLARE_IMAGES = (
    url: string,
    s: {
      size?: sizeType;
      fit?: "fill" | "scale-down" | "contain" | "cover" | "crop" | "pad";
      fetchFormat?: "auto";
      quality?: number;
      we?: boolean;
      anim?: boolean;
      background?: string;
      blur?: number;
      compression_fast?: boolean;
      format?: "avif" |"webp" |"jpeg" |"baseline-jpeg" |"json" | "auto";
      gravity?: "auto" | {x:number, y:number};
      metadata?: "keep";
    }={}
  ) => {
      if(isHost(url, "www.peluchan.net/cdn-cgi/image")) return url

      const args:[string,any][] = []

      if(s.size.width)  args.push(["width",s.size?.width])
      if(s.size.height) args.push(["height",s.size?.height])
      if(s.quality) args.push(["quality", s.quality])
      if(s.anim) args.push(["anim", s.quality])
      if(s.background) args.push(["background", s.background])

      if(s.blur) args.push(["blur", s.blur])
      if(s.compression_fast) args.push(["compression", "fast"])
      if(s.fit) args.push(["fit", s.fit])
      if(s.format) args.push(["format", s.format])
      if(s.gravity) args.push(["gravity", s.gravity=="auto"? "auto": `${s.gravity.x}x${s.gravity.y}`])
      if(s.metadata) args.push(["metadata", s.metadata])

      let params = args.map(i=> `${i[0]}=${i[1]}`).join(",")

      params = params.length>0?"/"+params:""

      return (
        `https://www.peluchan.net/cdn-cgi/image${params}/${url}`
      );
    }
  // const CDN_CLOUDINARY = (
  //   url: string,
  //   {
  //     size,
  //     crop = "fill",
  //     fetchFormat = "auto",
  //     fps = 8,
  //   }: {
  //     size?: sizeType;
  //     crop?: "fill";
  //     fetchFormat?: "auto";
  //     fps?: number;
  //   }
  // ) => {
  //   return CLIENT_CLOUDINARY.url(url, {
  //     ...(size
  //       ? {
  //           width: size?.width,
  //           height: size?.height,
  //         }
  //       : {}),
  //     crop,
  //     fetchFormat,
  //     type: "fetch",
  //     fps,
  //   });
  // };

  var CDN_PELUCHAN = (
    url: string,
    {
      size,
      quality = 90,
      crop = "fill",
      of_gif  = false
    }: {
      size?: sizeType;
      quality?: number;
      crop?: "fill";
      of_gif?: boolean
    }
  ) => {
    const build_url = (url:string)=>{
      let CLIENT = CLIENT_IMGPROXY_A;

      if (isTag("xkFJK")) CLIENT = CLIENT_IMGPROXY_B;
      if (isTag("cIMyI")) CLIENT = CLIENT_IMGPROXY_TEST;

      let build = CLIENT.builder()

      if(size.width || size.height) build = build.resize(crop, size?.width, size?.height, false)

      return build
        .quality(quality)
        .generateUrl(url);
    }

    const evalBanned = (url:string)=>{
      if(isHost(url, "i.imgur.com")) return true
      if(isHost(url, "iili.io")) return true
      return false
    } 

    if (isHost(url, "media.peluchan.net")) return url;
    if (isHost(url, "media.taringa.net")) return "/resources/img404/notfoun-banner.svg";

    const isBanned = evalBanned(url)

    if(!isBanned){
      const url_direct = build_url(url);
      if(!isFailed(url, url_direct)) return url_direct;
  
      const url_Proxy = PROXY_PELUCHAN(url)
      if(!isFailed(url, url_Proxy) ) return url_Proxy
    }

    if(isTag("M_Icf")){
      const url_direct_cfr = build_url(CDN_CLOUDFLARE_IMAGES(url))
      if(!isFailed(url, url_direct_cfr)) return url_direct_cfr
  
      const url_proxy_cfr = PROXY_PELUCHAN(CDN_CLOUDFLARE_IMAGES(url))
      return url_proxy_cfr
    }

    if(isTag("M_Ikn")){
      const url_direct_cfr = build_url(CDN_KN3_2(url, {size}))
      if(!isFailed(url, url_direct_cfr)) return url_direct_cfr
  
      const url_proxy_cfr = PROXY_PELUCHAN(CDN_KN3_2(url, {size}))
      return url_proxy_cfr
    }

    const url_direct_cfr = build_url(PROXY_CLOUDFLARE_WORKER(url))
    if(!isFailed(url, url_direct_cfr)) return url_direct_cfr

    const url_proxy_cfr = PROXY_PELUCHAN(PROXY_CLOUDFLARE_WORKER(url))
    return url_proxy_cfr
  };

  const CDN_WESERV = (
    url: string,
    {
      size,
      quality,
      crop,
      we,
    }: {
      size?: sizeType;
      crop?: "fill";
      fetchFormat?: "auto";
      quality?: number;
      we?: boolean;
    }
  ) => {
    const _url = encodeURI(url);
    const isGIF = _url.includes(".gif")
    let animate = ""
    if(isGIF && size?.width > 300){
      size.width = 300
      size.height = undefined
      animate = "&output=gif&n=-1"
    }
    const _width = size?.width ? `&w=${size?.width}` : "";
    const _height = size?.height ? `&h=${size?.height}` : "";
    const _fit = crop ? `&fit=${crop}` : ""; // inside | etc...
    const _we = we ? "&we" : "";
    const _quality = quality ? `&q=${quality}` : ""; // 0 - 100
    return (
      `//images.weserv.nl/?url=${_url}` +
      _width +
      _height +
      _fit +
      _we +
      _quality +
      animate
    );
  };

  const CDN_KN3_2 = (
    url: string,
    {
      size,
    }: {
      size?: { width?: number; height?: number };
    }
  ) => {
    url = url.replace(/https\:\/\/ugc\.kn3\.net\/i\/(\d*)x\//g, "");
    const _url = "" + window.btoa(url);
    const list = [
      [[100, 40], "90x90"],
      [[], "150x115"],
      [[140, 250, 740, 348, 600], "550"],
    ];
    let _size = `${
      (list.find((l) => l[0][0] === size?.width) || [, "550"])[1]
    }`;
    const _width = _size ? `fit:${_size}/` : "fit/";
    const result = `https://media.taringa.net/knn/` + _width + _url;
    if (isTag("djBN6")) return CDN_PELUCHAN(result, { size });
    return result;
  };

  //###  TEMPLANTES  ###


  type SimpleTemplante = (
    stg: {
      size?: sizeType;
    },
    ...args: ResultType
  ) => string;
  
  const OF_GIF: typeof CDN_KN3_2 = (url: string, { size }) => {
    const mitad_size = (size: {
      width?: number;
      height?: number;
  })=>{
      if(!size) return size
      const width = size.width?size.width/2:size.width
      const heigth = size.height? size.height/2:size.height
      return {width, heigth}
    }

    if (isTag("6akln")) size = mitad_size(size)
    if (isTag("M_Gpe")) return CDN_PELUCHAN(url,{size, of_gif: true});
    if (isTag("jPfF0")) return PROXY_PELUCHAN(CDN_KN3_2(url,{size}));
    if (isTag("TGgiA")) return PROXY_PELUCHAN(CDN_CLOUDFLARE_IMAGES(url,{size}));
    return url
  };

  const PostPortada: SimpleTemplante = ({ size }, url) => {
    if (isGIF(url)) return OF_GIF(url, { size });
    // if(isGIF(url)) return url;
    return CDN_PELUCHAN(url, { size });
  };

  const UserImage: SimpleTemplante = ({ size }, url) => {
    if (isGIF(url)) return OF_GIF(url, { size });
    // if(isGIF(url)) return url;
    return CDN_PELUCHAN(url, { size });
  };

  //###  RESULT  ###
  type ResultType = [string, MediaParamTypes];
  let V: { [key: string]: (...a: ResultType) => string | void } = {};

  //Imagen de portada de las miniaturas de los post en la home
  V["g7gbuPt1Dx"] = (...args) => {
    const size = { width: 90, height: 67 };
    return PostPortada({ size }, ...args);
  };

  //Imagen de portada de los post recomendados en los post
  V["m5vSGtEFbX"] = (url) => {
    const size = { width: 348, height: 200 };
    if (isGIF(url))
      return OF_GIF(url, {
        size: {
          width: size.width / 2,
          height: size.height / 2,
        },
      });
    if (isGIF(url)) return url;
    if(isTag("8t5hk")) return CDN_WESERV(url, { size })
    return CDN_PELUCHAN(url, { size });
  };

  //Imagen de portada del post a tamaño completo
  V["REQBee07Wp"] = V["m5vSGtEFbX"];

  //Imagenes del body de todos los posts/comments/messages
  V["2x7DsuOkLS"] = (url) => {
    if(isCloudFlarePaid(url)) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(url,"comment"))
    const size = { width: 740, height: undefined };
    if (isGIF(url)) return OF_GIF(url, { size });
    // if(isGIF(url)) return url;
    return CDN_PELUCHAN(url, { size });
  };

  //Imagen de usuario en header desktop
  V["dg0elqK0hD"] = (...args) => {
    if(isCloudFlarePaid(args[0])) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(args[0],"user40"))
    const size = { width: 40, height: 40 };
    return UserImage({ size }, ...args);
  };

  //Imagen de usuario en header mobile
  V["PFHlvHM53y"] = V["dg0elqK0hD"];

  //Imagen de usuario del creador-del-post y de perfil-de-usuario
  V["8j4PDaK2iS"] = (...args) => {
    if(isCloudFlarePaid(args[0])) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(args[0],"userperfil120"))
    const size = { width: 120, height: 120 };
    return UserImage({ size }, ...args);
  };

  //Imagen de usuario de comentario desktop
  V["KIcaPMsCBa"] = (...args) => {
    if(isCloudFlarePaid(args[0])) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(args[0],"user50"))
    const size = { width: 50, height: 50 };
    return UserImage({ size }, ...args);
  };

  //Imagen de usuario de comentario mobile
  V["1lv6NThaBQ"] = V["KIcaPMsCBa"];

  //Imagen de usuario de shout
  V["CtFzb96FNP"] = V["KIcaPMsCBa"];

  //Imagen de usuario de seguidores/siguiendo
  V["s6dGwrklQ5"] = (...args) => {
    if(isCloudFlarePaid(args[0])) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(args[0],"user40"))
    const size = { width: 50, height: 50 };
    return UserImage({ size }, ...args);
  };

  //Imagen de usuarios pequeña de estadisticas
  V["1gVhE1TxEl"] = V["s6dGwrklQ5"];

  //Imagen de usuarios en notificaciones
  V["Xx2nBR4obJ"] = V["s6dGwrklQ5"];

  //Imagen de usuarios en mensajes
  V["XmGU21YbRw"] = V["s6dGwrklQ5"];

  //Preview blur de imagenes en los shouts
  V["MhPbNfRfmv"] = (url) => {
    if(isCloudFlarePaid(url)) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(url,"blur"))
    const size = { width: 15 };
    if (isGIF(url)) return CDN_WESERV(url, { size });
    return CDN_PELUCHAN(url, { size });
  };

  //Preview de calidad medio para el scroll de las imagenes de los shouts
  V["DW4gEuUtq4"] = (url, { format }) => {
    if(isCloudFlarePaid(url)) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(url,"shoutpreview"))
    const size = { width: 600, height: undefined };

    if (isGIF(url, { format }))
      return OF_GIF(url, { size: { ...size, width: 740 } });

    return CDN_PELUCHAN(url, { size });
  };

  //Preview de maxima calidad para las imagenes de los shouts
  V["NxiQFXQfOi"] = V["DW4gEuUtq4"]
  // V["NxiQFXQfOi"] = (url, { format }) => {
  //   if(isCloudFlarePaid(url)) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(url,"shoutfull"))
  //   const size = { width: 1200, height: undefined };

  //   if (isGIF(url, { format }))
  //     return OF_GIF(url, { size: { ...size, width: 740 } });

  //   return CDN_PELUCHAN(url, { size });
  // };

  //Preview portada de shouts anteriores en la pagina de shout
  V["4ZCEidtgwq"] = (url, { format }) => {
    if(isCloudFlarePaid(url)) return PROXY_PELUCHAN(CDN_FILES_CLOUDFLARE_PAID(url,"shoutpreviewbox"))
    const size = { width: 140, height: 140 };
    if (isGIF(url, { format }))
      return OF_GIF(url, { size: { ...size, width: 140 } });
    // if(isGIF(url)) return CDN_CLOUDINARY(url, { size });
    // if(isGIF(url)) return url;
    return CDN_PELUCHAN(url, { size });
  };

  return V;
};

//@ts-ignore
window.build_media = () => Base64.encode(MediaLogic.toString());

export const useMedia = (logic: string, tag: any) => {
  return useMemo(() => {
    var _LOGIC: ReturnType<typeof MediaLogic> = {};
    if(tag.is("b64")){
      try {
        const raw = eval(Base64.decode(logic));
        if (typeof raw === "function") _LOGIC = raw();
        else _LOGIC = MediaLogic();
      } catch (error) {
        console.log("♦♦ MediaLogic ERROR!! ♦♦");
        try {
          _LOGIC = MediaLogic();
        } catch (error) {
          console.log(error);
        }
      }
    }else{
      try {
        _LOGIC = MediaLogic();
      } catch (error) {
        console.log(error);
      }
    }

    return {
      contexts: {
        media: (id: string, url: string, { size, format }: MediaParamTypes = {}) => {
          if (!url) url = "https://mypay.com.my/image/staff/user.png";
          const logic = _LOGIC[id];
          if (!logic) return url;
          try {
            return (
              logic(url, {
                size,
                format,
              }) || url
            );
          } catch (error) {
            console.log(error);
            return url;
          }
        },
      },
    };
  }, [logic]);
};

export type PImgProps = {
  refId: string;
  src: string;
  cdn?: MediaParamTypes;
} & React.ImgHTMLAttributes<HTMLImageElement>;
export const PImg: React.FC<PImgProps> = ({ refId, src, cdn,...props}) => {
  return (
    <Imagen
      pId={refId}
      {...props}
      src={src}
      pParams={cdn}
    />
  );
};
