import React, { useMemo, useState } from 'react';

import {
    Link
} from 'react-router-dom'
import Editor_Comment from "./editor-comment";
import Buttons from './buttos';
import ParseContent from "../../../components/utils/ParseContent/parse_content";
import SR from "../../../components/utils/resources";
import { refPeluchan, usePeluchan } from '../../../contexts/PeluchanContext';
import { UserType } from '../../../hooks/apis/useUser';
import LazyLoad from 'react-lazyload';
import SendDelete from '../../../components/modal/senddelete';
import Tooltip from '../../../components/utils/tooltip';
import SendDenuncia from "../../../components/modal/senddenuncia";
import copy from 'copy-to-clipboard';
import { ConcatY } from '../../../components/styleguide/components/Concat';
import cn from 'classnames';
import { CommentHeader } from './components/header';
import { CommentPhoto } from './components/photo';
import TagManager from 'react-gtm-module';


interface IComment {
    _id: string;
    body: {
        content: any;
        type: string;
    };
    author: UserType;
    parent_type: string;
    parent: string;
    parent_other: string;
    parent_comment: IComment;
    parent_comment_author: string;
    positivos: number
    negativos: number
    // in_positivos: Types.ObjectId[]
    // in_negativos: Types.ObjectId[]
    // type_content:  CommentVals
    // state_vals:  StateVals
    comments_root: boolean;
    created: Date;
    up?: boolean,
    down?: boolean;
    fav?: boolean
    comments: IComment[]
}


async function like(comment, like,dom){
    const {author,_id,up,down} = comment;

    if(!author) return;
    if(refPeluchan().login.my(author._id)) return;

    if(up||down){
        window.log(`Ya votaste a este comentario`);
        return;
    }

    var result = await window.POST(`/api/comment/like`,{
        user_id: author._id,
        comment_id: _id,
        like: like
    });

    dom.classList.add("active");
    if(result==null||result.error){
        dom.classList.remove("active");
        window.log(`${result.error}`,"red");
        return;
    }
    if(like===1) comment.up = true;
    if(like===-1) comment.down = true;

    const num = parseInt(dom.querySelector("span").innerText);
    if(!isNaN(num)){
        dom.querySelector("span").innerText = num+1;
    }

    TagManager.dataLayer({
        dataLayer: {
            event: "like",
            type: "comment",
            commentId: _id,
            authorId: author._id,
            username: refPeluchan().login.user.username,
            like
        }
    })
}


const ButtonsLikes:React.FC<{comment: any, isLogin: boolean}> = React.memo(({comment:c, isLogin})=>{
    const [isLoading, setLoading] = useState(0)

    return <>
        <div className="Buttons">
            <Buttons name="like" Click={(e)=>{
                setLoading(1)
                like(c, 1,e).finally(()=> setLoading(0))
                }} className={(isLogin && c.up?"active":"")+" "+(isLoading==1?"opacity-50":"")+" mr-2"}>{c.positivos}</Buttons>
            <Buttons name="dislike" Click={(e)=>{
                setLoading(2)
                like(c, -1,e).finally(()=> setLoading(0))
                }} className={(isLogin && c.down?"active":"")+" "+(isLoading==2?"opacity-50":"")}>{c.negativos}</Buttons>
        </div>
    </>
})

function BlockComment(props:CommentProps) {
    const {login:{isLogin}} = usePeluchan();
    return useMemo(()=> <BlockCommentLegacy {...props} isLogin={isLogin}/>, [isLogin, props.comment._id, props.isSmall, props.addLine]);
}

type CommentProps = {
    comment: IComment & {isClear?: boolean, isPush?: boolean}, 
    replys_show?: boolean,
    parent?: BlockCommentLegacy,
    isLogin?: boolean
    IsFav?: boolean,
    parent_user_id?: string
    parent_type?: "Post" | "Shout"
    parent_id?: string
    insertado?: boolean,
    isSmall?: boolean,
    addLine?: boolean
}

class BlockCommentLegacy extends React.Component<CommentProps> {
    state = {
        IsEditor: false
    }



    async delete({force = false, porque}:{force?:boolean, porque?:string}={}){
        const {comment} = this.props;
        const {author,_id,up,down, comments_root, parent,parent_other,parent_type,parent_comment,parent_comment_author} = comment;
        if(!author) return;

        var _delete = {
            tipo: parent_type,
            force_delete: force,
            comment_id: _id,
            porque: undefined,
            comment_reply_id: undefined,
            comment_reply_author_id: undefined
        };

        if(porque){
            _delete.porque = porque;
        }

        if(!comments_root && this.props.replys_show!==false){
            _delete.comment_reply_id = this.props.parent.props.comment._id;
            _delete.comment_reply_author_id = this.props.parent.props.comment.author._id;
        }

        var result = await window.POST(`/api/comment/delete`,_delete);

        if(result==null||result.error){
            window.log(`${result.error}`,"red");
            return;
        }
        const {action} = result.data;
        if(action === "clear"){
            window.log("Comentario limpiado","purpure");
            this.ClearMe({msg: result.data.comment.body});
        }
        if(action === "delete") {
            window.log("Comentario eliminado","purpure");
            this.DeleteMe();
        }


    }
    ClearMe({msg=""}){
        //@ts-ignore
        this.props.comment.body = msg;
        this.props.comment.isClear = true;
        this.forceUpdate();
    }

    DeleteMe(){
        this.root.classList.add("opa0");
        setTimeout(() => {
            this.root.parentElement.removeChild(this.root);
        }, 500);
    }


    replyClose(){
        if(this.state.IsEditor)
        this.setState({
            IsEditor: false
        });
    }

    root= undefined
    push_etiqueta = undefined
    editor = undefined

    replyOpen(username, reply= false){
        const {comment,parent} = this.props;
        const _this = this;

        if(comment.comments_root === false){
            parent.replyOpen(username, true);
            return;
        }

        if((window as any).close_last_editor) {
            (window as any).close_last_editor(comment._id);
            (window as any).close_last_editor = null;
        }

        this.setState({
            IsEditor: true
        });

        if(username && reply) this.push_etiqueta = username;
        else this.push_etiqueta = null;
        if(this.editor){
            if(this.editor.editor && this.editor.editor.push){
                this.editor.editor.push(` @${username} `, {off: "start"});
            }
        }

        (window as any).close_last_editor = (id)=>{
            if(comment._id !== id)_this.replyClose();
        }
    }


    render(){
        const _this = this;
        const {comment, IsFav = false} = this.props;

        if(typeof comment === "string") return <>{comment}</>

        let {author,created,body,positivos,negativos,up,down,comments, isPush,isClear,comments_root,parent,parent_type} = comment;
        if(!author) author = window.user404 as any;

        const {
            _id,
            username,
            name, 
            // online=false
        } = typeof author === "string"? {_id: author, name: author, username:"***"} : author;

        // let online_on = online && <span className="onlineball"/>;

        const IsMy = refPeluchan().login.my(_id);
        const IsLogin = this.props.isLogin;


        if(isClear){
            this.root.classList.add("_push_");
            setTimeout(() => {
                if(this.root?.classList?.remove) this.root.classList.remove("_push_");
            }, 1000);
            delete comment.isClear;
        }

        if(isPush){
            setTimeout(() => {
                if(this.root?.classList?.remove) this.root.classList.remove("_push_");
            }, 300);
            if(comments_root===false){
                setTimeout(() => {
                    if((window as any).close_last_editor) {
                        (window as any).close_last_editor();
                        (window as any).close_last_editor = null;
                    }
                }, 100);
            }
            delete comment.isPush;
        }

        const etiqueta_default = this.push_etiqueta?`@${this.push_etiqueta} `:"";

        var PFavorite = ()=>{
            const delete_favs = comment.fav || IsFav;
            return {
                text: delete_favs? `Quitar`:`Guardar`,
                show: IsLogin ,
                click: async ()=>{
                    window.RPOST(`/api/fav/${delete_favs?`del`:`add`}`,{_id: comment._id, type: "Comment"});
                    if(delete_favs) _this.DeleteMe();
                },
                icon: SR._icons._favorite_list4({size: 18})
            };
        }

        const tooltip = [{
            text:"ir al post",
            show: (this.props.insertado && parent)?false:false,
            to: `/post/${parent}`
        },
            PFavorite()
        ,{
            text:"Copiar enlace",
            show: IsLogin,
            click: ()=>{
                if(copy(`${window.location.origin}/${parent_type.toLowerCase()}/${parent}${parent_type==="Post"?"/_":""}#${comment._id}`)) window.log("Copiado!");
                else window.log("Fallo!");
            },
            icon: SR._icons._comment_link({size: 19})
        },{
            text:"Denunciar",
            show: !IsMy && IsLogin,
            click: ()=>{window.popup.load(<SendDenuncia parent={comment} parent_type={"Comment"}/> );},
            icon: SR._icons._comment_denunciar
        },{
            text:"Convertir Imágenes a Links",
            show: IsLogin && refPeluchan().login.user?.priv >= 300,
            click: async()=>{await window.RPOST("/api/comment/clearimages",{ comment_id: comment._id });},
            icon: SR._icons._comment_denunciar
        },{
            text:"Convertir Links a Hexas",
            show: IsLogin && refPeluchan().login.user?.priv >= 300,
            click: async()=>{await window.RPOST("/api/comment/links_to_binary",{ comment_id: comment._id });},
            icon: SR._icons._comment_denunciar
        },{
            text:"Eliminar",
            show: ((IsMy|| refPeluchan().login.user?.priv >= 300) && IsLogin && (body.type!=="delete"? true: comments && comments.length===0))?true:false,
            click: ()=>{this.delete()},
            icon: SR._icons._delete
        },{
            text:"Eliminar por x motivo",
            show: !IsMy && ((refPeluchan().login.user?.priv >= 300) && IsLogin && (body.type!=="delete"? true: comments && comments.length===0))?true:false,
            click: ()=>{window.popup.load(
                <SendDelete
                    title={`Eliminar comentario de @${comment.author.username}`}
                    preview={(text)=>
                        <>
                            Comentario eliminado de <a href="#">@</a>{comment.author.username} de este <a href="#">@</a>???? {text}
                        </>
                    }
                    _delete={(msg)=>{
                        this.delete({porque: msg});
                    }}
                />
            );},
            icon: SR._icons._delete_motivo
        },{
            text:"Eliminar a la fuerza",
            show: IsLogin && refPeluchan().login.user?.priv >= 300,
            click: ()=>{this.delete({force: true})},
            icon: SR._icons._deleteforce
        }]
        

        return (
            <>
            <article ref={(d)=>{ this.root =d }} id={`${IsFav?"fav-":""}${comment._id}`} className={cn({
                    "_my_":IsMy, "_push_":isPush
                }, 
                    `_comment_`
                )}>
                <CommentPhoto user={author} small={this.props.isSmall}/>
                <div className={cn("content", this.props.isSmall?"pl-[calc(40px+.75rem)]":"pl-[calc(50px+.75rem)]")}>
                    <CommentHeader user={author} created={created}/>
                    <div className={cn("___body___")}>
                        <ParseContent _type='string/html' body={body as any} lazyImages={false && location.hash.length==0}/>
                    </div>
                    <div className="footer h-[25px] relative w-full">
                        <ButtonsLikes comment={comment} isLogin={IsLogin}/>
                        <div className="Buttons-Right absolute right-0 top-0 ">
                            {this.props.insertado && parent &&
                                <Link className="LinkIrAlPost align-middle" to={`/${parent_type.toLowerCase()}/${parent}${parent_type==="Post"?"/_":""}#${comment._id}`} onClick={()=>{
                                    if(!IsFav) return;
                                    window.popup.close();
                                }}><span>Ir al comentario</span></Link>
                            }

                            <LazyLoad style={{display: "inline"}} offset={500} unmountIfInvisible={true} classNamePrefix=' '>
                                <Tooltip title="Comentario" offset={[0,8]} placement="left-end">
                                    {tooltip}
                                </Tooltip>
                            </LazyLoad>
                            { this.props.replys_show!==false && <Buttons name="reply" className='align-bottom ' Click={() => { this.replyOpen(username); } }/> }
                        </div>
                    </div>
                    {(this.props.addLine || (comments && comments.length!==0 )) && <ConcatY.Line className='w-full h-[1px] mt-2 mb-3' margin='none'/>}
                </div>
            </article>
            {
                this.props.replys_show!==false && (comments && comments.length!==0 )|| this.state.IsEditor?(
                    <div className="replys">
                        { 
                            comments.length!==0?(
                                comments.map((e,index)=>{
                                    return (<BlockCommentLegacy 
                                        parent={this} 
                                        comment={e} 
                                        isLogin={IsLogin} 
                                        key={e._id} 
                                        isSmall={this.props.isSmall } 
                                        addLine={comments.length != index +1 || this.props.addLine || this.state.IsEditor}
                                    />);
                                })
                            ):[]
                        }
                        {
                            this.state.IsEditor?(
                                <Editor_Comment 
                                    defaultValue={etiqueta_default}
                                    autoFocus={true}
                                    auto_scroll="1"
                                    isSmall={this.props.isSmall}
                                    addLine={this.props.addLine}
                                    type={this.props.parent_type}
                                    parent_id={this.props.parent_id}
                                    parent_user_id={this.props.parent_user_id}
                                    comment_reply={comment?comment:undefined}
                                    _created={({comment, isReply})=>{
                                        comment.isPush = true;
                                        comments.push(comment);
                                        this.forceUpdate();
                                    }} 
                                    onLoad={(editor)=>{
                                        _this.editor = editor;
                                        editor.toEnd();
                                    }}
                                />
                            )
                            :null
                        }
                    </div>
                ):null
            }
            </>
        );
    }
}

export default BlockComment;
