import React from 'react';
import EditorList from "../EditorList/editorlist";

class AWGAFAWGWA extends React.Component {
    constructor(){
        super();
        this.message = {};
    }

    render(){
        const {_id, username} = this.props.user?this.props.user:{}; 
        const _this = this;
        const {message} = this;
        //var body = null;
        return (
            <div className="modal fade show"  tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{`Enviar mensaje a @${username}`}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{
                        window.popup.close();
                    }}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                        <EditorList ref={(e) =>{
                         _this.editorlist = e;}} editor="tinymce-comment" onChange={(body)=>{
                            message.body = body;
                         }}></EditorList>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{
                        window.popup.close();
                    }}>Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={async ()=>{
                        const {data, error} = await window.POST(`/api/msg/create`,{
                            to: _id,
                            body: message.body
                        });
                        if(error || (!data && !error)){
                            if(!data && !error) window.log("Servidor no responde","red");
                            else window.log(error,"red");
                            return;
                        }
                        window.log("Mensaje enviado correctamente","purpure");
                        if(!_this.editorlist) return;
                        _this.editorlist.clear();
                    }} >Enviar</button>
                </div>
                </div>
            </div>
            </div>
        );
    }
}

export default AWGAFAWGWA;