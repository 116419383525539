import React from 'react';
import { Fragment } from 'react';
const reactStringReplace = require('react-string-replace');

var emoticones:{code:string, url: string, fileName: string}[] = [];
// (?:^|\s)@(\w*)(?:$|\s)
var AddEmoticon = (code:string, url:string)=>{
    emoticones.push({
        code: code, 
        url: url,
        fileName: url.split("/")[3].split(".")[0]
    });
}

const regex = /(?:^|\B)\:([\w\/\[\]]{1,12})\:(?:$|\B)/gm; 
//const regex = /(?:^|\B)(\:[\w]{1,12}\:)(?:$|\B)/gm;

AddEmoticon("smile", "/resources/ems/474.gif");
AddEmoticon("sad", "/resources/ems/sad.gif");
AddEmoticon("mmm", "/resources/ems/FD9.gif");
AddEmoticon("f", "/resources/ems/D96.gif");
AddEmoticon("angry", "/resources/ems/F37.gif");
AddEmoticon("gsmyle", "/resources/ems/738.gif");
AddEmoticon("cry", "/resources/ems/2F5.gif");
AddEmoticon("/", "/resources/ems/naa.gif");
AddEmoticon("happy", "/resources/ems/A07.gif");
AddEmoticon("tongue", "/resources/ems/12C.gif");
AddEmoticon("good", "/resources/ems/E61.gif");
AddEmoticon("roll", "/resources/ems/527.gif");
//Me falta un sad2 => :(
// AddEmoticon("(", "");
AddEmoticon("twisted", "/resources/ems/C75.gif");
//Me falta una cara roza => :oops:
// AddEmoticon("oops", "");
AddEmoticon("blind", "/resources/ems/A0F.gif");
AddEmoticon("hot", "/resources/ems/85B.gif");
AddEmoticon("cold", "/resources/ems/BF1.gif");
AddEmoticon("grin", "/resources/ems/9EA.gif");
AddEmoticon("love", "/resources/ems/C11.gif");
AddEmoticon("winky", "/resources/ems/A09.gif");
AddEmoticon("cool", "/resources/ems/1B8.gif");
AddEmoticon("blaf", "/resources/ems/EF5.gif");
AddEmoticon("bobo", "/resources/ems/19E.gif");
AddEmoticon("noo", "/resources/ems/E6D.gif");
AddEmoticon("done", "/resources/ems/huh.gif");
AddEmoticon("ooops", "/resources/ems/C54.gif");
AddEmoticon("crying", "/resources/ems/D3B.gif");
AddEmoticon("alaba", "/resources/ems/7A7.gif");
AddEmoticon("lpmqtp", "/resources/ems/733.gif");
AddEmoticon("idiot", "/resources/ems/EBE.gif");
AddEmoticon("shrug", "/resources/ems/10A.gif");
AddEmoticon("8S", "/resources/ems/700.gif");
AddEmoticon("]", "/resources/ems/BF8.gif");
AddEmoticon("buaa", "/resources/ems/lloron.gif");
AddEmoticon("globo", "/resources/ems/037.gif");
AddEmoticon("zombie", "/resources/ems/1C4.gif");
AddEmoticon("man", "/resources/ems/3AB.gif");
AddEmoticon("metal", "/resources/ems/4C8.gif");
AddEmoticon("mario", "/resources/ems/062.gif");
AddEmoticon("info", "/resources/ems/7A3.gif");
AddEmoticon("exc", "/resources/ems/exc.gif");
AddEmoticon("q", "/resources/ems/DDB.gif");
AddEmoticon("NO", "/resources/ems/no.gif");
AddEmoticon("OK", "/resources/ems/ok.gif");
AddEmoticon("WOW", "/resources/ems/8E7.gif");
AddEmoticon("LOL", "/resources/ems/201.gif");
AddEmoticon("oo", "/resources/ems/papel.gif");
AddEmoticon("RIP", "/resources/ems/rip.gif");
AddEmoticon("alien", "/resources/ems/koe.gif");
AddEmoticon("trago", "/resources/ems/106.gif");
AddEmoticon("money", "/resources/ems/verde.gif");
AddEmoticon("dolar", "/resources/ems/dolar.gif");
AddEmoticon("culo", "/resources/ems/culo.gif");
AddEmoticon("buenpost", "/resources/ems/buenpost.gif");
AddEmoticon("GET_A_LIFE", "/resources/ems/getalife.gif");
AddEmoticon("auto", "/resources/ems/car.gif");
AddEmoticon("lala", "/resources/ems/D31.gif");
AddEmoticon("fantasma", "/resources/ems/A7B.gif");
AddEmoticon("headbang", "/resources/ems/6D2.gif"); 
AddEmoticon("limon", "/resources/ems/limoon.gif");

const getUrl = (code:string)=>{
    for (let index = 0; index < emoticones.length; index++) {
        if(emoticones[index].code.toLowerCase()  === code.toLowerCase() && emoticones[index].url !== ""){ 
            return emoticones[index];
        }
    }
    return null;
}

export default {
    parse_emoticones : (raw:string)=>{
        var macth:[string,string][] = [];
        while(true){
            const r = regex.exec(raw);
            if(r === null) break;
            macth.push(r as any);
        }
        for (let index = 0; index < macth.length; index++) {
            const ma = macth[index];
            const emote = getUrl(ma[1]);
            if(!emote) continue;
            raw = raw.replace(ma[0], `<img emoticon="${ma[1]}" src="${emote.url}" />`);
        }
        return raw;
    },
    parse_emoticones_dom : (text:string)=>{
        let ii = 0;
        let raw = reactStringReplace(text, regex, (match, i) => {
            ii++;
            const emote = getUrl(match);            
            if(emote==null) return <Fragment key={ii}>{":"+match+":"}</Fragment>;
            //@ts-ignore
            return <img key={`${match}-${ii}`} emoticon={match} src={emote.url}/>;
        });
        return raw;  
    },
    GetList: ()=>{
        return emoticones;
    },
    GetUrl: (code:string)=>{
        return emoticones.find(e => e.code === code).url;
    }
}

