import React from "react";
import { Routes, Route } from "react-router-dom";
import { Panel } from "../../../components/styleguide/components/Panel";
import { DocumentTitle } from "../../../components/utils/DocumentTitle";
import { useDevice } from "../../../components/utils/mscreen";
import { SoftPost } from "../../../hooks/apis/usePost";
import { SoftShout } from "../../../hooks/apis/useShout";
import { WidthRead } from "../../../hooks/useMemoHeight";
import { ScrollPost } from "../../components/Scrolling/ScrollPost";
import { ScrollShout } from "../../components/Scrolling/ScrollShout";
import { useProfile } from "../context";
import { ScrollComment } from "../../components/Scrolling/ScrollComment";
import { UserType } from "../../../hooks/apis/useUser";
import { PanelOfProfile } from "../profile";

const ScrollCommentProfile:React.FC<{user:UserType}> = ({user})=>{
    const {isMobile} = useDevice()
    return <ScrollComment.StyleNone 
        key={"user-comments-"+user._id+""+isMobile}
        id={"user-comments-"+user._id}
        small={isMobile}
        initialSetting={{
            count: 10,
            user_id: user._id
        }}
    />
}

export const ProfileUserContent:React.FC = () => {
    const { user } = useProfile()
    const {isMobile} = useDevice()

    return (
        <PanelOfProfile >
            <Panel.Nav skipScroll={false} listenLocation>
            {[
                { name: "Shouts", to: `/user/${user._id}/${user.username}`, key: "publicaciones" },
                { name: "Posteos", to:`/user/${user._id}/${user.username}/recents`,key: "recientes" },
                { name: "Comentarios", to:`/user/${user._id}/${user.username}/comments`,key: "comentarios" },
            ]}
            </Panel.Nav>
            <Routes>
                <Route path={`recents`} element={<>
                    <DocumentTitle title={`Post - ${user.username}`}/>
                    <ScrollPost.StyleNone
                        key={"user-recents-"+user._id+(isMobile?"-m":"")}
                        id={"user-recents-"+user._id}
                        small={isMobile}
                        mobile={isMobile}
                        initialSetting={{
                            soft: SoftPost.Recientes,
                            user_id: user._id,
                            count: 10,
                            uff: true,
                            see_relevance: localStorage.relevance,
                        }}
                    />
                </>}>
                </Route>
                <Route path={`comments`} element={<>
                    <DocumentTitle title={`Comentarios - ${user.username}`}/>
                    <ScrollCommentProfile user={user}/>
                </>}>
                </Route>
                {/* exact path={`/user/${_id}/${username}`} */}
                <Route path="/" element={<>
                        <WidthRead/>
                        <DocumentTitle title={`${user.username}`}/>
                        <ScrollShout.StyleNone
                            key={`user-${user._id}`}
                            id={`user-${user._id}`}
                            btnRetryClass='mb-3' 
                            initialSetting={{
                                count: 10,
                                soft: SoftShout.Recientes,
                                user_id: user._id
                            }}
                        /> 
                </>}>
                </Route>
            </Routes>
        </PanelOfProfile>
    );
}