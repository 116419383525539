import React from 'react';

if(window.ajax === undefined) window.ajax = {};

class _ajax_ extends React.Component {
    state = {
        mode : "load",
        data: null,
        error: null,
        cound: 0
    }
    
    async reload({update=true, reset=false}={}){
        const _cound = update? this.state.cound+1: 0;
        if(reset) this.setState({mode:"load", data: null , cound: _cound});
        this.path = this.props.path;
        const _ok = this.props._ok?this.props._ok: ()=>{};

        if( this.props._key!==undefined ) {window.ajax[this.props._key] = this; this._key = this.props._key;}

        if(this.props.type === undefined) this.props.type = "get";
        if(this.props.type === "post"){
            try {
                var result1 = await window.POST(this.props.path, this.props.data, this.props.ajax_setting);
                _ok(result1);
                this.setState({mode:"complete", data: result1 , cound: _cound});
            } catch (error) {
                this.setState({mode:"error", error: error, cound: _cound});
            }
        }
        if(this.props.type === "get"){
            try {
                var result2 = await window.GET(this.props.path, this.props.ajax_setting);
                _ok(result2);
                this.setState({mode:"complete", data: result2, cound: _cound});
            } catch (error) {
                this.setState({mode:"error", error: error, cound: _cound});
            }
        }
        if(this.props.type === "get2"){
            try {
                var result3 = await window.GET2(this.props.path, this.props.data, this.props.ajax_setting);
                _ok(result3);
                this.setState({mode:"complete", data: result3, cound: _cound});
            } catch (error) {
                this.setState({mode:"error", error: error, cound: _cound});
            }
        }
        return true;
    }

    async componentDidMount() {
        this.first = true;
        await this.reload();
    }
    async componentDidUpdate(){
        if(this.path !== this.props.path || this._key !== this.props._key){
            this.setState({mode:"load", date: null, error: null});
            await this.reload();
        }
    }
    componentWillUnmount() {

    }

    render(){
        const {loading, err, ok,ok2=false} = this.props;
        const {mode,data, error} = this.state;
        //const {first} = this;
        this.first = false;
        const COk = ok2;

        if(mode === "load"){
            return (
                <>
                    {loading()}
                </>
            );
        }
        if(mode === "error"){
            return (
                <>
                    {err(error)}
                </>
            );
        }
        if(mode === "complete"){
            if(COk){
                return <COk r={data} shared={window.shared} _this={this}/>
            }
            return (
                <>
                    {ok(data,window.shared,this)}
                </>
            );
        }
        return (<div>No esta bien configurado!</div>);
    }
}

export default _ajax_;