import type {} from "../../../../../node_ts/src/shared/schemas/favs.schema";
import { ApiType } from "./useApi";

export type IFav = {
    _id: string,
    type: ITypeFavs, 
    to: any, 
}

export const FindFavs = (params:_Favorite.Gets)=>{
    return window.POST<ApiType<{
        favs: IFav[],
        cound: number
    }>>("/api/fav/gets",params );
}