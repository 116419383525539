import React, { useRef } from "react";
import { Navigate  } from "react-router-dom";
import Loading from "./loading";
const { usePeluchan, refPeluchan } = require("../../contexts/PeluchanContext");

export type RequiereLoginProps = React.PropsWithChildren<{
    to?: string
    out?: React.ReactNode, 
    waitRetry?:boolean,
}>
export const RequiredLogin:React.FC<RequiereLoginProps> = ({ to = "/home", children, out, waitRetry=false }={}) => {
    const {
        login: { status },
    } = usePeluchan();
    const initRef = useRef<boolean>(false);
    if (status === "ok") {
        initRef.current = true;
        return <>{children}</>;
    }
    
    if (status === "loading") return null;
    if (status === "out") return out ? <>{out}</> : <Navigate to={to} replace />;
    return <Loading />;
}

export type RRequiredAnonProps = React.PropsWithChildren<{
    to?: string
}>
export function RequiredAnon({ to = "/home", children }:RRequiredAnonProps) {
    const {
        login: { status },
    } = usePeluchan();
    if (status === "ok") return <Navigate to={to} replace />;
    if (status === "loading") return null;
    if (status === "out") return <>{children}</>;
    return <>{children}</>;
}
export type RequiredSessionProps = React.PropsWithChildren<{
}>
export function RequiredSession({ children }:RequiredSessionProps) {
    const {
        login: { isToken },
    } = usePeluchan();
    if (!isToken) return null;
    return <>{children}</>;
}

export function withRequiredSesion(Component, _props:RequiredSessionProps={}) {
    return function callBack(props) {
        return (
            <RequiredSession {..._props}>
                <Component {...props} />
            </RequiredSession>
        );
    };
}
export function withRequiredLogin(Component, _props: RequiereLoginProps={}) {
    return function callBack(props) {
        return (
            <RequiredLogin {..._props}>
                <Component {...props} />
            </RequiredLogin>
        );
    };
}
export function withRequiredAnon(Component, _props:RRequiredAnonProps={}) {
    return function callBack(props) {
        return (
            <RequiredAnon {..._props}>
                <Component {...props} />
            </RequiredAnon>
        );
    };
}
