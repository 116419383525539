
type BroadcastType = {
    tabId: number
}  

export class Channel<T>{
    tabId = Date.now()
    onmessage
    channel:BroadcastChannel
    constructor(name: string){
        this.channel = new BroadcastChannel(name);

        this.channel.onmessage = (event:MessageEvent<BroadcastType & T>) => {
            const v = event.data
            if(v.tabId  == this.tabId) return
            this.onmessage(event)
        }
    }
    setChannel(v: (v:MessageEvent<BroadcastType & T>)=> void){
        this.onmessage = v
    }
    postMessage(obj:T){
        this.channel.postMessage(Object.assign(obj, {tabId: this.tabId}));
    }
}
