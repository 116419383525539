import { Link } from "react-router-dom"
import { ImagenUsuario } from "../../Imagenes/Usuario"
import { UserType } from "../../../../hooks/apis/useUser"
import React from "react"

export const CommentPhoto:React.FC<{user: UserType, small: boolean, isMe?: boolean}> = ({user, small, isMe})=>{
    return <Link to={`/user/${user._id}/${user.username}`} className="absolute">
        {small?
            <ImagenUsuario.Small40 className="" pId={"KIcaPMsCBa"} src={user.photo} styles={isMe?"primaryRed":"primary"}/>:
            <ImagenUsuario.Small50 className="" pId={"1lv6NThaBQ"} src={user.photo} styles={isMe?"primaryRed":"primary"}/>
        }
    </Link>
}