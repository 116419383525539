import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({color,...props}: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
    <g
        {...props}
        transform={`translate(.5 .8), scale(0.8)`}
        >
        <path fill={color} d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
    </g>
    </>
)

export default ({borde1, backgroundShadow1}: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
        <g transform="translate(1.4 1)" >
            <Icon color={backgroundShadow1}/>
        </g>
        <Icon color={borde1}/>
    </>
)
