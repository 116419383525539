import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({color,...props}: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
    <g
        {...props}
        transform={`translate(0 0), scale(.19 .19), rotate(0)`}
        > 
        <g>
	<g>		
    <path fill={color} d="M15.398,44.798c2.209,0,4.197-0.003,6.185,0.001c6.225,0.011,10.88,5.251,10.174,11.45
			c-0.465,4.078-3.426,7.536-7.378,8.614c-4.117,1.124-8.437-0.343-10.95-3.692c-1.354-1.804-1.969-3.857-2.102-6.109
			c-2.696,0-5.345,0-7.992,0c-0.163,6.562,3.994,14.534,12.626,17.376c8.029,2.644,16.974-0.702,21.325-7.957
			c2.212-3.688,3.092-7.694,2.476-11.941c-0.902-6.214-4.26-10.797-9.726-13.894c2.933-3.986,5.815-7.903,8.817-11.984
			c-0.553,0-0.917-0.001-1.281,0c-5.363,0.012-10.726,0.028-16.089,0.036c-2.749,0.004-5.498,0.004-8.247-0.02
			c-0.506-0.004-0.839,0.147-1.143,0.566c-1.63,2.245-3.29,4.468-4.938,6.7c-0.18,0.244-0.345,0.5-0.616,0.896
			c5.484,0,10.77,0,16.175,0C20.264,38.177,17.894,41.402,15.398,44.798z M37.169,67.851c4.79,4.51,10.393,6.429,16.785,5.033
			c7.377-1.612,12.31-6.232,14.006-13.617c1.97-8.579-1.284-15.267-8.48-20.22c2.987-4.065,5.989-8.15,9.057-12.325
			c-0.378-0.028-0.588-0.057-0.799-0.057c-8.302,0.006-16.603,0.01-24.905,0.044c-0.31,0.001-0.721,0.223-0.911,0.473
			c-1.805,2.384-3.573,4.796-5.348,7.202c-0.072,0.098-0.119,0.216-0.229,0.419c2.739,0,5.373,0,8.008,0c2.656,0,5.311,0,8.128,0
			c-2.471,3.37-4.844,6.606-7.332,9.999c1.871,0,3.533,0.059,5.19-0.011c5.168-0.219,11.039,4.642,9.975,11.839
			c-0.622,4.203-3.817,7.465-7.956,8.377c-4.037,0.89-8.31-0.88-10.684-4.422c-0.109-0.163-0.233-0.318-0.418-0.569
			C40.439,62.962,39.103,65.516,37.169,67.851z M74.592,44.798c1.585,0,2.93,0,4.274,0c3.66,0,6.487,1.578,8.482,4.603
			c2.947,4.469,1.86,10.541-2.406,13.731c-4.352,3.254-10.411,2.609-13.918-1.491c-0.413-0.483-0.779-1.007-1.225-1.588
			c-0.788,2.946-2.172,5.497-4.008,7.78c6.53,6.722,17.989,7.606,25.58,0.419c3.566-3.376,5.499-7.563,5.675-12.464
			c0.25-6.971-2.746-12.348-8.422-16.321c3.119-4.241,6.204-8.436,9.375-12.748c-0.452-0.024-0.738-0.053-1.024-0.053
			c-8.219,0.006-16.439,0.009-24.658,0.045c-0.33,0.001-0.766,0.242-0.97,0.509c-1.52,1.99-2.995,4.015-4.483,6.031
			c-0.345,0.467-0.684,0.938-1.149,1.577c5.491,0,10.788,0,16.204,0C79.451,38.187,77.082,41.411,74.592,44.798z"/>
		<path fill={color} d="M15.398,44.798c2.495-3.396,4.865-6.621,7.317-9.958c-5.405,0-10.691,0-16.175,0
			c0.271-0.395,0.436-0.651,0.616-0.896c1.647-2.232,3.308-4.455,4.938-6.7c0.304-0.419,0.637-0.57,1.143-0.566
			c2.749,0.024,5.498,0.024,8.247,0.02c5.363-0.008,10.726-0.024,16.089-0.036c0.364-0.001,0.728,0,1.281,0
			c-3.002,4.08-5.884,7.997-8.817,11.984c5.465,3.097,8.824,7.68,9.726,13.894c0.616,4.248-0.264,8.253-2.476,11.941
			c-4.352,7.255-13.296,10.601-21.325,7.957C7.329,69.596,3.172,61.625,3.335,55.062c2.647,0,5.295,0,7.992,0
			c0.133,2.252,0.748,4.305,2.102,6.109c2.513,3.348,6.833,4.815,10.95,3.692c3.952-1.079,6.913-4.536,7.378-8.614
			c0.707-6.199-3.949-11.44-10.174-11.45C19.595,44.795,17.608,44.798,15.398,44.798z"/>
		<path fill={color} d="M37.169,67.851c1.934-2.335,3.27-4.888,4.087-7.835c0.185,0.252,0.309,0.406,0.418,0.569
			c2.374,3.542,6.648,5.312,10.684,4.422c4.139-0.912,7.335-4.174,7.956-8.377c1.064-7.198-4.807-12.059-9.975-11.839
			c-1.657,0.07-3.32,0.011-5.19,0.011c2.488-3.393,4.861-6.629,7.332-9.999c-2.817,0-5.473,0-8.128,0c-2.635,0-5.269,0-8.008,0
			c0.11-0.203,0.156-0.321,0.229-0.419c1.776-2.406,3.543-4.818,5.348-7.202c0.19-0.251,0.601-0.472,0.911-0.473
			c8.302-0.034,16.603-0.038,24.905-0.044c0.211,0,0.421,0.029,0.799,0.057c-3.068,4.175-6.069,8.26-9.057,12.325
			c7.197,4.953,10.451,11.641,8.48,20.22c-1.696,7.385-6.629,12.006-14.006,13.617C47.562,74.28,41.959,72.361,37.169,67.851z"/>
		<path fill={color} d="M74.592,44.798c2.49-3.388,4.859-6.612,7.329-9.972c-5.417,0-10.714,0-16.204,0
			c0.465-0.638,0.804-1.109,1.149-1.577c1.488-2.015,2.963-4.04,4.483-6.031c0.205-0.268,0.64-0.508,0.97-0.509
			c8.219-0.036,16.439-0.039,24.658-0.045c0.286,0,0.572,0.029,1.024,0.053c-3.171,4.312-6.257,8.507-9.375,12.748
			c5.676,3.973,8.672,9.35,8.422,16.321c-0.176,4.901-2.109,9.088-5.675,12.464c-7.591,7.187-19.051,6.302-25.58-0.419
			c1.836-2.283,3.22-4.834,4.008-7.78c0.446,0.581,0.811,1.105,1.225,1.588c3.507,4.1,9.566,4.745,13.918,1.491
			c4.266-3.189,5.353-9.262,2.406-13.731c-1.995-3.025-4.822-4.603-8.482-4.603C77.522,44.798,76.177,44.798,74.592,44.798z"/>
	</g>
</g>
 </g>
    </>
)

export default ({borde1, backgroundShadow1}: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
    <g transform="translate(0.3 1.7), rotate(0), scale(1 1)" >
        <g transform="translate(1.4 1.5)" >
            <Icon color={backgroundShadow1}/>
        </g>
        <Icon color={borde1}/>
    </g>
    </>
)
