import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({color,...props}: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
    <g
        {...props}
        transform={`translate(0 0), scale(.18 .17), rotate(0)`}
        > 
        <g>
	<g>		
        <path fill={color} d="M29.097,57.703c0-7.756-0.026-15.513,0.027-23.269c0.008-1.17-0.352-1.437-1.463-1.423
            c-4.562,0.059-9.125,0.004-13.687,0.042c-0.923,0.008-1.268-0.229-1.259-1.215c0.046-4.79,0.043-9.581,0.002-14.371
            c-0.008-0.921,0.199-1.258,1.196-1.256c15.741,0.03,31.481,0.029,47.222,0.001c0.922-0.002,1.131,0.295,1.124,1.162
            c-0.037,4.836-0.048,9.673,0.006,14.508c0.012,1.029-0.388,1.177-1.274,1.17c-4.425-0.037-8.851-0.017-13.277-0.016
            c-1.797,0-1.799,0.001-1.799,1.747c-0.001,15.376-0.018,30.751,0.03,46.127c0.004,1.312-0.312,1.681-1.646,1.655
            c-4.606-0.089-9.216-0.077-13.823-0.005c-1.173,0.018-1.414-0.343-1.407-1.452C29.12,73.307,29.097,65.505,29.097,57.703z"/>
        <path fill={color} d="M69.666,38.842c0-7.113,0.021-14.226-0.023-21.338c-0.006-1.017,0.257-1.313,1.29-1.302
            c4.787,0.055,9.575,0.056,14.362,0c1.042-0.012,1.289,0.302,1.286,1.309c-0.032,14.18-0.033,28.36,0.001,42.54
            c0.003,1.068-0.271,1.391-1.364,1.377c-4.741-0.06-9.484-0.062-14.225,0.001c-1.111,0.015-1.356-0.337-1.35-1.386
            C69.687,52.976,69.666,45.909,69.666,38.842z"/>
        <path fill={color} d="M78.195,64.679c2.371,0,4.742,0.033,7.112-0.017c0.894-0.019,1.286,0.16,1.275,1.179
            c-0.051,4.832-0.043,9.666-0.005,14.498c0.007,0.899-0.27,1.169-1.166,1.162c-4.878-0.038-9.757-0.041-14.635,0.002
            c-0.942,0.008-1.13-0.348-1.125-1.197c0.032-4.833,0.037-9.666-0.004-14.499c-0.008-0.904,0.27-1.168,1.162-1.148
            C73.27,64.715,75.733,64.68,78.195,64.679z"/>
	</g>
</g>
 </g>
    </>
)

export default ({borde1, backgroundShadow1}: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
    <g transform="translate(1.0 2.7), rotate(0), scale(1 1)" >
        <g transform="translate(1.4 1.5)" >
            <Icon color={backgroundShadow1}/>
        </g>
        <Icon color={borde1}/>
    </g>
    </>
)
