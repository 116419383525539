import React, { useMemo } from "react";
import { refEnv } from "../../../../../../contexts/EnvironmentContext";
import { useCssHandles } from "../../../../../../hooks/useCssHandles";
import { Imagen } from "../../../../../Imagen/Imagen";

export const MenuUserItem:React.FC<{
    user:{
        name:string,
        photo: string
    }
}> = ({user})=>{
    const styles = useCssHandles("nUTyx",["photo","name"] as const);
    const {name="",photo=""} = user;

    return (
        
        <div className={styles.ROOT}>
            <Imagen className={styles.photo} pId="dg0elqK0hD" src={photo}/>
            <span className={styles.name}>{name}</span>
        </div>
    );
}

