import React, { useEffect, useMemo, useReducer } from 'react';
import Loading from './loading';
import Message from '../styleguide/components/legacy/message';
import { GlobalState, LazyState, TempState } from './LazyState';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { hashCode } from '../../utils/hastCode';


type MemoTypes = "LazyState" | "TempState" | "GlobalState"
const XState = (type: MemoTypes="LazyState")=>{
    if(type == "LazyState") return LazyState
    if(type == "TempState") return TempState
    if(type == "GlobalState") return GlobalState
}

function build_init<R, I>(key:string, memoType: MemoTypes, initSetting:R):ScrollState<R,I>{
    const keyMemo = "memo-scrollling-"+key
    const memo = XState(memoType).get(keyMemo);

    const isMemo = !!memo

    return { 
        memo: isMemo? memo: {
            items: [],
            status: "loading",
            hasMore: true,
            setting: initSetting,
            settingHash: hashCode(JSON.stringify(initSetting))
        }, 
        enabled_resquest: !isMemo,
        memoType: memoType,
        key: keyMemo 
    }
}

type ScrollState<R,I>= {
    key:string,
    memo: {
        status: "ok" | "loading" | "error",
        items: I[],
        hasMore: boolean,
        setting: R,
        settingHash: number,
    },
    memoType: MemoTypes,
    enabled_resquest: boolean,
};
  
export type ScrollAction<I, S> = { 
    type: 'GET_OK',
    newItems: I[],
    setterSetting?: S,
    updateSetting?: Partial<S>,
    hasMore?: boolean
} | { 
    type: 'GET_ERROR' 
}| { 
    type: 'ON_RETRY' 
}| { 
    type: 'SCROLL_END'
};

const scrollReducer = (state: ScrollState<unknown, unknown>, action: ScrollAction<unknown, unknown>): ScrollState<unknown, unknown> => {
    switch (action.type) {
        case 'GET_ERROR':

        return {
            ...state,
            memo:{
                ...state.memo,
                status: "error"
            }, 
            enabled_resquest: false
        };
        case 'GET_OK':

            let setting = action.setterSetting || {}
            if(action.updateSetting) setting =  Object.assign({},state.memo.setting, action.updateSetting)

            const newMemo:ScrollState<unknown, unknown>["memo"] = {
                items: state.memo.items.concat(action.newItems),
                status: "ok",
                hasMore: action.hasMore,
                setting,
                settingHash: hashCode(JSON.stringify(setting))
            };
            
            XState(state.memoType).set(state.key, newMemo, {ttlLazy: 1000})

            return {
                ...state, 
                memo: newMemo,
                enabled_resquest: false
            };

        case 'ON_RETRY':
            return {
                ...state,
                enabled_resquest: true
            };

        case 'SCROLL_END':
            return {
                ...state,
                enabled_resquest: true
            };

        default:
        return state;
    }
};

const ButtonRetry:React.FC<JSX.IntrinsicElements["button"]> = ({className, ...args})=>{
    return <button type="button" className={cn("btn btn-shoutscroll-retry btn-primary w-100 btn-sm style btn-style-error mr-2", className)} {...args} style={{
        height: "50px",
        fontSize: "18px",
        fontWeight: 700
    }}>Reintentar</button> 
}


export type ScrollFetcherType<T = any, I=unknown> = (params:T, state: ScrollState<T,I>, dispatch: React.Dispatch<ScrollAction<I, T>>)=> void

export type ScrollingProps<F extends ScrollFetcherType> = {
    id: string,
    fetcher: F,
    initialSetting?: Parameters<F>[0],
    btnRetryClass?: string,
    loadingComponent?: JSX.Element,
    disabledScroll?: boolean,
    limitSize?:number,
    memoType?: MemoTypes,
    loadingMoreComponent?: JSX.Element,
    zeroComponent?: JSX.Element,
    errorComponent?: JSX.Element,
    demo?: "loading" | "loadingMore" | "error" | "zero-items" | "retry",
    root_query?: string,
    disabledZeroComponent?: boolean,
    children: ScrollChildren<any>
}
export type ScrollChildren<T> = (items:T[])=> JSX.Element

function Element<F extends ScrollFetcherType>(v :ScrollingProps<F>){
    const initReducer = useMemo(()=> build_init(v.id, v.memoType, v.initialSetting),[])
    const [state, dispatch] = useReducer(scrollReducer, initReducer);
    const isOverflowSize = v.limitSize && state.memo.items.length >= v.limitSize

    useEffect(()=>{
        if(!state.enabled_resquest) return
        if(v.demo == "loadingMore" && state.memo.items.length != 0) return
        v.fetcher(state.memo.setting, state, dispatch)
    }, [state.enabled_resquest, state.memo.settingHash]);

    if(v.demo){
        if(v.demo == "loading") return v.loadingComponent
        if(v.demo == "error") return v.errorComponent
        if(v.demo == "zero-items") return v.zeroComponent
        if(state.memo.items.length>0){
            if(v.demo == "loadingMore") state.enabled_resquest = true;
            // if(v.demo == "retry") {
            //     state.enabled_resquest = true;
            //     if(state.memo.status !== 'error') dispatch({type: "GET_ERROR"})
            // }
        }
    }

    useEffect(() => {
        const handleUnload = (event) => {
            const isPageReload = !event.persisted;
            if (isPageReload) XState(state.memoType).set(state.key, undefined, {noLazy: true})
        };
        window.addEventListener('beforeunload', handleUnload);
        return () => window.removeEventListener('beforeunload', handleUnload);
    }, []);


    if(state.memo.status === "loading") 
        return v.loadingComponent || <Loading margin="20"></Loading>;

    if((state.memo.status === "error") && state.memo.items.length == 0) 
        return v.errorComponent || <Message className="mt-3 pb-3" message="Algo fallo!"></Message>

    if(state.memo.items && state.memo.items.length ===0 && !v.disabledZeroComponent) 
        return v.zeroComponent || <Message className="mt-3 pb-3" message="Nada por aqui"></Message>

    if(v.disabledScroll) {
        return <>
            {v.children(state.memo.items)}
        </>
    }

    return <InfiniteScroll 
        initialLoad={false} 
        hasMore={state.memo.hasMore && !isOverflowSize} 
        threshold={window.innerHeight} 
        getScrollParent={!v.root_query?undefined:()=> document.querySelector(v.root_query)}
        useWindow={!v.root_query}
        loadMore={()=>{
            if(state.enabled_resquest) return
            if(state.memo.status === "error") return
            dispatch({type: "SCROLL_END"})
        }}
    >
        {v.children(state.memo.items)}
        {state.enabled_resquest && (v.loadingMoreComponent || <Loading margin="20"></Loading>) }
        {!state.enabled_resquest && state.memo.status === "error" && 
            <ButtonRetry className={v.btnRetryClass} onClick={()=>dispatch({type: "ON_RETRY"})} />
        }
    </InfiniteScroll>;
}


export const Scrolling = React.memo(Element)