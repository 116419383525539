import * as React from "react";
import { SVGProps, memo } from "react";

const Icon = ({
  color,
  ...props
}: SVGProps<SVGGElement> & {
  color: string;
}) => (
  <>
    <g {...props} transform={`translate(2.6 -1), scale(.17 .17), rotate(15)`}>
      <path
        fill={color}
        d="M93.91,10.94c-2.56-0.06-5.11-0.03-7.67-0.03c-3.39,0-6.79-0.01-10.18,0.01c-0.38,0-0.6-0.12-0.8-0.45
			c-1.48-2.44-3.64-4.13-6.11-5.46c-3.39-1.82-7.03-2.84-10.81-3.34c-2-0.26-4.02-0.38-6.03-0.56C52.2,1.1,52.1,1.06,52,1.04h-4.79
			c-0.92,0.07-1.83,0.13-2.75,0.21c-5.05,0.43-9.96,1.39-14.47,3.86c-2.46,1.34-4.59,3.06-6.06,5.52c-0.1,0.15-0.4,0.27-0.6,0.27
			c-5.82,0.01-11.65,0.02-17.47,0.01c-1.58-0.01-2.87,0.52-3.61,1.96c-0.26,0.5-0.43,1.11-0.44,1.67
			c-0.08,6.81,1.21,13.32,4.38,19.39c2.31,4.41,5.44,8.17,9.1,11.5c3.39,3.07,7.13,5.68,10.9,8.24c3.13,2.11,6.26,4.23,9.3,6.47
			c2.43,1.78,4.61,3.84,6.15,6.48c1.12,1.9,1.69,3.97,1.72,6.18c0.04,2.43,0.01,4.86,0.01,7.31c-1.18,0.18-2.33,0.33-3.48,0.54
			c-3.25,0.6-6.42,1.48-9.34,3.08c-1.35,0.74-2.58,1.62-3.54,2.85c-1.3,1.66-1.35,3.71-0.12,5.42c1.04,1.45,2.47,2.42,4.03,3.23
			c3.26,1.68,6.77,2.59,10.38,3.09c1.86,0.26,3.74,0.37,5.6,0.56c0.18,0.01,0.35,0.05,0.52,0.08h4.48c0.77-0.07,1.54-0.14,2.32-0.2
			c3.92-0.28,7.77-0.96,11.47-2.33c2.22-0.82,4.34-1.85,6.03-3.58c2.1-2.14,2.14-4.67,0.09-6.85c-1.29-1.37-2.88-2.28-4.58-3.03
			c-3.27-1.44-6.7-2.26-10.24-2.68c-0.32-0.04-0.63-0.09-1.02-0.15c-0.01-0.16-0.02-0.35-0.02-0.53c-0.01-2.19-0.01-4.37,0-6.56
			c0-3.26,1.16-6.09,3.24-8.56c2.3-2.75,5.16-4.86,8.08-6.87c3.51-2.42,7.06-4.76,10.54-7.21c4.08-2.87,7.83-6.11,11-9.99
			c3.19-3.9,5.55-8.25,6.99-13.09c1.22-4.12,1.73-8.32,1.72-12.6C97.52,12.58,96.06,10.99,93.91,10.94z M25.73,44.25
			c-2.93-2.11-5.7-4.4-8.13-7.08c-4.22-4.63-6.86-10.03-7.8-16.23c-0.11-0.72-0.21-1.44-0.31-2.16c-0.01-0.12,0-0.24,0-0.4h13.32
			c0.32,9.55,1.65,18.9,5.58,27.75C27.51,45.51,26.61,44.88,25.73,44.25z M56,53.55c-1.66,1.59-3.71,2.39-6.17,2.39
			c-2.34,0-4.27-0.67-5.8-2.01c-1.54-1.35-2.43-3.1-2.68-5.27l4.78-0.58c0.16,1.22,0.57,2.15,1.24,2.8
			c0.66,0.64,1.47,0.96,2.42,0.96c1.02,0,1.88-0.38,2.58-1.16c0.69-0.77,1.04-1.81,1.04-3.13c0-1.24-0.33-2.22-1-2.95
			c-0.67-0.72-1.48-1.09-2.44-1.09c-0.64,0-1.39,0.12-2.27,0.37l0.54-4.02c1.34,0.03,2.36-0.26,3.06-0.87
			c0.71-0.62,1.06-1.44,1.06-2.46c0-0.86-0.26-1.56-0.78-2.07c-0.51-0.52-1.2-0.77-2.05-0.77c-0.85,0-1.57,0.29-2.16,0.87
			c-0.6,0.59-0.97,1.45-1.09,2.57l-4.56-0.77c0.32-1.56,0.8-2.81,1.44-3.74c0.63-0.93,1.52-1.66,2.67-2.2
			c1.14-0.53,2.42-0.8,3.84-0.8c2.42,0,4.37,0.78,5.83,2.33c1.21,1.26,1.81,2.69,1.81,4.28c0,2.27-1.23,4.07-3.7,5.42
			c1.47,0.31,2.65,1.02,3.54,2.13c0.88,1.1,1.32,2.43,1.32,3.99C58.47,50.03,57.65,51.96,56,53.55z M69.23,17.31
			c-0.87,1.22-2.03,2.13-3.3,2.89c-2.62,1.58-5.48,2.45-8.47,3c-2.57,0.47-5.16,0.69-7.77,0.67c-4.98-0.04-9.87-0.6-14.46-2.7
			c-1.72-0.78-3.32-1.77-4.6-3.19c-1.53-1.68-1.53-2.88,0-4.55c1.79-1.94,4.08-3.09,6.52-3.97c3.23-1.16,6.59-1.63,10-1.82
			c3.61-0.2,7.2-0.01,10.75,0.67c3.48,0.67,6.79,1.78,9.61,4.03c0.64,0.51,1.22,1.14,1.72,1.79C70.08,15.25,70.04,16.16,69.23,17.31
			z M89.6,20.54c-0.84,5.63-2.98,10.69-6.58,15.11c-2.71,3.33-5.94,6.08-9.41,8.58c-0.89,0.64-1.79,1.27-2.68,1.91
			c-0.03-0.03-0.07-0.06-0.1-0.09c4.03-8.78,5.35-18.11,5.67-27.68h13.36C89.77,19.11,89.71,19.83,89.6,20.54z"
      />
    </g>
  </>
);

export default ({
  borde1,
  primary1,
  backgroundShadow1,
}: SVGProps<SVGGElement> & {
  borde1: string;
  primary1?: string;
  backgroundShadow1: string;
}) => (
  <>
    <g transform="translate(-0.5 4.7),  rotate(-15)">
      <g transform="translate(1.4 1.5)">
        <Icon color={backgroundShadow1} />
      </g>
      <Icon color={primary1 || borde1} />
    </g>
  </>
);
