
import emoticones from '../../../../utils/emoticones'
import etiquetas from '../../../../utils/etiquetas'

var {parse_emoticones, parse_emoticones_dom} = emoticones;
var {parse_etiquetas, parse_etiquetas_dom, parse_etiquetas_shouts_dom} = etiquetas;

export type EnhanceTextParams = {
    show_shoutTags?: boolean
}
export function EnhanceText(text, params: EnhanceTextParams={}){
    text = parse_emoticones_dom(text);
    text = parse_etiquetas_dom(text);
    if(params.show_shoutTags) text = parse_etiquetas_shouts_dom(text);
    return text;
}