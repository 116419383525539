import React, { useRef, useState } from "react";
import { Fragment } from "react";
import { useShout } from "../../item-shout"
import { Link } from 'react-router-dom'
import Setting from "./setting";
import AuthorShared from "./author_shared";
import { Imagen, ImgDefault404 } from "../../../../../components/Imagen/Imagen";
import { Timer } from "../../../../../components/styleguide/components/Timer";
import { ImagenUsuario } from "../../../Imagenes/Usuario";

function Placeholder() {
    const sizes = useRef({
        size0: Math.random() * 60,
        size1: Math.random() * 40
    });
    const size1 = useRef(Math.random()*20);
    return <div>
        <div className="lazy-loading" style={{
            width: "40px",
            height: "40px",
            borderRadius:"3px"
        }}/>
        <div className="lazy-loading" style={{
            width: `${40 + sizes.current.size0}px`,
            height: "12px",
            transform: "translate(50px, -40px)",
            borderRadius:"3px"
        }}/>
        <div className="lazy-loading" style={{
            width: `${20 + sizes.current.size1}px`,
            height: "12px",
            transform: "translate(50px, -35px)",
            borderRadius:"3px"
        }}/>
    </div>
}

const HeaderMedia = ({showOptions})=>{
    const {
        shout,
        originShout,
        props: { backgraund_link = false },
        components: { ShoutLink, ShoutLazyLoad }
    } = useShout();

    const {
        created
    } = shout;

    const author = typeof shout.author === "string"? {_id: shout.author, name: shout.author, username:"***", photo: undefined} : shout.author;

    const url_user = `/user/${author?._id}/${author.username}`;

    const CONTENT = <div className="shout-header relative h-[40px] mb-2" >
        {showOptions && <Setting shout={shout}/>}
        <Link to={url_user} onClick={(e)=>{ e.stopPropagation(); }}>
            <ImagenUsuario.Small40 pId="CtFzb96FNP" src={author?.photo} alt={author?.username} pDefault={ImgDefault404.nano} resetSize={false} style={{backgroundPosition:"center"}}/>
        </Link>
        <div className=" absolute left-[calc(40px+0.5rem)] top-0 right-8 h-[40px] nowrap items-baseline header">
            <div className="truncate">
                <Link to={url_user} onClick={(e)=>{ e.stopPropagation(); }}>
                    <span className="text-[#00a2ff]">{author.name}</span>
                </Link>
                <span className="inline-block dark:bg-gray-500/75 bg-gray-400 rounded-full h-1 w-1 align-middle mx-1.5  "/>
                <span className="username text-[14px] color_rosa">{author.username}</span>
            </div>
            <Timer.withHover time={created} className=" text-xs text-gray-400 block" icon={true}/>
        </div>
    </div>;

    if(backgraund_link) return <ShoutLink>{CONTENT}</ShoutLink>

    return CONTENT;
}
export const ShoutHeader = ({showOptions=true})=>{
    return <Fragment>
        <AuthorShared/>
        <HeaderMedia showOptions={showOptions}/>
    </Fragment>
}

