import React from 'react';


declare global {
    var popup :_popup_
}

class _popup_ extends React.Component {
    state={
        element: null
    }
    constructor(value){
        super(value);
        
        window.popup = this;
    }

    load(element: JSX.Element){
        this.setState({element: element});
        document.body.style.overflow = "hidden"
    }
    close(){
        this.setState({element: null});
        document.body.style.overflow = ""
    }

    render(){
        const {element} = this.state;
        if(element) return element;
        return null;
    }
}

export default _popup_;