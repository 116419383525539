import React, { useEffect, useMemo, useState } from "react";
import { useShout } from "../item-shout"
import ParseContent from "../../../../components/utils/ParseContent/parse_content";
import { useShoutTags } from "../../../mi/components/TagsTrending/context";
import { LazyState } from "../../../../components/utils/LazyState";
import { Imagen, ImgDefault404 } from "../../../../components/Imagen/Imagen";
import { useSetterContent } from "../../../../components/desktop/header/components/SetterContent";
import cn from "classnames";

const ShoutBody = ()=>{
    const { 
        shout, 
        props:{ full }, 
        components: { ShoutLink, ShoutDivLink },
        style
    } = useShout();
    const tagContext = useShoutTags();
    const {tagsIncludes} = useSetterContent()
    
    let {
        _id,
        portada,
        text,
        body,
        hidden_content
    } = shout;

    
    const stateShowPressKey = _id+":state-nsfw"
    const stateShowPress = useMemo(()=>LazyState.get(stateShowPressKey), [stateShowPressKey])

    const [isHidden, setHidden] = useState((hidden_content && !stateShowPress) && tagContext.tag !== "nsfw");

    useEffect(()=>{
        if(hidden_content !== isHidden && tagContext.tag !== "nsfw"){
            if(hidden_content == true && stateShowPress) return
            setHidden(hidden_content)
        }
    }, [hidden_content]);

    function RemoveHidden() {
        LazyState.set(stateShowPressKey, true)
        setHidden(false)
    }

    if(isHidden && !full && tagsIncludes("nsfw")) return (
        <div className={cn("___dynbodyhidden___ mb-2 ", {"-mx-2": style=="panel"})}>
            {portada && portada.url?
                <Imagen pId="MhPbNfRfmv" src={portada.url} pDefault={ImgDefault404.shoutsTrans} className="fondo"/>
            :null}

            <div className="dynbodyhidden-content">
                <div className="dynbodyhidden-content-title" >
                    <ParseContent body={{type:"text", content: text}} show_shoutTags={true} ></ParseContent>
                </div>
                <div>
                    <button className="dynbodyhidden-content-button outline-none bg-[#ffffff91] dark:bg-[#ffffff11]" onClick={()=>RemoveHidden()}>Mostrar</button>
                </div>
            </div>
        </div>
    );

    const TextContext = !text?null:<div className="text mb-2">
        <ParseContent body={{type:"text", content: text}} show_shoutTags={true}></ParseContent>
    </div>
    
    
    return ((<>
        {TextContext}
        <div className={cn("___dynbody___", {"-mx-2": style=="panel"})}>
            <ParseContent _type="any" body={body as any} imagenKey={shout._id} to={full?null:ShoutLink}  native={full} media-download={full} media-preview={<div className="tl pa5">
                {/* <div className="mb3">
                    <ShoutHeader showOptions={false}/>
                </div> */}
                <div className="">
                    {TextContext||<br/>}
                </div>
                {/* <div className="mt5 w-100 flex justify-between items-between" >
                    <ShoutFooter lazy={false}/>
                </div> */}
            </div>
            }></ParseContent>
        </div>
    </>)
    );
}

export const ShoutContent:React.FC<JSX.IntrinsicElements["div"]> = ({className, ...props})=>{
    return (
        <div {...props} className={"_shoutbody_ "+className}>
            <ShoutBody/>
        </div>
    );
}