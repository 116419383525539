import React, { useState, lazy } from "react";
import { EditorShoutItemType } from "../editor-shout";
import { Button } from "../../../../../../components/styleguide/components/Button";

const ReactPlayer = lazy(()=> import('react-player'))

export const DynMedia:React.FC<{
    onDelete: ()=> void,
    setContent: (v: {url?: string})=> void,
    item: EditorShoutItemType<{url?: string}>
}> = ({onDelete, item, setContent})=>{
        const {content={}} = item;
        const [value, setValue] = useState<string | undefined>();

        let CONTENT = null;

        if(!content.url) CONTENT = (
            <div className="input-group mb-3">
                <input type="text" value={value} onChange={e=> setValue(e.target?.value)} className="form-control" placeholder="Link del video" aria-label="Link del video" aria-describedby="button-addon2" autoFocus/>
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=> setContent({url: value})}>Insertar</button>
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={onDelete}>Volver</button>
                </div>
            </div>
        );
        
        else CONTENT = (
            <div className="toolbal SelectOption">
                <div className="button_close item_media">
                    {/* //@ts-ignore */}
                    <ReactPlayer url={content.url} width={"100%"} height={"100%"} controls={true} nano={true}/>
                    
                    <Button.Icon 
                        className="absolute top-0 right-0 !w-[24px] !h-[24px] text-[16px]" 
                        onClick={onDelete}
                        type="flatBlue"
                        title="Quitar"
                        icon="close"
                    />
                </div>
            </div>
        );

        return (
            <div className="dyn-media">
                {CONTENT}
            </div>
        );
}