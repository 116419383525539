import cn from "classnames"
import React from "react"

const Element:React.FC<JSX.IntrinsicElements["span"]> = ({ className,children ,...args})=>{
    return <span className={cn("material-icons", className)} {...args}>
        {children}
    </span>
}

const Outlined:React.FC<JSX.IntrinsicElements["span"]> = ({ className, children ,...args})=>{
    return <span className={cn("material-icons-outlined", className)} {...args}>
        {children}
    </span>
}

export const MaterialIcon = Object.assign(Element, {
    Outlined
})