import React from 'react';
import Frame from 'react-frame-component';
import { Editor } from '@yansuan/tinymce-react';
import Emoticones from "../../utils/emoticones";
import SR from "./resources";
import { refEnv } from '../../contexts/EnvironmentContext';
import { PeluchanUpload, base64ToBlob } from '../../utils/services/PeluchanUpload';
import { isValidUrl } from './isValidUrl';

const Screen = ({ get }) => {
  const mScreen = new window.MScreen();
  get(mScreen);

  return <></>;
}


// class MyCustomElement extends HTMLElement {
//   connectedCallback() {
//       const mountPoint = document.createElement('div');
//       this.attachShadow({ mode: 'open' }).appendChild(mountPoint);

//       const props = JSON.parse(this.getAttribute('props'));
//       ReactDOM.render(<div {...props} >AAAAAAAAAAAAA</div>, mountPoint);
//   }
// }
// if(window.define1) console.log("AA")
// else{
//   window.define1 = true
//   window.customElements.define('my-custom-element', MyCustomElement);
// }

class _tinymce_ extends React.Component {
  mscreen = null;
  clear() {
    if (!this.editor) {
      console.log("Tinymce no encontrado");
      return;
    }
    this.editor.setContent('');
  }

  toEnd() {
    if (!this.editor) return;
    this.editor.selection.select(this.editor.getBody(), true);
    this.editor.selection.collapse(false);
  }

  push(content, off) {
    if (!this.editor) return;
    this.editor.execCommand('mceInsertContent', false, content);
    //this.editor.selection.setRng(this.editor.selection.getEnd(),true)

  }

  handleEditorChange = (content, editor) => {
    //this.editor = editor;
    const body = { content: content, type: "string/html" }
    if (this.props.onChange) this.props.onChange(body);
    if (this.props.defaultValue) {
      // this.editor.selection.setRng(this.editor.selection.getEnd(),true);
    }
    //localStorage.setItem(`tinymce_${_memory}`,content);
  }

  // getFooter(){
  //     return new Promise((c,e)=>{
  //         var count = 0;
  //         const sleep = () => {
  //             setTimeout(() => {
  //                 count++;
  //                 if(count>50) return;
  //                 const content = document.querySelector(".tox-dialog__footer-start");
  //                 if(content && content.appendChild) c(content);
  //                 else sleep();
  //             }, 250);
  //         };
  //         sleep();
  //     });
  // }

  componentDidMount() {

  }
  render() {
    const _this = this;
    const { mode = "simple", defaultValue, onLoad = () => false } = this.props;

    let autocomplete_etiquetas = false;

    let mce_setting = undefined;
    let mce_mobile = undefined;
    switch (mode) {
      case "Full":
        mce_setting = {
          toolbar: 'undo redo | fontselect fontsizeselect | forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | emoticons | bullist numlist outdent indent | searchreplace preview fullscreen | image media link |  code',
          plugins: `autoresize advlist autolink link image lists hr anchor wordcount visualchars code fullscreen insertdatetime media nonbreaking save table directionality paste textcolor spellchecker`,
          paste_data_images: true,
        };
        break;
      case "Comment":
        mce_setting = {
          toolbar: 'forecolor | bold italic | image media link | dialog-emoticones | fullscreen  code| wizardExample',
          plugins: `autoresize autolink link image hr code fullscreen media paste textcolor spellchecker`,
        };
        autocomplete_etiquetas = true;
        break;
      default:
        mce_setting = {
          toolbar: 'undo | fontselect styleselect  | forecolor backcolor | bold italic | alignleft aligncenter | image media link | dialog-emoticones | visualblocks fullscreen  code',
          plugins: `autoresize advlist autolink visualblocks link image lists hr anchor wordcount visualchars code fullscreen insertdatetime media nonbreaking save table directionality paste textcolor spellchecker`,
        }

        mce_mobile = {
          toolbar: 'undo | letras  | colors | image media link  | dialog-emoticones | fullscreen  code',
          plugins: `autoresize advlist autolink link image lists hr anchor wordcount visualchars code fullscreen insertdatetime media nonbreaking save table directionality paste textcolor spellchecker`,
          toolbar_groups: {
            letras: {
              icon: 'format',
              tooltip: 'Formatting',
              items: 'styleselect  | bold italic | alignleft aligncenter'
            },
            colors: {
              icon: 'fill',
              tooltip: 'Formatting',
              items: 'forecolor backcolor'
            },
            aling: {
              icon: 'color-levels',
              tooltip: 'Formatting',
              items: 'forecolor backcolor'
            }
          }
        }

        break;
    }


    var dialogConfig = {
      title: 'Lista de emoticones',
      size: 'medium',
      body: {
        type: 'panel',
        items: [
          {
            type: 'collection', // component type
            name: 'mce_emoticones', // identifier,
            class: 'fawf'
          }
        ]
      },
      buttons: [
      ],
      initialData: {
        'mce_emoticones': [
          ...(() => {
            let list = [];
            let push = (code, url) => {
              if (!url) return;
              list.push({
                text: `:${code}:`,
                value: `${code}`,
                icon: `<img src="${url}" title=":${code}:" editor emoticon="${code}">`
              });
            }
            Emoticones.GetList().map(e => push(e.code, e.url));
            return list;
          })(),
        ]
      },
      onAction: function (dialogApi, details) {
        if (details.name !== "mce_emoticones") return;
        _this.editor.execCommand('mceInsertContent', false, `:${details.value}:`);
        dialogApi.close();
      }
    };

    var page1Config = {
      title: 'Redial Demo',
      body: {
        type: 'panel',
        items: [{
          type: 'htmlpanel',
          html: '<p>Redial allows for the contents of a dialog to be replaced with new contents. This can be used to create multipage form dialogs.</p><br/><p>The Next button is initially disabled. When the <strong>checkbox</strong> is checked, the Next button should be enabled.</p>'
        }, {
          type: 'checkbox',
          name: 'anyterms',
          label: 'I agree to disagree'
        }, {
          type: 'htmlpanel',
          html: '<p>Pressing the Next button will call redial() to reload the dialog with the next page of the form.</p><br /><p>Press Next to continue.</p>'
        }]
      },
      initialData: {
        anyterms: false
      },
      buttons: [
        {
          type: 'custom',
          name: 'doesnothing',
          text: 'Previous',
          disabled: true
        },
        {
          type: 'custom',
          name: 'uniquename',
          text: 'Next',
          disabled: true
        }
      ],
      onChange: function (dialogApi, details) {
        var data = dialogApi.getData();
        /* Example of enabling and disabling a button, based on the checkbox state. */
        var toggle = data.anyterms ? dialogApi.enable : dialogApi.disable;
        toggle('uniquename');
      },
      onAction: function (dialogApi, details) {
        if (details.name === 'uniquename') {
          dialogApi.redial(page2Config);
        } else if (details.name === 'doesnothing') {
          /* this case should never be met as the button is never enabled. */
        }
      }
    };

    var page2Config = {
      title: 'Redial Demo - Page 2',
      body: {
        type: 'panel',
        items: [
          {
            type: 'selectbox',
            name: 'choosydata',
            label: 'Choose a pet',
            items: [
              { value: 'meow', text: 'Cat' },
              { value: 'woof', text: 'Dog' },
              { value: 'thunk', text: 'Rock' }
            ]
          },
          {
            type: 'htmlpanel',
            html: '<p>Click done and the dialog will log a message to the console, insert a sentence into the editor and close.</p>'
          }
        ]
      },
      buttons: [
        {
          type: 'custom',
          name: 'lastpage',
          text: 'Done',
          disabled: false
        }
      ],
      initialData: {
        choosydata: ''
      },
      onAction: function (dialogApi, details) {
        var data = dialogApi.getData();

        var result = 'You chose wisely: ' + data.choosydata;
        console.log(result);
        _this.editor.execCommand('mceInsertContent', false, '<p>' + result + '</p>');

        dialogApi.close();
      }
    };

    var mce_setup = (editor) => {
      editor.on('init', function (e) {
        setTimeout(() => {
          editor.selection.select(editor.getBody(), true); // ed is the editor instance
          editor.selection.collapse(false);
        }, 0);
      });
      _this.editor = editor;

      let emo_url = "";
      let rand = Math.random() * 1000;
      if (rand > 100) emo_url = Emoticones.GetUrl("grin");
      else if (rand > 50) emo_url = Emoticones.GetUrl("winky");
      else if (rand > 30) emo_url = Emoticones.GetUrl("roll");
      else if (rand > 10) emo_url = Emoticones.GetUrl("gsmyle");
      else if (rand > 3.5) emo_url = Emoticones.GetUrl("]");
      else if (rand > 1) emo_url = Emoticones.GetUrl("8S");
      else if (rand > 0) emo_url = Emoticones.GetUrl("cool");

      editor.ui.registry.addButton('dialog-emoticones', {
        text: `<img src="${emo_url}" mce_icon="" style="padding: 2px 0px 0px 0px;">`,
        onAction: function () {
          editor.windowManager.open(dialogConfig)
          window.tinymce.dom.DomQuery(".tox-dialog").addClass("mce_emoticones");
        }
      })
      // editor.ui.registry.addButton('custom-test', {
      //     text: 'Mi Elemento Personalizado',
      //     onAction: function() {
      //         // Inserta tu elemento personalizado aquí
      //         editor.insertContent('<my-custom-element>FFFFFFFFFFFF</my-custom-element>');
      //     }
      // });



      var eti_state = -1;
      if (this.mscreen.isNotMobile && autocomplete_etiquetas) editor.ui.registry.addAutocompleter('autocomplete_etiquetas', {
        ch: '@',
        minChars: 1,
        columns: 1,
        fetch: function (pattern) {
          return new Promise(function (resolve) {
            if (pattern.length === 1) resolve([]);
            eti_state++;
            let old = eti_state;
            setTimeout(async function () {
              if (old !== eti_state) {
                resolve([]); return;
              }
              try {
                const list = await window.GET2("/api/user/autocompleter", { q: pattern });
                var matchedChars = list.data.users;

                var results = matchedChars.map(function (char) {
                  const url = refEnv().media("dg0elqK0hD", url);
                  return {
                    value: `@${char.username}`,
                    text: char.username,
                    icon: `<img src="${url}" class="onlineusers photo_shadow0" emoticon editor style="margin: 2px 0px 0px 0px;border: 1px solid #0089ffaa;border-radius: 5px !important;border-color: #0089ffaa !important;" />`
                  }
                });
                if (old !== eti_state) return resolve([]);
                resolve(results);
              } catch (error) {
                resolve([]);
              }
            }, 750);

          });
        },
        onAction: function (autocompleteApi, rng, value) {
          editor.selection.setRng(rng);
          editor.insertContent(value);
          autocompleteApi.hide();
        }
      });
      var emo_state = -1;
      if (this.mscreen.isNotMobile) editor.ui.registry.addAutocompleter('autocomplete_emoticones', {
        ch: ':',
        minChars: 1,
        columns: 1,
        fetch: function (pattern) {
          return new Promise(function (resolve) {
            const regex = RegExp(`^${pattern}`, "i");
            var matchedChars = Emoticones.GetList().sort((a, b) => a.code.toLowerCase().localeCompare(b.code.toLowerCase())).filter(function (char) {
              return char.url && regex.exec(char.code);
            });

            var results = matchedChars.map(function (char) {
              return {
                value: `:${char.code}:`,
                text: `:${char.code}:`,
                icon: `<img src="${char.url}" editor emoticon="${char.code}" style="padding: 2px 0px 0px 0px;" />`
              }
            });
            resolve(results);
          });
        },
        onAction: function (autocompleteApi, rng, value) {
          editor.selection.setRng(rng);
          editor.insertContent(value);
          autocompleteApi.hide();
        }
      });
    };

    return (
      <>
        <Screen get={(screen) => {
          this.mscreen = screen;
        }} />
        <Editor
          initialValue={defaultValue ? `${defaultValue} ` : ""}
          init={{
            skin: 'oxide-peluchan',
            menubar: '',//file edit insert view format table tools help
            formats: {
              bold: { inline: 'strong', 'classes': 'bold' },
            },
            browser_spellcheck: true,
            gecko_spellcheck: false,
            relative_urls: false,
            remove_script_host: true,
            document_base_url: "/",
            custom_elements: 'me',
            body_class: 'body',
            body_id: '_editor',
            // nonbreaking_wrap: true,
            mode: "textareas",
            content_css: '/css/post.css',
            max_width: 500,

            toolbar_mode: 'floating',
            branding: false,
            toolbar_persist: true,
            toolbar_sticky: true,
            contextmenu: "spellchecker",
            language: 'es',
            force_p_newlines: false,
            forced_root_block: false,
            setup: mce_setup,

            autoresize_focus_disabled: true,

            // paste_enable_default_filters: false,
            // paste_filter_drop: false,
            // smart_paste: true,
            // image_file_types: 'jpeg,jpg,jpe,jfi,jfif,png,gif,bmp,webp',

            paste_preprocess: async function (plugin, args) {
              //https://stackoverflow.com/questions/49987976/fill-for-external-svg-file-in-react.png
              if (typeof args.content !== "string") return;
              if(args.content.indexOf("<img src=\"")==0){
                let content = args.content.slice(10,args.content.length-9)
                if(!isValidUrl(content)) {
                  PeluchanUpload({file: base64ToBlob(content)}).then(r=>{
                    _this.editor.insertContent(`<img src="${refEnv().media("2x7DsuOkLS", r.data.url)}">`);
                  })
                  args.content = ""
                }
              }
              if (!args.content.replace) return;
              let clear = args.content.replace(/<[^>]*>?/gm, '');
              if (/^http(s)?:\/\/([\w\-]+\.)+[\w\-]+(\/[\w\-\.\/\?\%\&\=]+)?\.(?:jpeg|jpg|png|gif|bmp|webp)$/i.exec(clear)) args.content = clear;
            },

            image_custom: true,
            image_class_list: [
              { text: 'Normal', value: '' },
              // { text: 'Párrafo', value: 'pr' },
              { text: 'Libre', value: 'nw' },
            ],
            image_custom_upload: (obj) => {
              const { api, changeSrc } = obj;
              _this.updateSrc = (url) => {
                let awf = api.getData();
                awf.src.value = url;
                api.setData(awf);
                changeSrc();
              };
              PeluchanUpload({loading: ()=> {
                const el = document.querySelector('button[title="Subir Imagen"]')
                if(el) el.textContent = "Loading..."
              } }).then(r => {
                const el = document.querySelector('button[title="Subir Imagen"]')
                // if(el && el.textContent == "Loading...") return
                _this.updateSrc(r.data.url);
              }).finally(()=> {
                const el = document.querySelector('button[title="Subir Imagen"]')
                if(el) el.textContent = "Subir Imagen"
              });
            },

            ...mce_setting ? mce_setting : {},

            mobile: {
              skin: 'oxide-peluchan',
              toolbar_persist: false,
              toolbar_sticky: false,
              branding: false,
              menubar: '',
              max_width: 300,
              toolbar_mode: 'floating',
              ...mce_mobile ? mce_mobile : {},
            }
          }}
          ref={(e) => {
            if (!e) return;
            //this.editor = window.tinymce.editors[e.id];
          }}
          onEditorChange={this.handleEditorChange}
        />
      </>
    );
  }
}

export default _tinymce_;