import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../../utils/loading";
import Notificacion from "../../../../utils/notificacion";
import { refPeluchan } from "../../../../../contexts/PeluchanContext";
import { MessageWitchIcon } from "../../../../styleguide/components/legacy/message";
import { ConcatY } from "../../../../styleguide/components/Concat";
import { Button } from "../../../../styleguide/components/Button";
import { useForceUpdate } from "../../../../utils/useForceUpdate";
import { PlaceholderNotis } from "../../../../../pages/components/Scrolling/utils/PlaceholderNotis";
import { PlaceholderPulse } from "../../../../utils/PlaceholderPulse";

type NotificacionesDesktopProps = {
    count:number
    view?:boolean
    tooltip?:boolean
};

export const NotificacionesDesktop:React.FC<NotificacionesDesktopProps> = ({tooltip,count, view = true}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error>();
    const [{notis=[],last}, setData] = useState<any>({});
    const {forceUpdate, cound} = useForceUpdate()

    useEffect(()=>{
        window.GET("/api/user/notificaciones?count=8").then(({data, error})=>{
            console.log(data,error)
            if(error || !data) {
                setError(error || new Error("Algo fallo!"))
                return setData({})
            }
            setData(data)
            refPeluchan().login.notis.toCero();
        }).catch((e)=>{
            setError(e)
        }).finally(()=>{
            setLoading(false)
        })
    },[count,view, cound]);

    const onRetry = ()=>{
        forceUpdate()
        setError(undefined)
        setLoading(true)
    }
    
    if(error) 
        return <div className={`noti_container ${tooltip?"header-tooltip":""} text-center`}>
            <MessageWitchIcon 
                materialIcon="notifications_off" 
                message={error.message}
                classNameIcon="block mb-1 text-[30px] !mr-0"
                className="p-4 text-lg font-sans text-red-300"
            />
            <Button type="secondary" children="Reintentar" className="mb-4" onClick={onRetry}/>
        </div>

    if(error) return <h1>Error... {error.toString()}</h1>;

    if(loading || !view) return (
        <div className={`noti_container ${tooltip?"header-tooltip":""}`}>
            <PlaceholderNotis count={6}/>
            <ConcatY.Line margin="base2"/>
            <PlaceholderPulse className={"w-[75px] h-[15px] mb-2 mx-auto"}/>
        </div> 
    );


    if(notis && notis.length == 0) 
        return <MessageWitchIcon 
            materialIcon="notifications_off" 
            message="Sin notificaciones"
            classNameIcon="block mb-1 text-[30px] !mr-0"
            className="p-4 !text-lg font-sans text-blue-300"
        />

    return (
        <div className={`noti_container pt-1 ${tooltip?"header-tooltip":""}`}>
            <ConcatY.withLine line={{margin: "base2"}}>
                {[
                    ...notis ? 
                    notis.map((e:any)=>{
                        return <Notificacion key={e._id} data={e} last={last}></Notificacion>;
                    })
                :[],
                <div className={`center mt-2 ${tooltip?"mb-1":""}`}>
                    <Link to="/notifications">Ver todos</Link>
                </div>
                ]}
            </ConcatY.withLine>
        </div>
    );
}