import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({color,...props}: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
    <g
        {...props}
        transform={`translate(1.5 1218.5), scale(1)`}
        >
        <path id="Trazado_4" data-name="Trazado 4" d="M8.527-1215.793c-.055.115-.269.549-.476.966l-1.113,2.254c-.406.823-.743,1.5-.746,1.5s-1.224.189-2.709.407c-3.005.444-2.781.411-2.781.422s3.5,3.428,3.821,3.733l.117.112-.019.106c-.01.058-.072.423-.138.813-.13.767-.212,1.255-.4,2.347-.066.39-.175,1.031-.241,1.426a4.621,4.621,0,0,1-.145.743c-.024.026-.024.027,0,.019s.027,0,.027.01,0,.02.006.02.493-.256,1.089-.57c2.183-1.146,2.845-1.493,3.288-1.721l.49-.253.037-.021,1.416.744,2.446,1.286c.567.3,1.032.539,1.034.534s-.022-.159-.053-.343c-.1-.559-.129-.752-.168-.976-.02-.117-.056-.332-.081-.474s-.061-.359-.082-.478-.06-.346-.086-.5-.068-.4-.093-.546l-.2-1.2c-.035-.2-.086-.5-.112-.652l-.047-.279.082-.082c.046-.045.936-.913,1.981-1.928,1.637-1.594,1.894-1.849,1.879-1.864s-.6-.106-1.9-.3l-2.711-.4c-.523-.078-.829-.118-.839-.112s-.055-.072-.151-.271c-.076-.155-.323-.657-.55-1.114s-.45-.91-.5-1.006-.257-.523-.468-.95l-.587-1.189c-.112-.226-.206-.412-.211-.412A1.89,1.89,0,0,0,8.527-1215.793Z" transform="translate(0)" fill={color}/>
    </g>
    </>
)

export default ({borde1, backgroundShadow1}: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
        <g transform="translate(1.4 1)" >
            <Icon color={backgroundShadow1}/>
        </g>
        <Icon color={borde1}/>
    </>
);
