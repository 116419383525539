import React from "react";
import Circulo from "../resources/Circulo";
import { MedallaAllIcons } from "../icons";
import cn from "classnames";
const Colr = require("colr");

const RAREZA_NAME = ["#FFF","#AEE5FF","#BEFFB5","#8ACCFF","#FFB4B4","#FFC387"]
export const MedallaIcon:React.FC< React.SVGProps<SVGSVGElement> & {
    color: string,
    rareza?: number,
    borde1?: string,
    scale?: number,
    icon: string,
    active: boolean
}> = ({color, borde1, rareza=0, scale=1.36, icon, className,active = true, ...args})=>{

    const colors = {
        borde1: borde1 || RAREZA_NAME[rareza],
        // primary1: primary1,
        background1: color,
        backgroundShadow1: Colr.fromHex(color).darken(10).toHex()
    }

    return (
        <svg 
            {...args}
            xmlns="http://www.w3.org/2000/svg"
            width={29 * scale}
            height={29 * scale}
            className={cn({"o-30":!active},className)}
        >
            <g transform={`scale(${scale}), translate(-4 -4)`} >
                <Circulo {...colors} >
                    <g transform="translate(8.4 7.5)" >
                        <MedallaAllIcons icon={icon} {...colors}/> 
                    </g>
                </Circulo>
            </g>
        </svg>
    );
}