import cn from "classnames"
import React from "react"
import { Link } from "react-router-dom"
import SR from '../../../utils/resources';

const sizesStyles = {
    size1: "text-xs rounded-[.250rem] px-2 py-1 ",
    size2: "text-sm rounded-[.250rem] px-2 py-1 ",
    size3: "text-sm rounded-[.375rem] px-[.625rem] py-[.375rem]",
    size4: "text-sm rounded-[.375rem] px-3 py-2",
    size5: "text-sm rounded-[.375rem] px-[.875rem] py-[.625rem]",
}

const styles = {
    _base: "font-[Roboto] font-semibold outline-none transition-colors",
    primary:    "border-none text-white hover:!text-white "+
                "bg-blue-500 hover:bg-blue-400 active:bg-blue-300 dark:bg-[#136dcd] dark:hover:bg-[#428ad7] dark:active:hover:bg-[#3f93ed] "+
                "dark:disabled:bg-[#8888881a] dark:disabled:hover:bg-[#8888881a] dark:disabled:cursor-auto dark:disabled:text-gray-400",
    secondary:  "bg-[#FFF] hover:bg-[#00000011] active:bg-[#00000022] dark:bg-[#ffffff1a] dark:hover:bg-[#ffffff33] dark:active:bg-[#ffffff44] "+
                "border-[#57a8ff30] border-[1px] border-solid "+
                "text-[#51595e] dark:text-[#cfe6ff] "+
                "dark:disabled:bg-[#8888881a] dark:disabled:hover:bg-[#8888881a] dark:disabled:cursor-auto dark:disabled:border-[#282d3c] dark:disabled:text-gray-400",
    dark:   "bg-[#FFF] hover:bg-blue-50 active:bg-blue-100 dark:bg-[#0f171f] dark:hover:bg-[#0f171f7b] dark:active:bg-[#16222e7b] "+
            "border-[#57a8ff30] border-[1px] border-solid text-blue-400 dark:text-[rgba(165,208,255,0.89)]",
    smallBlue:   " bg-[#FFF] hover:bg-blue-50 active:bg-blue-100 dark:bg-[#375a7f1a] dark:hover:text-[#FFF] "+
                    "border-none text-blue-300 dark:text-[#cfe6ff]",
    flatBlue:   " bg-[#FFF] hover:bg-blue-50 active:bg-blue-100 dark:bg-[#182e3a] dark:hover:bg-[#1e3e50] dark:active:bg-[#1e2e3f] "+
    "border-[#57a8ff30] border-[1px] border-solid text-white dark:text-[#cfe6ff] transition-none",
    primaryRed:    "border-none text-white hover:text-white "+
    "bg-[#b01e69] hover:bg-[#c9267b] active:bg-[#bf4a87] dark:bg-[#6f1342] dark:hover:bg-[#9b1a5c] dark:active:hover:bg-[#b01e69] "+
    "dark:disabled:bg-[#a7246844] dark:disabled:hover:bg-[#a7246844] dark:disabled:cursor-auto dark:disabled:text-gray-400",
}

export type ButtonProps = Omit<JSX.IntrinsicElements["button"], "type"> & {
    size?: keyof typeof sizesStyles,
    type?: keyof typeof styles,
    _ref?: React.LegacyRef<HTMLButtonElement>
}
const Element:React.FC<ButtonProps> = ({className,_ref, children,size="size2", type="primary", ...args})=>{
    return <button ref={_ref} {...args} className={cn(styles._base, styles[type], sizesStyles[size], className)}>
        {children}
    </button>
}

export type WithLinkProps = React.ComponentProps<typeof Link> & {
    size?: keyof typeof sizesStyles,
    type?: keyof typeof styles
}
const withLink:React.FC<WithLinkProps> = ({className, children,size="size2", type="primary", ...args})=>{
    return <Link {...args} className={cn(styles._base, styles[type], sizesStyles[size], className)}>
        {children}
    </Link>
}
const withLoading:React.FC<ButtonProps & {loading}> = ({loading, className, children, ...args})=>{
    return <Element {...args} className={cn(className, "relative")}>
        <span className={cn("absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",{"hidden": !loading})}>{SR._icons._loading_dual_ring}</span>
        <span className={cn({"opacity-0":loading})}>{children}</span>
    </Element>
}

const sizesIconStyles = {
    size1: {
        root: "text-xs rounded-[.250rem] w-[24px] h-[24px] ",
        icon: "text-[12px]"
        
    },
    size2: {
        root: "text-sm rounded-[.250rem] w-[28px] h-[28px] ",
        icon: "text-[14px]"
    },
    size3: {
        root: " rounded-[.375rem] w-[32px] h-[32px]",
        icon: "text-[16px]"
    },
    size4: {
        root: " rounded-[.375rem] w-[36px] h-[36px]",
        icon: "text-[16px]"
    },
    size5: {
        root: "text-sm rounded-[.375rem] w-[40px] h-[40px] ",
        icon: "text-[18px]"
    },
}
const Icon:React.FC<ButtonProps & {icon:string, sharp?:boolean}> = ({icon, className,size="size2", sharp, children, ...args})=>{
    return <Element {...args} className={cn(className, "relative ", sizesIconStyles[size].root)}>
        <span className={cn(sharp?"material-icons":"material-icons-sharp","absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2", sizesIconStyles[size].icon)}>{icon}</span>
        {children}
    </Element>
}


const sizesIcon2Styles = {
    size1: {
        root: "text-xs rounded-[.250rem] h-[24px] pl-[calc(12px+0.75rem)] px-2 py-1",
        icon: "text-[12px] left-3"
        
    },
    size2: {
        root: "text-sm rounded-[.250rem] h-[28px] pl-[calc(14px+0.75rem)] px-2 py-1",
        icon: "text-[14px] left-[.85rem]"
    },
    size3: {
        root: "text-sm rounded-[.375rem] h-[32px] pl-[calc(16px+0.75rem)] px-[.625rem] py-[.375rem]",
        icon: "text-[16px] left-[.9rem] " 
    },
    size4: {
        root: "text-sm rounded-[.375rem] h-[36px] pl-[calc(16px+1rem)] px-3 py-2",
        icon: "text-[16px] left-4"
    },
    size5: {
        root: "text-sm rounded-[.375rem] h-[40px] pl-[calc(18px+1.25rem)] px-[.875rem] py-[.625rem]", 
        icon: "text-[18px] left-5"
    },
}
const IconAndText:React.FC<ButtonProps & {icon:string}> = ({icon, className,size="size2", children, ...args})=>{
    return <Element {...args} className={cn(className, "relative ", sizesIcon2Styles[size].root)}>
        <span className={cn("material-icons absolute top-1/2 -translate-x-1/2 -translate-y-1/2", sizesIcon2Styles[size].icon)}>{icon}</span>
        <span className="">
            {children}
        </span>
    </Element>
}

export const Button = Object.assign(Element, {
    Icon,
    IconAndText,
    withLink,
    withLoading
})