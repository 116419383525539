import { useState, useRef } from "react";
import UploadPhoto from "../../../../../../components/utils/UploadPhoto";
import { EditorShoutItemType } from "../editor-shout";
import React from "react";
import { MaterialIcon } from "../../../../../../components/styleguide/components/Icon";
import { Button } from "../../../../../../components/styleguide/components/Button";
import { PeluchanUpload } from "../../../../../../utils/services/PeluchanUpload";
import { ClipboardImage, ReadClipboardImage } from "../../../../../../components/utils/ClipboardImage";


type DynImageContent = {
    images: {
        url: string,
        width?: number,
        height?: number
    }[]
};

export const DynImage:React.FC<{
    onDelete: ()=> void,
    item: EditorShoutItemType<DynImageContent>,
    setContent: (v: DynImageContent)=> void
    defauld_setting?: {
        native: {
            type: string,
            content: string
        }
    }
}> = ({onDelete, item,setContent, defauld_setting})=>{
        const {content={ images: []}} = item;
        const [mode, setMode] = useState<string | undefined>();
        const [value, setValue] = useState<string | undefined>();
        const refUploadPhoto = useRef<UploadPhoto>();
        const [loading, setLoading] = useState(false)

        const TryUploadPhoto = async ()=> {
            if(!refUploadPhoto.current) return;
            await refUploadPhoto.current?.Upload({ uploading: ()=> setLoading(true) })
            .then((result)=> setContent({ images: [...content.images, { url: result.data.url }]}))
            .finally(()=> setLoading(false))   
        }

        const TryDrod = async (files:any[])=>{
            if(!refUploadPhoto.current) return;
            setLoading(true)
            console.log(files)
            await PeluchanUpload({file:files[0]})
            .then((result)=> setContent({ images: [...content.images, { url: result.data.url }]}))
            .finally(()=> setLoading(false))   
        }

        let CONTENT = null;
        // window.log(JSON.stringify(content.images));
        if(content.images.length === 0){
            if(!mode){
                CONTENT = <>
                        <UploadPhoto ref={(e)=>{if(e) refUploadPhoto.current = e}}/>
                        <ClipboardImage onDrod={TryDrod} queryHandlePaste=".create-shout-content">
                            <div className="toolbal SelectOption flex space-x-1.5">
                                <Button.withLoading 
                                    loading={loading} 
                                    size="size5" 
                                    type="smallBlue"
                                    title="Subir imagen"
                                    className="font-normal"
                                    onClick={()=> TryUploadPhoto()}
                                    children={<>
                                        <div className="flex justify-center items-center space-x-3">
                                            <MaterialIcon children="cloud_upload"/>
                                            <span className="leading-none mt-0.5">{`Subir`}</span>
                                        </div>
                                    </>}
                                />
                                
                                <Button 
                                    size="size5" 
                                    type="smallBlue"
                                    title="Usar URL"
                                    className="font-normal"
                                    onClick={()=> setMode("url")}
                                    children={<>
                                        <div className="flex justify-center items-center space-x-3">
                                            <MaterialIcon children="link"/>
                                            <span className="leading-none mt-0.5">{`URL`}</span>
                                        </div>
                                    </>}
                                />

                                {/* <Button 
                                    size="size5" 
                                    type="smallBlue"
                                    title="Pegar imagen"
                                    className="font-normal"
                                    onClick={TryByPaste}
                                    children={<>
                                        <div className="flex justify-center items-center space-x-3">
                                            <MaterialIcon children="content_paste" className="text-[16px]"/>
                                            <span className="leading-none mt-0.5">{`Pegar`}</span>
                                        </div>
                                    </>}
                                /> */}


                                <div className="flex-grow-1"/>

                                <Button.Icon
                                    icon="close"
                                    size="size5" 
                                    type="smallBlue"
                                    title="Quitar"
                                    className="font-normal"
                                    onClick={onDelete}
                                />
                            </div>
                        </ClipboardImage>

                    </>;
            }else{
                if(mode === "url"){
                    CONTENT = <div className="font23">
                        <label htmlFor="name">Link de imagen</label>
                        <input  type="text" className="form-control" placeholder="https://i.imgur.com/bQhMSIG.gif" autoFocus/>
                    </div>;
                    CONTENT = (
                        <>
                            <div className="input-group mb-3">
                                <input value={value} onChange={e=> setValue(e.target?.value)}  type="text" className="form-control" placeholder="Link de imagen" aria-label="Link de imagen" aria-describedby="button-addon2" autoFocus/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=>{
                                        setContent({ images: [...content.images, { url: value as string }]})
                                    }}>Insertar</button>
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=>{
                                        setMode(undefined);
                                    }}>Volver</button>
                                </div>
                            </div>
                        </>
                    );
                }
            }
        }else{
            CONTENT = (
                <div className="toolbal SelectOption">
                    {
                        content.images.map((obj,index)=>{
                            return (
                                <div key={index} className="button_close item_image">
                                    <img className="preview_image" src={obj.url} onLoad={(e)=>{
                                        if(!e.target) return;
                                        obj.width = (e.target as HTMLImageElement).width;
                                        obj.height = (e.target as HTMLImageElement).height;
                                    }}/>
                                    <Button.Icon 
                                        className="absolute top-0 right-0 !w-[24px] !h-[24px] text-[16px]" 
                                        onClick={onDelete}
                                        type="flatBlue"
                                        icon="close"
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            );
        }

        return <div className="dyn-image">
            {CONTENT}
        </div>;
}