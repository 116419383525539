import { useRef, useMemo, useEffect } from "react";
import { useclientWidth } from "../../../../hooks/useMemoHeight";
import { Imagen } from "../../../Imagen/Imagen";
import React from "react";
import { refEnv } from "../../../../contexts/EnvironmentContext";
import AwesomeSlider from "react-awesome-slider";
import { PItemProps } from "./PMain";

type Image =  {
    url: string,
    width: number,
    height: number,
    format: string
}
type PictureProps = {
    image: Image,
    native: boolean,
    MyLink: React.FC<any>
    imagenKey?: string
}
//http://localhost:2052/shout/65fb11388dea374417d052d6
function Picture({image, native, MyLink, imagenKey}:PictureProps) {
    const clientWidth = useclientWidth();
    let mediaParams = { 
        size: {width: image?.width, height: image?.height},
        format: image?.format
    };

    const sizeImage = useMemo(()=> {
        if(!clientWidth) {
            console.log(new Error("⚠️ clientWidth es Null"))
            return
        }

        const sizeImage = image  
        const horizontal = sizeImage.width/sizeImage.height>1
        const widthDiff = clientWidth<sizeImage.width?clientWidth/sizeImage.width:1
        const calScale = clientWidth/sizeImage.width
        let calWidth = horizontal? clientWidth : sizeImage.width
        let calHeight = horizontal? sizeImage.height * calScale: sizeImage.height
        calWidth =horizontal?calWidth: calWidth * widthDiff
        calHeight =horizontal?calHeight: calHeight * widthDiff
        
        return {calHeight, calWidth }
    } ,[clientWidth, image.width,image.height])

    const style = {
        ...(image.width/image.height>1 && !sizeImage)?{ width:"100%" }:{},
        ...sizeImage?{ width: sizeImage.calWidth, height: sizeImage.calHeight }:{}
    }

    let img = <Imagen key={imagenKey} pId={native?"NxiQFXQfOi":"DW4gEuUtq4"} src={image.url} style={style} pParams={mediaParams}/>;

    if(native) img = (
        <a data-fancybox="gallery" href={image.url}>
            {img}
        </a>
    );

    if(MyLink) img = <MyLink>
        {img}
    </MyLink>;
    return img;
}

export type PImageProps = PItemProps<{
    images:Image[]
}, {
    to?: React.FC,
    native?: boolean
    imagenKey?: string
}>
export default ({content, to, native=false, imagenKey }:PImageProps)=>{

    if(!content.images || content.images.length === 0) return null;
    if(content.images.length === 1) return <Picture image={content.images[0]} imagenKey={imagenKey} native={native} MyLink={to}/>
    
    const list = [];
    content.images.map((e, index)=>{
        list.push(<img 
            key={index} 
            data-href={refEnv().media(native?"NxiQFXQfOi":"DW4gEuUtq4", e.url)} 
            data-src={e.url} 
            data-width={e.width} 
            data-height={e.height}
        />);
    });
    return (
        <AwesomeSlider onTransitionStart={(e)=>{
            if(!e.nextMedia || !e.nextMedia["data-height"] || !e.nextMedia["data-width"]) return;
            const ra = e.nextMedia["data-height"]/e.nextMedia["data-width"];
            e.element.style.height = e.element.clientWidth * ra+"px";
        }} onFirstMount={(e)=>{
            if(!e.currentMedia || !e.currentMedia["data-height"] || !e.currentMedia["data-width"]) return;
            const ra = e.currentMedia["data-height"]/e.currentMedia["data-width"];
            e.element.style.height = e.element.clientWidth * ra+"px";
        }} >
          {list}
        </AwesomeSlider>
    );
}