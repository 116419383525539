import React from "react";
import { PItemProps, PMain } from "./PMain";


export type PListProps = PItemProps<any[], {}>;

export default ({content, ...setting}:PListProps)=>{
    const list = [];
    for (let index = 0; index < content.length; index++) {
        list.push(<div key={index}>
            {/* {this.analize_body_raw(content[index],setting)} */}
            <PMain key={index} body={content[index]} {...setting}/>
        </div>);
    }
    return <>
        {list}
    </>;
}
