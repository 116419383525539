/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom'
import LazyLoad, { LazyLoadProps } from 'react-lazyload';

import { ShoutHeader } from './components/header';
import { ShoutFooter } from './components/footer';
import { ShoutContent } from './components/content';
import { Panel } from '../../../components/styleguide/components/Panel';
import cn from 'classnames';
import { LazyView } from '../../../components/utils/LazyView';
import { IShout } from '../../../hooks/apis/usePost';
import { Memo } from '../../../components/utils/Memo';
import { refPeluchan } from '../../../contexts/PeluchanContext';
import { useForceUpdate } from '../../../components/utils/useForceUpdate';
import { refHistory } from '../../../hooks/useHistory';


const ShoutContext = React.createContext<ReturnType<typeof _SHOUT>>({} as any);

export const useShout = ()=> useContext(ShoutContext);
const ShoutProvider = ShoutContext.Provider;

const useShoutLinks = (shout:any, onClick:()=>void)=>{
    const shoutPatch = `/shout/${shout._id}`;
    return {
        ShoutLink: useCallback((props:Partial<React.ComponentProps<typeof Link>>) => {
            return <Link {...props}
                state={{ id: shout._id }}
                to={shoutPatch} 
                onClick={onClick} 
            ></Link>
        },[shout._id, onClick]),

        ShoutDivLink: useCallback((props) => {
            return <div onClick={()=>{
                refHistory().navegate(shoutPatch, { state:{ id: shout._id } });
            }} {...props} ></div>
        },[shout._id, onClick])
    };
}
const useShoutLazy = (scrollContainer?: string | HTMLElement)=>{
    return useMemo(()=> ({
        ShoutLazyLoad: (props: LazyLoadProps)=>{
            // @ts-ignore
            return <LazyLoad {...props} {...scrollContainer?{
                scrollContainer,
                overflow: true
            }:{}}/>
        }
    }),[scrollContainer])
}

const _SHOUT = (props: {
    onClick?:()=>void,
    shout:IShout,
    _ref?:(i:HTMLDivElement | null)=>void, 
    className?:string,
    hidden_footer?: boolean,
    backgraund_link?: boolean,
    noLazy?: boolean,
    scrollContainer?: Parameters<typeof useShoutLazy>[0],
    no_setting?: boolean,
    full?: boolean,
    style?: "none" | "panel",
    deleted?:(s:IShout)=>void
})=>{
    const { onClick = ()=>{}, shout } = props;
    const {forceUpdate, cound} = useForceUpdate()
    const [isShow, setShow] = useState(true);

    //Estates
    const rootRef = useRef<HTMLDivElement | null>();

    //Animacion de eliminar
    const deleted = useCallback(()=>{
        if(!rootRef.current) return;
        rootRef.current.classList.add("opa0");
        setTimeout(() => {
            if(props.deleted) props.deleted(props.shout)
            else setShow(false)
        }, 250);
    },[shout._id,rootRef.current]);

    //Shared logic
    const hasText = shout.text && shout.text.length > 0;
    const isSharedShout = !hasText && shout?.body?.type === "link" && shout?.body?.content?.native?.content && shout?.body?.content?.native?.type === "shout";
    const SharedShout = isSharedShout? shout.body.content.native.content: undefined;

    //Componentes
    const ShoutLinks = useShoutLinks(SharedShout || shout, onClick)
    const ShoutLazyLoad = useShoutLazy(props.scrollContainer);

    if(!shout.author) shout.author = window.user404;
    if(isSharedShout && !SharedShout.author) SharedShout.author = window.user404;

    // ⌛ Solo un render en shoutPage y miPage
    // beep(shout._id,"console")

    return {
        root: rootRef,
        count:cound,
        isShow,
        forceUpdate,
        originShout: SharedShout? shout: undefined,
        shout: (SharedShout || shout) as IShout,
        style: props.style || "panel",
        props:{
            ...props
        },
        components: { 
            ...ShoutLinks,
            ...ShoutLazyLoad
        },
        actions:{
            deleted
        }
    };
}

export type ShoutContextType = ReturnType<typeof _SHOUT>


export const ShoutV2:React.FC<Parameters<typeof _SHOUT>[0]> = (props)=>{

    const _context = _SHOUT(props);

    return <ShoutProvider value={_context}>
        <Memo debs={[props.shout._id, _context.isShow]}>
            {()=>{
                if(!_context.isShow) return null;
                const {className, ...root_props}:JSX.IntrinsicElements["div"] & React.ComponentProps<typeof Panel> = {
                    ref: (d)=>{ _context.root.current = d; if(props._ref) props._ref(d); },
                    _ref: (d)=>{ _context.root.current = d; if(props._ref) props._ref(d); },
                    className: "__shout__ "+props.className
                }
        
                const Content = <>
                    <ShoutHeader/>
                    <ShoutContent className={props.hidden_footer?'':'mb-2'}/>
                    {!props.hidden_footer && <ShoutFooter/>}
                </>
        
                if(_context.style=="none")  return <div {...root_props} className={cn(className)}>
                    {Content}
                </div>
        
                if(_context.style=="panel") return  <Panel {...root_props} padding='none' className={cn(className, "p-2")} >
                    {Content}
                </Panel>
            }}
        </Memo>
    </ShoutProvider>
}


function ShoutMemo(props:Parameters<typeof ShoutV2>[0]) {
    if(props.noLazy) return <ShoutV2 {...props}/>

    return useMemo(()=> <LazyView 
        id={props.shout._id} 
        observer={{rootMargin: "500px 0px 2000px 0px"}}
        // className='bg-gray-200 dark:bg-gray-800 rounded-lg'
        // placeholder={<PlaceholderShout/>}
    >
        <Memo debs={[props.shout._id]}>
            {()=> <ShoutV2 {...props}/>}
        </Memo>
    </LazyView>, [props?.shout?._id]);
}

export const ShoutOfLink:React.FC<Parameters<typeof _SHOUT>[0]> = ({...args})=> (
    <ShoutMemo {...args} style="none" className="m-2 " hidden_footer={true} backgraund_link={true}></ShoutMemo>
)

export default ShoutMemo;