import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Panel } from "../../../components/styleguide/components/Panel";
import { DocumentTitle } from "../../../components/utils/DocumentTitle";
import { useDevice } from "../../../components/utils/mscreen";
import { SoftPost } from "../../../hooks/apis/usePost";
import { SoftShout } from "../../../hooks/apis/useShout";
import { MemoHeightProvider, WidthRead } from "../../../hooks/useMemoHeight";
import { ScrollPost } from "../../components/Scrolling/ScrollPost";
import { ScrollShout } from "../../components/Scrolling/ScrollShout";
import { useProfile } from "../context";
import { ScrollComment } from "../../components/Scrolling/ScrollComment";
import { UserType } from "../../../hooks/apis/useUser";
import { PanelOfProfile } from "../profile";
import { useQueryParam } from "use-query-params";
import { ConcatY } from "../../../components/styleguide/components/Concat";
import { IFav } from "../../../hooks/apis/useFavs";
import ItemComment from "../../components/Comment/item-comment";
import { ItemPost } from "../../components/Post/components/ItemPost";
import { ScrollFavs } from "../../components/Scrolling/ScrollFavs";
import ItemShout from "../../components/Shout/item-shout";
import Message from "../../../components/styleguide/components/legacy/message";
import { TitleLegacy } from "../../../components/title";
import { NavList } from "../../../components/styleguide/components/Nav";
import cn from "classnames";
import { useRefer } from "../../../hooks/useRefer";

const DeleteFavBroke:React.FC<{message: string, onDelete: ()=> void, fav: IFav}> = ({message, onDelete, fav})=>{
    return <div className={`favdelete favline`}>
        <div>
            {`${message}`}
        </div>
        <div>
            <Link to="#" onClick={async (e)=>{
                e.preventDefault();
                if(window.RPOST("/api/fav/del",{fav_id: fav._id, type: fav.type})){
                    onDelete()
                }
            }}>Eliminar favorito</Link>
        </div>
    </div>
}

export const useScrollingDelete = ()=>{
    const [dels, setDels] = useState<string[]>([])
    const refer = useRefer({dels, setDels})

    return {
        del: (id:string)=> refer.current.setDels(i=> [...i, id]),
        hasDel: (id:string)=> refer.current.dels.includes(id)
    }
}

const Render:React.FC<{favs:IFav[]}> = ({favs})=>{
    const {isMobile} = useDevice()
    if(favs && favs.length === 0) return <Message className="mt-4 pb-3" message="Nada por aqui"></Message>;

    const fistType = favs[0].type
    const items = favs.filter(f=> fistType == f.type)

    const {del, hasDel} = useScrollingDelete()

    if(fistType=="Post"){
        return <ConcatY className={cn({"_mini": isMobile})}>
            {items.map((fav )=>{
                if(!fav.to)  return <DeleteFavBroke key={"fav-"+fav._id} fav={fav} message='Post no encontrado.' onDelete={()=>{
                    favs.splice(favs.findIndex((e)=> e._id === fav._id),1);
                    (window as any).forceUpdate.gsedhdrjdrhdrhrdjrdj();
                }}/>;
                return <ItemPost key={"fav-"+fav._id+(isMobile?"-m":"")} post={fav.to} IsFav={true} mini={isMobile} metas={{large: isMobile}}/>;
            })}
        </ConcatY> 
    }

    if(fistType=="Shout"){
        return  <ConcatY.withLine>
                {items.filter(i=> !hasDel(i._id)).map((fav )=>{
                    if(!fav.to)  return <DeleteFavBroke key={"fav-"+fav._id} fav={fav} message='Shout no encontrado.' onDelete={()=>{
                        favs.splice(favs.findIndex((e)=> e._id === fav._id),1);
                        (window as any).forceUpdate.gsedhdrjdrhdrhrdjrdj();
                        del(fav._id)
                    }}/>;
                    return <ItemShout key={"fav-"+fav._id} shout={fav.to} style='none' scrollContainer={"#FavScrollRoot"} deleted={()=>del(fav._id)}/>;
                })}
            </ConcatY.withLine>
    }

    if(fistType=="Comment") 
        return (<>
            {favs.map((fav, idx)=>{
                if(!fav.to) return <React.Fragment key={"fav-"+fav._id}>
                    <DeleteFavBroke fav={fav} message='Comentario no encontrado.' onDelete={()=>{
                            favs.splice(favs.findIndex((e)=> e._id === fav._id),1);
                            (window as any).forceUpdate.gsedhdrjdrhdrhrdjrdj();
                    }}/>
                    {idx+1!=favs.length && <ConcatY.Line className='w-full h-[1px] mt-2 mb-3' margin='none'/>}
                </React.Fragment>;
                return <ItemComment replys_show={false} insertado={true} addLine={idx+1!=favs.length} key={"fav-"+fav._id} comment={fav.to} IsFav={true}/>;
            })}
        </>);
}
 
const Favs:React.FC<_Favorite.Gets>= (params) =>{
    return <ScrollFavs 
        key={'fav-'+params.type}
        id={'fav-'+params.type}
        initialSetting={params}
        btnRetryClass='mt-4'
    >
        {(items)=> <Render favs={items}/>}
    </ScrollFavs>
}

export const ProfileFavs:React.FC = () => {
    const { user } = useProfile()

    return (<>
            <PanelOfProfile >
                <Panel.Nav skipScroll={false} listenLocation>
                {[
                    { name: "Volver", key: "fav-back", skipScroll: true, jsx: 
                        <Link to={ `/user/${user._id}/${user.username}`} state={{skipScroll: true}}>
                            <NavList.ButtonRounted title='Volver' materialIcon='arrow_back' className='' />
                        </Link>
                    },
                    { name: "Shouts", to: `/user/${user._id}/${user.username}/favoritos`, key: "fav-shouts", skipScroll: true },
                    { name: "Posteos", to:`/user/${user._id}/${user.username}/favoritos/post`,key: "fav-posts", skipScroll: true },
                    { name: "Comentarios", to:`/user/${user._id}/${user.username}/favoritos/comments`,key: "fav-comentarios", skipScroll: true },
                ]}
                </Panel.Nav>
                <Routes>
                    <Route path={`post`} element={<>
                        <DocumentTitle title={`Posteos - Favoritos - ${user.username}`}/>
                        <Favs type={"Post" as any} cound={10}/>
                    </>}>
                    </Route>
                    <Route path={`comments`} element={<>
                        <DocumentTitle title={`Comentarios - Favoritos - ${user.username}`}/>
                        <Favs type={"Comment" as any} cound={10}/>
                    </>}>
                    </Route>
                    {/* exact path={`/user/${_id}/${username}`} */}
                    <Route path="/" element={<>
                        <MemoHeightProvider name="favs" widthRefDom={".__shout__"}>
                            <DocumentTitle title={`Shouts - Favoritos - ${user.username}`}/>
                            <WidthRead/>
                            <Favs type={"Shout" as any} cound={10}/>
                        </MemoHeightProvider> 
                    </>}>
                    </Route>
                </Routes>
            </PanelOfProfile>
    </> );
}