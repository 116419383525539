import React, { useEffect, useRef, useState } from "react";
import { useCssHandles } from "../../../../../hooks/useCssHandles";
import "./index.scss";
import SR from '../../../../utils/resources';
import { Link } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import cn from "classnames";
import { refHistory } from "../../../../../hooks/useHistory";
import { useMediaQuery } from 'react-responsive'

export type SearchHeaderProps = JSX.IntrinsicElements["div"] & {
    w100?:boolean,
    autoFocus?:boolean,
    restoreParam?:boolean,
    inputClassName?: string,
    autoClear?:boolean,
    /**
     * Oculta el input si la resolucion es menor de 800
     */
    minWidthShow?:number | undefined
}
export const SearchHeader:React.FC<SearchHeaderProps> = ({w100, autoFocus, restoreParam, className,inputClassName,autoClear, minWidthShow , ...args})=>{
    const handler = useCssHandles("y2buQ", [
        "input",
        "iconSearch",
        "iconClose"
    ] as const);
    const styles = {
        input: "bg-[#edf3f8] dark:bg-[#151a27] text-gray-500 dark:text-gray-300 focus:outline-gray-300 dark:focus:outline-[#283e5c] "+inputClassName,
        iconClose: "bg-transparent dark:bg-[#151a27] text-gray-500",
        iconSeachNoText: "text-gray-400 dark:text-white",
        iconSearchWithText: "bg-[#3f6291aa] dark:bg-[#283e5c] text-white dark:text-white"
    }
    
    const [querySearch, setQueryParam] = useQueryParam<string>("q");
    const [search="", setSearch] = useState<string>(restoreParam?querySearch || "":"");
    const input = useRef<HTMLInputElement>();
    const link = useRef<HTMLAnchorElement>();
    const minWidth = minWidthShow? useMediaQuery({ minWidth: minWidthShow }): true
    
    useEffect(()=>{
        if(search == querySearch) return
        setSearch(querySearch)
    },[querySearch])

    // ⚠️ Quitar esto rompera posicionamiento del scroll al volver al sitio que tenga este elemento.
    const isBack = refHistory().navigateType === "POP"

    const isShowClose = search.length > 0?"":"hide";
    const isLarge = search.length > 12? "large":"";
    const hasText = search.length > 0? "text":"";
    const hasW100 = w100?"w-100":""

    const _Search = (
        <div className={cn(handler.iconSearch,hasText? styles.iconSearchWithText: styles.iconSeachNoText)}>
            {SR._icons._Search({size: 15})}
        </div>
    );

    if(!minWidth) return null

    return <div {...args} className={cn(`${handler.ROOT} ${isLarge} ${hasText}`, className)}>
        <input placeholder="Buscar" {...autoFocus && !isBack?{autoFocus:true}:{}} ref={input as any} onKeyUp={(event)=>{
            if (event.keyCode === 13) {
                link.current?.click();
            }
        }} className={`${handler.input} ${styles.input} ${hasW100} `} value={search} onChange={v=>setSearch(v.target.value)}/>
        <div className={`${handler.iconClose} ${styles.iconClose} ${isShowClose}`} onClick={()=> {
            setSearch("");
            input.current?.focus()
        }}>
            {SR._icons._close3({size: 15})}
        </div>
        {isShowClose?_Search:(
            <Link ref={link as any} to={`/search?q=${encodeURI(search)}`} onClick={(e)=>{
                if(window.location.pathname !== "/search") return;
                e.preventDefault();
                setQueryParam(search);
                if(autoClear) setTimeout(()=>{
                    setSearch("")
                },200)
            }}>
                {_Search}
            </Link>
        )}
    </div>;
}