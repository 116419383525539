import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({color,...props}: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
    <g
        {...props}
        transform={`translate(2.3 3.5), scale(1 0.9), rotate(0)`}
        > 
         <path fill={color} d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM5 4h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1zm0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1z" />
    </g>
    </>
)

export default ({borde1, backgroundShadow1}: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
    <g transform="translate(-2.7 3.5),  rotate(-15)" >
        <g transform="translate(1.4 1.5)" >
            <Icon color={backgroundShadow1}/>
        </g>
        <Icon color={borde1}/>
    </g>
    </>
)
