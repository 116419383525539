import { useNavigate,useLocation, NavigationType, useNavigationType } from "react-router-dom";
import { Location } from "@remix-run/router";
import { useRef } from "react";

let ref:ReturnType<typeof Build> = undefined;

type DefaultStateType<T extends Record<string, any>> = {idx: number, key: string, usr?: T}

const Build = ()=>{
    const _navegate = useNavigate();
    const _location = useLocation();
    const _navigateType: NavigationType = useNavigationType();
    const _history = history as  Omit<History, "state"> & {state: DefaultStateType<unknown>}

    return {
        navegate: _navegate,
        location: _location,
        history: _history,
        navigateType: _navigateType,
        replace: (state:Record<string,any>={})=>{
            _navegate(location.href.replace(location.origin,""), {
                replace: true,
                state:{
                    ..._location.state,
                    ...state,
                }
            })
        }
    }

}

export const HistoryContext = ()=>{
    ref  = Build()
    return null;
}

export const refHistory = <T={}>()=>{
    return ref as Omit<typeof ref, "location" | "history"> & {
        location: Location<T>,
        history: Omit<History, "state"> & {state: DefaultStateType<T>}
    };
}