import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useCssHandles } from "../../../hooks/useCssHandles";
import { Profile } from "../../../pages/user/components/usersesion";
import { Layout } from "../../layout";
import { Wrapper } from "../../wrapper";
import { Item } from "./components/Item/intex";
import { Logo } from "./components/logo/index";
import "./index.scss";
import Loading from "../../utils/loading";
import { ProfileMenu } from "./components/ProfileMenu";
import { HideOnScroll } from "./../../mui/HideOnScroll";
import AppBar, { appBarClasses } from "@mui/material/AppBar";
import { IrAlCielo } from "../../utils/iralcielo";
import { SearchHeader } from "./components/Search";
import { TagIs } from "../../../contexts/EnvironmentContext/useTags";
import { ValidType } from "../../../contexts/PeluchanContext/useValid";
import { SetterContent } from "./components/SetterContent";
import { useTheme } from "../../../contexts/ThemeContext";

const GroudRight: React.FC = () => {
  return (
    <Profile
      loading={() => <Loading />}
      err={(error: Error) => {
        return error || <Loading />;
      }}
      out={() => {
        return (
          <Fragment>
            <Item text={"Registrarse"} to="/register" className="mr-2"/>
            <Item text={"Iniciar sesión"} to={`/login?returnUrl=${window.location.pathname}`} style="primary"/>
          </Fragment>
        );
      }}
      ok={(r: ValidType) => {
        return <ProfileMenu user={r?.user} />;
      }}
    />
  );
};

export const HeaderDesktop: React.FC = () => {
  // const {toggleTheme, theme} = useTheme()
  const refConteiner = useRef<HTMLDivElement>(null);

  const styles = {
    container: "bg-white dark:bg-[#1d2333]",
    slider: "shadow-sm z-100"
  }
  const _styles = useCssHandles("rLmtSQKDjm", ["slider", "container", "content", "groud"] as const);

  return (
    <Fragment>
      {/* <LazyPosition element={refConteiner}/> */}
      <HideOnScroll>
        <AppBar
          classes={{ root: styles.slider }}
          sx={{
            [`&`]: {
              boxShadow: "0 0 20px #151b2a",
            },
          }}
        >
          <div ref={refConteiner} className={styles.container}>
            <Wrapper>
              <Wrapper.Padded notTop={true} h100={true}>
                <div className={_styles.content}>
                  <div className={_styles.groud}>
                    <Logo />
                    <Layout.HorizontalSpace mul={1.5} />
                    <Item text={"Inicio"} to="/home" />
                    <Item text={"Shouts"} to="/mi" />
                    <Layout.HorizontalSpace mul={1.5} />
                    <SearchHeader autoClear={true} className="" minWidthShow={885}/>
                  </div>
                  <div className={_styles.groud}>
                    {/* <span onClick={()=> toggleTheme()}>
                      <Item className="p-2 mr-3" style="icon" text={<span className="material-icons w-[24px]">{theme=="dark"?"dark_mode":"light_modes"}</span>} />
                    </span> */}
                    <SetterContent/>
                    <div className="border-0 border-r-[1px] border-solid border-[#57a8ff33] h-[30px] ml-3 mr-3 pl-2"/>
                    <GroudRight />
                  </div>
                </div>
              </Wrapper.Padded>
            </Wrapper>
          </div>
        </AppBar>
      </HideOnScroll>
      <Layout.VerticalSpace exac={"50px"} />
      <IrAlCielo />
    </Fragment>
  );
};
