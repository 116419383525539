import cn from "classnames";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useDevice } from "../../components/utils/mscreen";

export function useSnack() {
    const { isDesktop } = useDevice();
    const ref = useRef<ReturnType<typeof useSnackbar>>();

    return {
        contexts: {
            notify: {
                set: (v: ReturnType<typeof useSnackbar>) => (ref.current = v),
                create: (
                    ...args: Parameters<
                        typeof ref["current"]["enqueueSnackbar"]
                    >
                ) =>
                    ref.current.enqueueSnackbar(args[0], {
                        ...args[1],
                        anchorOrigin: isDesktop
                            ? {
                                  vertical: "bottom",
                                  horizontal: "right",
                              }
                            : {
                                  vertical: "top",
                                  horizontal: "center",
                              },
                        className: cn(
                            args[1].className,
                            isDesktop ? "mode-desktop" : "mode-mobile"
                        ),
                    }),
            },
        },
        childrens: () => <></>,
    };
}
