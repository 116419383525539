import React from "react";
import { PlantillaInfoType } from "../../types";
import { MedallaInfoWithNivel } from "./plantillas/InfoWithNivel";
import { MedallaInfo } from "./plantillas/Info";


export const PlantillaInfo:React.FC<React.PropsWithChildren<PlantillaInfoType>> = ({plantilla=null, children, ...args})=>{
    if(plantilla === "MedallaInfoWithNivel") return <MedallaInfoWithNivel {...args as any}/>
    if(plantilla === "Medalla") return <MedallaInfo {...args as any}/>
    return null;
}