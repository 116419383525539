import { Channel } from "../../utils/Channel"

type MessageType =  {
    type: "IS_MASTER" 
}
| { 
    type: "MASTER_RESPONSE"
}
| {
    type: "NO_MASTER" 
}
| {
    type: "WIN_MASTER" 
}
| {
    type: "PROXY_EMIT"
    eventName: string
    eventArgs: any[]
}
| {
    type: "PROXY_IN"
    eventName: string
    eventArgs: any[]
}

export const TabChannel = new Channel<MessageType>('peluchan-channel-'+location.host)