import React from "react";
import { ShoutContextType, useShout } from "../../item-shout"
import Tooltip from "../../../../../components/utils/tooltip";
import SR from "../../../../../components/utils/resources";
import SendDenuncia from "../../../../../components/modal/senddenuncia";
import { usePeluchan } from "../../../../../contexts/PeluchanContext";
import SendDelete from "../../../../../components/modal/senddelete";
import { LazyState } from "../../../../../components/utils/LazyState";
import { refEnv } from "../../../../../contexts/EnvironmentContext";

const useFavoritos = (ctxPeluchan,ctxShout:ShoutContextType)=>{
    const {login:{ isLogin }} = ctxPeluchan;
    const { shout, root } = ctxShout;

    const delete_favs = shout.fav || false;
    if(!isLogin) return []; 
    
    return [{
        text: delete_favs? `Quitar`:`Guardar`,
        click: delete_favs? 
        async ()=>{ await window.RPOST("/api/fav/del",{ _id: shout._id, type: "Shout" }).then(()=> ctxShout.actions.deleted()) }:
        async ()=>{ await window.RPOST("/api/fav/add",{ _id: shout._id, type: "Shout" }) },
        icon: SR._icons._favorite_list4({size: 18})
    }];
}

export const useEliminar = (ctxPeluchan, ctxShout)=>{
    const {login:{ 
        isLogin, 
        my, rol: { mod } 
    }} = ctxPeluchan;

    const { 
        shout,
        actions: { deleted }
    } = ctxShout;

    if(!isLogin) return []; 

    let options = [{
        text:"Eliminar",
        show: ((my(shout.author._id) || mod) && (shout.body.type!=="delete"? true: false))?true:false,
        click: async ()=>{
            if(await window.RPOST(`/api/shout/delete`,{ 
                shoutIds: [shout._id], 
                spam: false 
            })) deleted();
        },
        icon: SR._icons._delete
    }];

    if(mod) options.push({
        text:"Eliminar por spam",
        show: (!my(shout.author._id) && (shout.body.type!=="delete"? true: false))?true:false,
        click: async ()=>{
            if(await window.RPOST(`/api/shout/delete`,{ 
                shoutIds: [shout._id], 
                spam: true 
            })) deleted();
        },
        icon: SR._icons._delete
    });
    
    if(mod) options.push({
        text:"Eliminar por x motivo",
        show: (!my(shout.author._id) && (shout.body.type!=="delete"? true: false))?true:false,
        click: async ()=>{ window.popup.load(
            <SendDelete 
                title={`Eliminar shout de @${shout.author.username}`} 
                preview={(text)=>
                    <>
                        Se elimino el <a href="#">@</a>shout de <a href="#">@</a>{shout.author.username} {text}
                    </>
                }
                _delete={async (msg)=>{
                    if(await window.RPOST(`/api/shout/delete`,{ 
                        shoutIds: [shout._id], 
                        spam: false,
                        porque: msg
                    })) deleted();
                }}
            /> 
        );},
        icon: SR._icons._delete_motivo
    });

    return options;
}

export const useDenunciar = (ctxPeluchan, ctxShout)=>{
    const {login:{ isLogin, my }} = ctxPeluchan;
    if(!isLogin) return [];
    const {shout} = ctxShout;

    return [{
        text:"Denunciar",
        show: !my(shout.author._id),
        click: ()=>{window.popup.load(<SendDenuncia parent={shout} parent_type={"Shout"}/> );},
        icon: SR._icons._shout_denunciar
    }];
}

export const useHiddenContent = (ctxPeluchan, ctxShout)=>{
    const {login:{ isLogin, rol:{mod} }} = ctxPeluchan;
    const {shout, forceUpdate} = ctxShout;
    const { hidden_content=false } = shout;

    
    const hiddent_content = async({_id="",hidden_content=false},fatansma=false)=>{
        var report = await window.RPOST(`/api/shout/hidden_content`,{
            shout_id: _id,
            active: !hidden_content,
            ninja: fatansma
        });
        if(report){
            const stateNsfwKey = _id+":state-nsfw"
            LazyState.set(stateNsfwKey, !hidden_content)
            shout.hidden_content  = !hidden_content;
            forceUpdate();
        }
    }
    
    if(isLogin && !mod && refEnv().tag.is("|hidden_content") ){
        if(hidden_content && !refEnv().tag.is("|hidden_content_restore")) return []
        return [{
            text: (!hidden_content?"Esto va en NSFW":"Esto no va en NSFW"),
            click: ()=>{hiddent_content(shout)},
            icon: SR._icons._photoshidens
        }]
    }

    if(!isLogin || !mod) return [];
    
    return [{
        text: (!hidden_content?"Esconder contenido":"Mostrar contenido"),
        click: ()=>{hiddent_content(shout)},
        icon: SR._icons._photoshidens
    },{
        text: (!hidden_content?"Esconder contenido (fantasma)":"Mostrar contenido (fantasma)"),
        click: ()=>{hiddent_content(shout,true)},
        icon: SR._icons._photoshidensfantasma
    }]
}


export default (props)=>{
    const ctxPeluchan = usePeluchan();
    const _ctxShout = useShout();
    let {
        props: { 
            no_setting = false,
        }
    } = _ctxShout;

    const ctxShout = {
        ..._ctxShout,
        ...props.shout? {shout: props.shout}:{}
    }

    const favoritos = useFavoritos(ctxPeluchan,ctxShout);
    const hidden_content = props.isShared? [] :useHiddenContent(ctxPeluchan,ctxShout);
    const eliminar = useEliminar(ctxPeluchan,ctxShout);
    const denunciar = useDenunciar(ctxPeluchan,ctxShout);

    if(no_setting) return null;

    return <div>
        <Tooltip title="Shout" offset={[0,8]} placement="left-end" className="absolute right-[-6px] max-width-none">
            {
                [
                    ...favoritos,
                    ...hidden_content,
                    ...eliminar, 
                    ...denunciar,   
                ]
            }
        </Tooltip>
    </div>
}