import React, { FC } from "react";
import { useCssHandles } from "../../hooks/useCssHandles";
import { useScreen } from "../utils/mscreen";
import "./index.scss";


export const Layout = ()=>{
    return null;
} 

type SpaceProps = {
    mul?:number,
    exac?: string | number
}
Layout.VerticalSpace = ({mul=1, exac}:SpaceProps) => {
    return (
        <div style={{height: exac || 15 * mul}}/>
    );
}

Layout.HorizontalSpace = ({mul=1, exac}:SpaceProps) => {
    return (
        <div style={{marginRight: exac || 15 * mul}}/>
    );
}

type CenterProps = {
    children:React.ReactNode,
    glow?:boolean
}

Layout.Center = ({children, glow}:CenterProps) => {
    const styles = useCssHandles("layout-center",[]);
    return (
        <div className={styles.ROOT} style={{
            ...glow?{flexGrow: 1}:{},
        }}>
            {children}
        </div>
    );
} 
Layout.TextCenter = ({children, glow}:CenterProps) => {
    const styles = useCssHandles("layout-center-text",[]);
    return (
        <div className={styles.ROOT} style={{
            ...glow?{flexGrow: 1}:{},
        }}>
            {children}
        </div>
    );
} 
Layout.CenterFixed = ({children, glow}:CenterProps) => {
    const styles = useCssHandles("layout-center-fixed",[]);
    return ( 
        <div className={styles.ROOT} style={{
            ...glow?{flexGrow: 1}:{},
        }}>
            {children}
        </div>
    );
} 

export const Mobile:FC<React.PropsWithChildren> = ({children})=>{
    const isMobile = useScreen().isMobile();
    if(!isMobile) return null;
    return <>{children}</>;
}

export const Desktop:FC<React.PropsWithChildren> = ({children})=>{
    const isMobile = useScreen().isDesktop();
    if(!isMobile) return null;
    return <>{children}</>;
}