import React from "react";
//import { createPopper } from '@popperjs/core';
import { Link } from "react-router-dom";
import { refPeluchan } from "../../contexts/PeluchanContext";
import { refEnv } from "../../contexts/EnvironmentContext";
import { PlantillaIcon } from "../../pages/components/Medallas/medalla/icono/plantilla";
import { Imagen } from "../Imagen/Imagen";
import { Timer } from "../styleguide/components/Timer";
import { RetryCommentFocus } from "../../pages/components/Comment/utils/CommentFocus";



const ClickToComment = (_id, url="")=>{
  return ()=> {
    if(url.includes("/post/") !== 0 && url.includes("/shouts/") !== 0) RetryCommentFocus(_id, url)
  }
  
  if(refEnv().tag.is("toInPage"))
  if(url.includes("/post/") !== 0 && url.includes("/shouts/") !== 0) 
  return (e)=>{
    if(location.pathname.startsWith(url)) return
    const id = document.getElementById(_id)
    if(!id || location.pathname == "/mi" || location.pathname == "/mi") return
    setTimeout(() => {
        id.scrollIntoView();
        id.classList.add("_push_");
        setTimeout(() => {
            id.classList.remove("_push_");
        }, 2000);
    }, 300);
    e.preventDefault()
  }
}



const replaceDom = (content)=>{
  if(typeof content == "string") {
    content = content.replace("[img]","🖼️")
    content = content.replace("[source]","🎬")
    content = content.replace("[link]","🔗")
    content = content.replace("[iframe]","🌐")
  }
  return content
}


class Notification extends React.Component {
  plantilla({ from, photo, rawPhoto, create, message, body, footer, subtitle, mode = "",noProxyImg = false }) {
    const isNow = (new Date(create) > new Date(Date.now() - 1000 * 10))

    return (
      <>
        <div className={`header media ${mode}`}>
          {from || photo ? 
            (noProxyImg? 
              <img className="photo" src={from?.photo || photo}/>: 
              <Imagen pId="Xx2nBR4obJ" className="photo"  src={from?.photo || photo}/>
            ) : null}
          {rawPhoto ? rawPhoto : null}
          <div className="media-body">
            <div className="" >
              <span className="time inline-block pl-2 right whitespace-nowrap">
                {isNow?"ahora":<Timer fromNow ago time={create}/>}
              </span>
              
              <span className="flex-grow-1">
                {!from ? null : (
                  <Link  className="inline" to={`/user/${from._id}/${from.username}`}>
                    {from.name}
                  </Link>
                )}
                <span className="">
                  {message}
                </span>
              </span>
            </div>
            <div>{subtitle}</div>
          </div>
        </div>
        {body && <div className="body">{body}</div>}
        {footer && <div className="footer">{footer}</div>}
      </>
    );
  }

  RateShout({ from, create }, { shout = {}, rate = 0 }) {
    if (shout) {
      const { _id = "_" } = shout;
      return (
        <this.plantilla
          from={from}
          create={create}
          message={
            rate === 1 ? (
              <>
                {` te dio `}
                <span className="color_green">+1</span>
                {` en tu `}
                <Link to={`/shout/${_id}`}>{"Shout"}</Link>
              </>
            ) : (
              <>
                {`Te dieron `}
                <span className="color_red">-1</span>
                {` en tu `}
                <Link to={`/shout/${_id}`}>{"Shout"}</Link>
              </>
            )
          }
          mode="nano"
        />
      );
    }
    return "'post-rate' no valid";
  }

  RateComment({ from, create }, { comment = {}, rate = 0 }) {
    if (comment) {
      const { _id = "_", preview = "", parent_type = "", parent = "" } = comment;
      let link = "...";
      if (parent_type === "Post") link = `/post/${parent}/_#${_id}`;
      if (parent_type === "Shout") link = `/shout/${parent}#${_id}`;
      return (
        <this.plantilla
          from={from}
          create={create}
          message={
            rate === 1 ? (
              <>
                {` te dio `}
                <span className="color_green">+1</span>
                {` en tu `} <Link to={link} onClick={ClickToComment(_id, link)}>{"comentario"}</Link>
              </>
            ) : (
              <>
                {`Te dieron `}
                <span className="color_red">-1</span>
                {` en tu `} <Link to={link} onClick={ClickToComment(_id, link)}>{"comentario"}</Link>
              </>
            )
          }
          mode="nano"
          body={<Link to={link} onClick={ClickToComment(_id,link)} >{replaceDom(preview)}</Link>}
        />
      );
    }
    return "'post-rate' no valid";
  }

  RatePost({ from, create }, { post = {}, rate = 0 }) {
    //const {photo,name,username} = from;
    if (post) {
      const {
        title = "...",
        slug = "...",
        //author="..."
      } = post;
      return (
        <this.plantilla
          from={from}
          create={create}
          message={
            <>
              {` te dejo`} <span className="color_green">+{rate.toString()}</span> {`en tu post`}
            </>
          }
          subtitle={<Link to={`/post/${post._id}/${slug}`}>{title}</Link>}
        />
      );
    }
    return "'post-rate' no valid";
  }

  CreatePostFallow({ from, create }, { post = {} }) {
    //const {photo,name,username} = from;
    if (post) {
      const {
        title = "...",
        slug = "...",
        //author="..."
      } = post;
      return <this.plantilla from={from} create={create} message={` creo un post`} subtitle={<Link to={`/post/${post._id}/${slug}`}>{title}</Link>} />;
    }
    return "'etiqueta' no valid";
  }

  CreateShoutFallow({ from, create }, { shout = {} }) {
    //const {photo,name,username} = from;
    if (shout) {
      const { _id = "_" } = shout;
      return <this.plantilla from={from} create={create} message={` creo un shout`} subtitle={<Link to={`/shout/${_id}`}>{"Ir al Shout"}</Link>} />;
    }
    return "'etiqueta' no valid";
  }

  Etiqueta({ from, create }, { comment = {}, post, shout }) {
    //const {photo,name,username} = from;
    if (post) {
      const { preview = "...", _id = "..." } = comment;
      const {
        title = "...",
        slug = "...",
        //author="..."
      } = post;
      const url = `/post/${post._id}/${slug}#${_id}`
      return (
        <this.plantilla
          from={from}
          create={create}
          message={` te etiqueto en un comentario`}
          subtitle={
            <Link to={`/post/${post._id}/${slug}`}>
              <span className="title w-100">{`${title}`}</span>
            </Link>
          }
          body={<Link to={url} onClick={ClickToComment(_id,url)}>{replaceDom(preview)}</Link>}
        />
      );
    }
    if (shout) {

      const { preview = "...", _id = "..." } = comment;
      const url = `/shout/${shout._id}${comment._id ? `#${_id}` : ``}`
      return (
        <this.plantilla
          from={from}
          create={create}
          message={` te etiqueto en un shout`}
          subtitle={<Link to={url} onClick={ClickToComment(_id, url)}>{comment.preview ? replaceDom(preview) : shout.preview ? shout.preview : `shout:${shout._id}`}</Link>}
        />
      );
    }
    return null;
  }

  PrivateMessage({ from = {}, create }, { message = {} }) {
    //const {photo,name,username} = from;
    const { preview = "..." } = message;
    return (
      <this.plantilla
        from={from}
        create={create}
        message={` te mando un mensaje`}
        mode="nano"
        body={
          <>
            <Link to={`/mensajes`}>{replaceDom(preview)}</Link>
          </>
        }
      />
    );
  }

  ReplyComment({ from, create }, { post, shout, comment = {} }) {
    if (!from) from = window.user404;
    const { photo, name, username } = from;
    const { preview = "...", _id = "..." } = comment;

    if (post) {
      const { title = "...", slug = "...", author = "..." } = post;
      const IsMyPost = refPeluchan().login.my(author);
      const url = `/post/${post._id}/${slug}#${_id}`

      
      return (
        <this.plantilla
          from={from}
          create={create}
          message={` te respondio ${IsMyPost ? "en tu post" : "en un post"}`}
          mode="media"
          subtitle={<span className="title w-100" title={title}>{`${title}`}</span>}
          body={
            <>
              <Link to={url} onClick={ClickToComment(_id, url)}>{replaceDom(preview)}</Link>
            </>
          }
        />
      );
    }
    if (shout) {
      const { author = "..." } = shout;
      const IsMyPost = author === refPeluchan().login.my(author);
      const url = `/shout/${shout._id}#${_id}`

      return (
        <this.plantilla
          from={from}
          create={create}
          message={` te respondio ${IsMyPost ? "en tu shout" : "en un shout"}`}
          mode="media"
          subtitle={
            <>
              <Link to={url} onClick={ClickToComment(_id, url)}>{replaceDom(preview)}</Link>
            </>
          }
        />
      );
    }
    return "'retry-comment' no valid";
  }
  
  ActionMod({ from, create }, { post, shout, comment = {}, action }) {
    if (!from) from = window.user404;

    if (post) {
      const { title = "...", slug = "...", author = "..." } = post;
      const url = `/post/${post._id}/${slug}`

      return (
        <this.plantilla
          create={create}
          photo={"/resources/logos/icon@96.png"}
          noProxyImg={true}
          message={`Se elimino este post${action?.porque||""}`}
          subtitle={<Link to={url}><span className="title w-100" title={title}>{`${title}`}</span></Link>}
          mode="media"
        />
      );
    }
    if (shout) {
      const url = `/shout/${shout._id}`

      return (
        <this.plantilla
          create={create}
          photo={"/resources/logos/icon@96.png"}
          noProxyImg={true}
          message={`Se elimino este shout${action?.porque||""}`}
          mode="media"
          subtitle={
            <>
              <Link to={url}>{`shout:${shout._id}`}</Link>
            </>
          }
        />
      );
    }

    if (comment) {
      const { preview = "...", _id = "...", parent_type } = comment;
  
      const url = parent_type=="Shout"?`/shout/${comment.parent}#${_id}`:`/post/${comment.parent}/_#${_id}`

      return (
        <this.plantilla
          create={create}
          photo={"/resources/logos/icon@96.png"}
          noProxyImg={true}
          message={`Se elimino este comentario${action?.porque||""}`}
          mode="media"
          subtitle={
            <>
              <Link to={url} onClick={ClickToComment(_id, url)}>{replaceDom(preview)}</Link>
            </>
          }
        />
      );
    }

    return "'mod-action' no valid";
  }


  PostComment({ create, from }, { comment = {}, post = {} }) {
    if (!from) from = window.user404;
    const { preview = "...", _id = "..." } = comment;

    if (post) {
      const { title = "...", slug = "..." } = post;
      const url = `/post/${post._id}/${slug}#${_id}`

      return (
        <this.plantilla
          from={from}
          create={create}
          message={` comento en tu post`}
          mode="media"
          subtitle={<span className="title w-100" title={title}>{`${title}`}</span>}
          body={
            <>
              <Link to={url} onClick={ClickToComment(_id, url)}>{replaceDom(preview)}</Link>
            </>
          }
        />
      );
    }
    return "'post-comment' no valid";
  }

  ShoutComment({ create, from }, { comment = {}, shout = {} }) {
    //const {photo,name,username} = from;
    const { preview = "...", _id = "..." } = comment;
    if (shout) {
      const url = `/shout/${shout._id}#${_id}`
      return <this.plantilla from={from} create={create} message={` comento en tu shout`} subtitle={<Link to={`/shout/${shout._id}#${_id}`} onClick={ClickToComment(_id, url)}>{replaceDom(preview)}</Link>} />;
    }
    return "'shout-comment' no valid";
  }

  Medallas({ create, from }, data) {
    const { message, body, footer, subtitle, mode } = data?.plantilla || {};
    const { icon } = data?.data || {};
    return (
      <this.plantilla
        {...{
          ...(message ? { message } : {}),
          ...(subtitle ? { subtitle } : {}),
          ...(body ? { body } : {}),
          ...(icon
            ? {
                rawPhoto: <PlantillaIcon scale={1} className="mr3" {...icon} />,
              }
            : {}),
          ...(footer ? { footer } : {}),
          ...(mode ? { mode } : {}),
        }}
        from={from}
        create={create}
      />
    );
  }

  NotyPlantilla({ create, from }, data) {
    const { message, body, photo, footer, subtitle, mode } = data?.plantilla || {};
    return (
      <this.plantilla
        {...{
          ...(message ? { message } : {}),
          ...(subtitle ? { subtitle } : {}),
          ...(body ? { body } : {}),
          ...(photo ? { photo } : {}),
          ...(footer ? { footer } : {}),
          ...(mode ? { mode } : {}),
        }}
        from={from}
        create={create}
      />
    );
  }

  render() {
    const { last } = this.props;
    const { content, create } = this.props.data;
    const data = JSON.parse(content);

    let _content = null;
    if (data.type === "reply-comment") _content = this.ReplyComment(this.props.data, data);
    if (data.type === "post-comment") _content = this.PostComment(this.props.data, data);
    if (data.type === "shout-comment") _content = this.ShoutComment(this.props.data, data);
    if (data.type === "message-private") _content = this.PrivateMessage(this.props.data, data);
    if (data.type === "mod-action") _content = this.ActionMod(this.props.data, data);
    if (data.type === "etiqueta") _content = this.Etiqueta(this.props.data, data);
    if (data.type === "create-post-fallow") _content = this.CreatePostFallow(this.props.data, data);
    if (data.type === "create-shout-fallow") _content = this.CreateShoutFallow(this.props.data, data);
    if (data.type === "post-rate") _content = this.RatePost(this.props.data, data);
    if (data.type === "shout-rate") _content = this.RateShout(this.props.data, data);
    if (data.type === "comment-rate") _content = this.RateComment(this.props.data, data);
    if (data.type === "medalla") _content = this.Medallas(this.props.data, data);
    if (data.type.indexOf("plantilla-") === 0) _content = this.NotyPlantilla(this.props.data, data);

    var time_create = Timer.get(create);
    var last_see = Timer.get(last);
    const isNew = last && time_create.isAfter(last_see);

    if (isNew) {
      setTimeout(() => {
        if (!this.root || !this.root.classList) return;
        this.root.classList.remove("_new_");
      }, 1000);
    }

    return (
      <div
        ref={(r) => {
          this.root = r;
        }}
        className={`noti ${isNew ? "_new_" : ""}`}
      >
        {_content}
      </div>
    );
  }
}

export default Notification;
