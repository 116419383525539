import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Profile_Tooltip } from "../../../../../pages/user/components/usersesion";
import { useCssHandles } from "../../../../../hooks/useCssHandles";
import { MenuUserItem } from "./components/Items";
import { TooltipDesktop } from "../TooltipDesktop";
import "./index.scss";
import { HeaderTooltip, HeaderTooltipRaw } from "../../../../mui/Tooltip";
import {EditorShoutV2, EditorShoutData, SendShoutData } from "../../../../../pages/components/Shout/components/Editor/editor-shout";
import { LoadingButtonInShouts } from "../../../../mui/LoadingButton";
import RemoteUpdate from '../../../../utils/remoteupdate';
import SendIcon from '@mui/icons-material/Send';
import SR from '../../../../utils/resources';

type ProfileMenuProps = {
    user?: any
}

export const NanoIconShoutData:React.FC<{x:number, y:number, color?:string}> = ({x,y, color="#ec5fa8"})=>{
    if(!localStorage.getItem("shout-temp")) return null;
    return (
        <span style={{
            "background": color,
            "borderRadius": "99px",
            "width": "8px",
            "height": "8px",
            "position": "absolute",
            "border": "2px solid #1d2333",
            "transform": `translate(${x}px, ${y}px)`
        }}/>
    )
}

export const useMemoryShoutEditor = (_default:EditorShoutData | undefined= undefined, {
    memory = true
}:{
    memory?: boolean,
}={})=>{
    const [value, setValue] = useState<EditorShoutData | undefined>(_default);

    useEffect(()=>{
        if(!memory) return;
        if(value) return;
        var temp = localStorage.getItem("shout-temp");
        if(!temp) return;
        setValue(JSON.parse(temp));
    },[])
    
    const clear = ()=>{
        setValue(undefined);
        if(memory) localStorage.removeItem("shout-temp");
        setTimeout(()=> (window as any).forceUpdate.run("sD3HejBcca"), 1) 
    }

    const onChange = (data:EditorShoutData)=>{
        if(memory) localStorage.setItem("shout-temp", JSON.stringify(data));
        setValue(data);
        (window as any).forceUpdate.run("sD3HejBcca"); 
    }

    return {
        value, setValue,
        clear,
        onChange
    }
}

const HeaderSendShout:React.FC = ()=>{
    const [loading, setLoading] = useState(false);
    const {value, clear, onChange} = useMemoryShoutEditor();

    return <EditorShoutV2 
        onChange={onChange}
        value={value}
        disabled={loading} 
        footerRight={(
            <div className="flex items-center">
                {value && <button title="Limpiar datos" className="shout-clears pa2 mr3" onClick={()=> clear()}>{SR._icons._close3({size: 14})}</button>}
                <LoadingButtonInShouts 
                        onClick={async()=>{
                            if(!value) return;
                            setLoading(true);
                            const isOK = await SendShoutData(value);
                            await (new Promise(c=> setTimeout(c,1000)))
                            setLoading(false);
                            if(isOK) clear();
                        }}
                        endIcon={<SendIcon />}
                        loading={loading}
                        variant="contained"
                        loadingPosition="end"
                    >
                    Enviar
                </LoadingButtonInShouts>
            </div>
        )}
    />
}

export const ProfileMenu:React.FC<ProfileMenuProps> = ({user={}}={}) => {
    const styles = useCssHandles("oXjalm27Ks",[
        "link"
    ] as const);
    
    return (
        <>
            
            <HeaderTooltip placement="bottom-end" title={<HeaderSendShout/>}>
                <Link className={styles.link} to="#" onClick={(e)=>{
                    e.preventDefault();
                }}>
                    <span >
                        <span className="material-icons">
                            add_box
                        </span>
                        <RemoteUpdate _key="sD3HejBcca" content={()=>(
                            <NanoIconShoutData x={-15} y={6}/>
                        )}/>
                    </span>
                </Link>
            </HeaderTooltip> 

            {/* <Layout.HorizontalSpace mul={0.5}/> */}
            
            <TooltipDesktop/> 

            <HeaderTooltipRaw leaveDelay={0} title={"Mensajes"}>
                <Link className={styles.link} to={"/mensajes"}>
                    <span className="material-icons">
                        forum
                    </span>
                </Link>
            </HeaderTooltipRaw> 
            

            <Profile_Tooltip user={user}>
                <MenuUserItem user={user}/>
            </Profile_Tooltip>
        </>
    );
}