import React, { useEffect, useMemo, useRef, useState } from 'react';
import Message from "../../../components/styleguide/components/legacy/message";
import EditorList from '../../../components/EditorList/editorlist';
import { usePeluchan } from '../../../contexts/PeluchanContext';
import { withRequiredLogin } from '../../../components/utils/RequiredLogin';
import TagManager from 'react-gtm-module';
import { ConcatY } from '../../../components/styleguide/components/Concat';
import { CommentPhoto } from './components/photo';
import cn from 'classnames';
import { Button } from '../../../components/styleguide/components/Button';
import { CommentHeader } from './components/header';

export function isVisible(elem:HTMLElement) {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');
    const style = getComputedStyle(elem);
    if (style.display === 'none') return false;
    if (style.visibility !== 'visible') return false;
    if (style.opacity && parseFloat(style.opacity) < 0.1) return false;
    if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
        elem.getBoundingClientRect().width === 0) {
        return false;
    }
    const elemCenter   = {
        x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
        y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
    };
    if (elemCenter.x < 0) return false;
    if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
    if (elemCenter.y < 0) return false;
    if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;
    let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);
    do {
        if (pointContainer === elem) return true;
        //@ts-ignore
    } while (pointContainer = pointContainer.parentNode);
    return false;
}

function _editorcomment_({
    auto_scroll,
    parent_id,
    parent_user_id, 
    _created, comment_reply,
    type, 
    defaultValue, 
    autoFocus, 
    onLoad,
    addLine,
    isSmall
}) {
    const bodyRef = useRef("");
    const [root,setRoot] = useState<HTMLElement>();
    const {login:{user, isLogin}} = usePeluchan();
    const editorlist = useRef<EditorList>();
    const [editor_id] = useState("Editor_"+Math.floor(Math.random()*100000));
    const [editorAutoFocus, setEditorAutoFocus] = useState(autoFocus)
    const [isLoading, setLoading] = useState(false)

    useEffect(()=>{
        if(  root && editorAutoFocus && root.scrollIntoView && !isVisible(root)){
            root.scrollIntoView(); 
        }
    },[root]);

    useEffect(()=>{
        if(editorAutoFocus) setEditorAutoFocus(false);
    },[editorAutoFocus]);


    const author = user;
    const {_id,username,name} = author? author: window.user404;

    async function Enviar(){
        const _comment_reply = comment_reply?comment_reply:{};

        var _delete:Record<string, any> = {
            tipo: type,

            parent_id: parent_id,
            parent_user_id: parent_user_id,
            comment:{
                body: bodyRef.current,
                type_content: "normal"
            }
        };
        
        if(_comment_reply.comments_root){
            _delete.comment_reply_id = _comment_reply._id;
            _delete.comment_reply_author_id = _comment_reply.author? _comment_reply.author._id:undefined;
        }

        setLoading(true)
        var result = await window.POST(`/api/comment/create`,_delete).finally(()=>{
            setLoading(false)
        })
        
        if(result==null||result.error){
            window.log(`${result.error}`,"red");
            return;
        }

        _created({
            comment: result.data,
            IsReply: false
        });

        if(editorlist.current) editorlist.current.clear();
        window.log("Comentario enviado correctamente","green");

        TagManager.dataLayer({
            dataLayer: {
                ...type=="post"?{
                    postId: parent_id,
                }:{},
                ...type=="shout"?{
                    shoutId: parent_id,
                }:{},
                authorId: parent_user_id,
                commentId: result.data._id,
                event: "comment",
                username: username
            }
        })
    }


    const Editor = useMemo(()=>
    {
        return <EditorList onLoad={onLoad} defaultValue={defaultValue} autoFocus={editorAutoFocus} ref={el=> editorlist.current = el} editor={"tinymce-comment"} onChange={(body)=>{
            bodyRef.current = body;
        }}/>
    }
    ,[defaultValue, editorAutoFocus]); 

    if(!isLogin) return null

    return <article id={editor_id} ref={(d)=>{
            setRoot(d) ;
        }} className={` _comment_ _editor_ _my_`}>
        <CommentPhoto  user={author} small={isSmall} isMe/>
        <div className={cn("content", isSmall?"pl-[calc(40px+.75rem)]":"pl-[calc(50px+.75rem)]")}>
            <CommentHeader user={author} isMe={true}/>
            <div className="body  mt-2">
                <div className="form-group">
                    <textarea placeholder="Agrega un comentario" className="form-control editor_raw"  rows={3} hidden={true}></textarea>
                    {Editor}
                </div>
            </div>
            <div className="footer h-[28px] mt-3 relative w-full">
                <div className="Buttons">
                </div>
                <div className="Buttons-Right absolute right-0" >
                    <Button.withLoading onClick={Enviar} loading={isLoading}>Enviar</Button.withLoading>
                </div>
            </div>
            {addLine && <ConcatY.Line className='w-full h-[1px] mt-3 mb-3' margin='none'/>}
        </div>
    </article>;
}

export default withRequiredLogin(_editorcomment_, {out: <Message className="light_message" margin="30" message="Solo usuarios registrados pueden comentar"></Message>, waitRetry: true});