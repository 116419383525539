import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({color,...props}: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
    <g
        {...props}
        transform={`translate(0 0), scale(.15 .15), rotate(0)`}
        > 
        <g>
	<g>
    <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M446.975,224.252c0,4.596,0,9.192,0,13.789
        c-10.641,13.652-21.421,27.168-19.697,53.185c-38.045,19.083-83.465,30.786-124.1,47.276c-19.918,46.768-140.273,49.153-159.557,0
        c-31.374-2.832-61.053-1.866-70.915-25.607c-3.189-7.681,0.156-17.829-1.969-25.607c-3.455-12.64-14.256-15.691-17.729-27.577
        c0-4.596,0-9.194,0-13.79c-0.59-6.398,13.545-15.109,13.789-15.759c3.835-10.225-1.941-24.402,3.94-35.457
        c11.631-21.863,46.398-17.675,72.883-21.669c20.403-3.075,44.596-15.761,66.975-23.638c18.65-6.565,44.367-23.736,66.974-23.638
        c9.556,0.042,25.778,7.583,37.428,11.819c34.985,12.726,79.513,29.046,106.37,41.367c5.535,3.658,3.746,14.639,3.94,23.638
        C436.388,205.948,442.126,214.655,446.975,224.252z M277.569,141.519c-41.142,7.95-87.3,40.699-131.979,47.276
        c-24.195,3.563-54.842-5.434-63.035,21.669c10.055,21.773,53.905,22.932,80.764,15.758c-3.741,4.796-7.334,9.739-5.91,19.699
        c22.567,26.526,118.68,32.297,126.07-11.819c42.458-14.009,84.661-28.276,124.1-45.306
        C364.245,173.033,320.247,157.938,277.569,141.519z M360.303,251.83c-22.681,8.249-44.022,15.499-63.035,21.669
        c-16.11,48.296-139.567,48.522-155.617,0c-33.163,0.988-57.778-6.57-72.883-23.639c-0.08,19.122,17.65,20.434,33.487,23.639
        c-0.842,7.408,6.161,6.97,7.879,11.818c36.147,7.188,59.911,26.762,96.521,33.487c3.664,7.497,8.227,14.099,17.729,15.759
        c9.813-2.005,10.424-13.214,19.698-15.759c18.948-0.752,32.636-6.763,39.397-19.699c14.391-9.248,31.605-15.67,45.306-25.606
        c0.879,6.343,3.007,11.438,11.818,9.849c6.282-2.255,5.507-11.567,9.851-15.759c12.34-0.793,16.607-9.658,25.607-13.789
        c24.58-1.807,59.795-3.072,53.187-29.547c-2.519-0.109-2.729-2.525-5.91-1.97C413.393,236.521,382.447,243.775,360.303,251.83z" id="path-0" ></path>
    <path fillRule="evenodd" clipRule="evenodd" fill={"#fdb2e2"} d="M407.579,188.795c-39.438,17.03-81.642,31.297-124.1,45.306
        c-7.39,44.117-103.502,38.345-126.07,11.819c-1.424-9.96,2.169-14.902,5.91-19.699c-26.859,7.174-70.708,6.015-80.764-15.758
        c8.193-27.103,38.84-18.106,63.035-21.669c44.678-6.578,90.836-39.327,131.979-47.276
        C320.247,157.938,364.245,173.033,407.579,188.795z" id="path-1" ></path>
    <path fillRule="evenodd" clipRule="evenodd" fill={"#820b10"} d="M423.338,222.282c3.182-0.555,3.392,1.861,5.91,1.97
        c6.608,26.475-28.606,27.741-53.187,29.547c-9,4.131-13.268,12.996-25.607,13.789c-4.344,4.191-3.568,13.504-9.851,15.759
        c-8.812,1.589-10.939-3.506-11.818-9.849c-13.7,9.937-30.915,16.358-45.306,25.606c-6.761,12.937-20.449,18.947-39.397,19.699
        c-9.273,2.545-9.884,13.754-19.698,15.759c-9.502-1.66-14.065-8.262-17.729-15.759c-36.611-6.726-60.374-26.3-96.521-33.487
        c-1.719-4.849-8.722-4.41-7.879-11.818c-15.837-3.205-33.567-4.518-33.487-23.639c15.105,17.069,39.721,24.627,72.883,23.639
        c16.049,48.522,139.506,48.296,155.617,0c19.013-6.17,40.354-13.42,63.035-21.669C382.447,243.775,413.393,236.521,423.338,222.282z
        " id="path-2" ></path>
	</g>
</g>
 </g>
    </>
)

export default ({borde1, backgroundShadow1}: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
    <g transform="translate(-2.2 -2.8), rotate(-5), scale(.3 .4)" >
        <g transform="translate(1.4 1.5)" >
            <Icon color={backgroundShadow1}/>
        </g>
        <Icon color={borde1}/>
    </g>
    </>
)
