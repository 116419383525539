import React, { useMemo, useRef } from "react"
import { refEnv } from "../../contexts/EnvironmentContext"
import { MediaParamTypes } from "../../contexts/EnvironmentContext/useMedia"
import { TempState } from "../utils/LazyState"

type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

type ImagenPros = {
    pId: string,
    pParams?: MediaParamTypes,
    pDefault?: string,
    resetSize?: boolean,
    _ref?: React.LegacyRef<HTMLImageElement>
}


export const ImgDefault404 = {
    Banner: "/resources/img404/notfoun-banner.svg",
    Box: "/resources/img404/notfoun-box.svg",
    nano2: "/resources/img404/notfoun-nano-2.svg",
    nano: "/resources/img404/notfoun-nano.svg",
    shouts: "/resources/img404/notfoun-shout.svg",
    shoutsTrans: "/resources/img404/notfoun-shout-trans.svg",
    generic: "https://mypay.com.my/image/staff/user.png"
}

const isDebug = localStorage.getItem("imagen-debug") == "true" 
//localStorage.setItem("imagen-debug", "true")

const devUrl = "https://st1.uvnimg.com/dims4/default/a05191b/2147483647/thumbnail/1024x576/quality/75/?url=http%3A%2F%2Fuvn-brightspot.s3.amazonaws.com%2Fe9%2Ff3%2F2eed08897066b71646bb2fa338a4%2Fanime-comunista.png"

const resetImagen = (e,pDefault:string, resetSize: boolean)=>{
    const default_url = pDefault || ImgDefault404.shouts
    if(default_url == e.currentTarget.src) return
    e.currentTarget.src = default_url
    if(resetSize){
        e.currentTarget.style.width = "100%"
        e.currentTarget.style.height = ""
    }
}

const onErrorLocal = (e, redRetrys:{current: number}, props: ImgProps & ImagenPros)=>{
    const {pId, pParams, pDefault, resetSize=false, _ref, onError, ...args} = props

    if(refEnv().tag.is("notRetry")) {
        if(redRetrys.current==0) return
        redRetrys.current=0;
        return resetImagen(e, pDefault, resetSize)
    }

    try {
        if(onError) onError(e)
    } catch (error) {
        console.error(error)
    }

    redRetrys.current--;
    if(redRetrys.current<=0) 
        return resetImagen(e, pDefault, resetSize)

    try {
        const key = encodeURIComponent("img-fail-"+args.src)
        const urlFails:string[] = TempState.get(key) || []
        
        if(isDebug) console.error("Fail", e.currentTarget.src)
        e.currentTarget.setAttribute("data-fail", e.currentTarget.src)
        if(!urlFails.includes(e.currentTarget.src)) TempState.set(key, [...urlFails, e.currentTarget.src])
        const newUrl = refEnv().media(pId, args.src, pParams)

        if(urlFails.includes(newUrl) || newUrl == e.currentTarget.title)
            return resetImagen(e, pDefault, resetSize)
        if(isDebug) e.currentTarget.title+= `\n🔹${urlFails.length+2} - ` +newUrl
        e.currentTarget.src =  newUrl
    } catch (error) {
        // console.error(error)
    }
}

export const Imagen:React.FC<ImgProps & ImagenPros> = React.memo((props)=>{
    const {pId, pParams, pDefault, resetSize=false, _ref, onError, ...args} = props

    const redRetrys = useRef(5)
    const url = useMemo(()=> {
        if(!args.src) return pDefault || ImgDefault404.shouts
        return refEnv().media(pId, args.src, pParams)
    }, [args.src, pId])

    if(isDebug) args.title = pId + "\n🌐"+args.src +  "\n🔹1 - " +url
    return <img ref={_ref} data-pid={pId} {...args} src={url} onError={(e)=> onErrorLocal(e,redRetrys, props )}/>
})