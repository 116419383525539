import { useRef, useEffect } from "react";
import { useRefer } from "../../hooks/useRefer";
import React from "react";

export const ReadClipboardImage = async()=>{
  const items = await navigator.clipboard.read()
  const files = [...items].filter((item:any)=> item.type.startsWith('image/')).map((item:any)=> item.getAsFile());
  return files
}

export const ClipboardImage:React.FC<React.PropsWithChildren & {onDrod: (files:any[])=>void, queryHandlePaste?: string }> = ({children, onDrod, queryHandlePaste}) => {
    //   const [imageSrc, setImageSrc] = useState(null);
      const dropAreaRef = useRef(null);
    
      const refer = useRefer({onFile: onDrod})
    
      useEffect(() => {
        const handlePaste = (event) => {
          const items = [...event.clipboardData.items].filter(item=> item.type.startsWith('image/')).map(item=> item.getAsFile());
          if(items.length > 0) refer.current.onFile(items)
    
        //   for (let item of items) {
        //     if (item.type.startsWith('image/')) {
        //         // Convertir a base64
        //         // const file = item.getAsFile();
        //         // const reader = new FileReader();
        //         // reader.onload = function (event) {
        //         //   setImageSrc(event.target.result);
        //         //   refer.current.onFile(event.target.result)
        //         // };
        //         // reader.readAsDataURL(file);
        //     }
        //   }
        };
        
        if(queryHandlePaste){
          document.querySelector(queryHandlePaste)?.addEventListener('paste', handlePaste);
          return () => document.querySelector(queryHandlePaste)?.removeEventListener('paste', handlePaste);
        }

        document.addEventListener('paste', handlePaste);
        return () => document.removeEventListener('paste', handlePaste);
      }, []);
    
      const handleDragOver = (event) => {
        event.preventDefault();
        dropAreaRef.current.classList.remove('hidden');
      };
    
      const handleDragLeave = () => {
        dropAreaRef.current.classList.add('hidden');
      };
    
      const handleDrop = (event) => {
        event.preventDefault();
        dropAreaRef.current.classList.add('hidden');
        const items = [...event.dataTransfer.items].filter(item=> item.kind === 'file' && item.type.startsWith('image/')).map(item=> item.getAsFile());
        refer.current.onFile(items)
      };
    
      return (
        <div className="clipboard-image-container relative"
            onDragEnter={handleDragOver}
        >
            {children}
          <div
            ref={dropAreaRef}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className="absolute drop-area top-0 left-0 bottom-0 right-0 bg-black/75 justify-center items-center hidden"
            
          >
            Arrastra y suelta una imagen aquí o presiona Ctrl+V
          </div>
        </div>
      );
    };
    