
import { RequestProvider } from "react-request-hook";
import axios from 'axios';
import React from "react";
import { HOST } from "../../api";

const axiosInstance = axios.create({
    baseURL: `${HOST}/api/`,
});

export const LibRequestProvider:React.FC<React.PropsWithChildren> = ({children})=>{
    return (
        <RequestProvider value={axiosInstance}>
            {children}
        </RequestProvider>
    )
}