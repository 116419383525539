import cn from 'classnames';
import React, { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Title } from './Title';
import { PanelStyles } from './Panel';
import { TextTooltip } from '../../mui/Tooltip';
import { PickTailwindSizeKeys } from '../utils/tailwindsize';

type NavItemProps = {
    key: string,
    name:string,
    to?: string | string[],
    show?: boolean,
    jsx?: JSX.Element
    className?: string,
    click?: (e: React.MouseEvent<any, MouseEvent>)=> void, 
    active?: boolean, 
    skipScroll?: boolean
}

const styles = {
    border: "border-[#00adff] text-[#00adff] dark:border-[#57a8ff] dark:text-[#77b8fd]",
}


const NavItem:React.FC<NavItemProps> = (e)=>{
    const {name="...",to="",show=true,className="",click=null, active, jsx=null, skipScroll=false} = e || {};

    const navStyles = {
        default: "pb-2 pt-[0.5em] px-3 text-nowrap",
        active: "border-0 border-b-[1px]  mb-[-1px] border-solid "+styles.border,
        inactive: "border-inherit text-gray-400 dark:text-inherit",
    }

    if(!show) return null;
    return (
        <li className={`block p-0 m-0 ${className} `}>
            {  jsx?jsx:( 
                to? <Link to={Array.isArray(e.to)?e.to[0]:e.to} state={{skipScroll}} className={cn(navStyles.default, active?navStyles.active: navStyles.inactive, "block")} onClick={(e)=> {
                    if(click) click(e)
                }}>{name}</Link>: <Title className={cn(navStyles.default,  active?navStyles.active: navStyles.inactive)} onClick={(e)=> {
                    if(click) click(e)
                }}>{name}</Title>
            )}
        </li>
    );
}



const Element:React.FC<{
    children: (NavItemProps & {left?: boolean})[],
    center?:boolean,
    skipScroll?: boolean,
    className?: string,
    classNameItem?: string,
    classNameRight?: string,
    classNameLeft?: string,
    listenLocation?: boolean
}> = React.memo(({center, children: setting, className, skipScroll, listenLocation, classNameItem, classNameRight, classNameLeft})=>{
    listenLocation && useLocation()

    const fun = (e:NavItemProps, idx:number)=>{
        const isPathEqual = Array.isArray(e.to)? e.to.some(to=> window.location.origin + to === window.location.href) :window.location.origin + e.to === window.location.href
        var isActive = isPathEqual || (e.active !== false && setting.length == 1) || e.active==true;
        return <NavItem key={e.key || idx} skipScroll={skipScroll} {...e} active={isActive}  className={cn(e.className, classNameItem)}/>;
    }
    let titlesRight = setting.filter((i)=> !i.left).map(fun)
    let titlesLeft = setting.filter((i)=> i.left).map(fun)

    const styles = {
        border: PanelStyles.borderLine
    }

    return <ul className={cn(`list-none flex mb-4 `, center?"justify-content-center":"justify-between", className, styles.border)} >
        <div className={cn('flex items-center flex-wrap', classNameRight)}>
            {titlesRight}
        </div>
        <div className={cn('flex items-center flex-wrap', classNameLeft)}>
            {titlesLeft}
        </div>
    </ul>
})

const navSizeStyle:PickTailwindSizeKeys<"base" | "lg" | "xl"> = {
    base: "m-1.5",
    lg: "m-3",
    xl: "m-3",
}

export const ButtonRounted:React.FC<JSX.IntrinsicElements["button"] &{materialIcon:string, noTooltip?:boolean, size?: keyof typeof navSizeStyle }> = ({className,noTooltip,title, materialIcon, size="base", ...args})=>{
    const Content = <button {...args} className={cn('bg-transparent border-0 outline-none hover:bg-gray-800 active:bg-slate-700 flex justify-center items-center text-[#4c7996]', className)}>
        <span className={cn("material-icons-outlined text text-inherit", navSizeStyle[size])}>{materialIcon}</span>
    </button> 

    if(noTooltip) return Content

    return <TextTooltip title={title} enterDelay={500}>
        {Content}
    </TextTooltip>
}

export const NavList = Object.assign(Element, {
    ButtonRounted
})