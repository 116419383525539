import React from "react"
import { ScrollByLastId } from "./utils/ByLastId"
import { FindComments, FindCommentsParams } from "../../../hooks/apis/useComments"
import { ScrollingProps } from "../../../components/utils/Scrolling"
import { FindPosts, IPost } from "../../../hooks/apis/usePost"
import { PlaceholderPosts } from "./utils/PlaceholderPosts"
import { ItemPost } from "../Post/components/ItemPost"
import { ConcatY } from "../../../components/styleguide/components/Concat"
import cn from "classnames"
import { LazyView } from "../../../components/utils/LazyView"
import { Memo } from "../../../components/utils/Memo"
import { GroupArray } from "../../../utils/GroupArray"
import { ItemPostReco } from "../Post/components/ItemPostReco"
import { PostMetasProps } from "../Post/components/metas"
import { ScrollByNextId } from "./utils/ByNextId"


export const ScrollPostStyles = (count: number):Omit<ScrollingProps<any>, "_key" | "fetcher"|"children"|"id" | "initialSetting">=>  ({
    btnRetryClass: "mt-4",
    loadingComponent: <PlaceholderPosts count={count}/>,
    loadingMoreComponent: <PlaceholderPosts count={3} className="mt-3"/>
})

export type ScrollPostProps = Omit<React.ComponentProps<typeof ScrollByLastId>, "fetcher" | "children"| "initialSetting">  &  {
    children: (items: IPost[])=> JSX.Element,
    initialSetting: Parameters<typeof FindPosts>[0]
}
const Element:React.FC<ScrollPostProps> = ({
    initialSetting,
    ...args
})=>{
    
    return <ScrollByNextId 
        {...args}

        initialSetting={{
            __pageSize: initialSetting.count,
            __nextId: initialSetting.nextId,
            ...initialSetting
        }} 

        fetcher={(params)=>{
            return FindPosts({
                count: params.__pageSize,
                nextId: params.__nextId,
                ...initialSetting
            }).then((data)=>{
                if(data===undefined || data.error) throw new Error(data.error)
                return {
                    newItems: data.data.list,
                    hasMore: !data.data.isEnd,
                    nextId: data.data.nextId
                }
            })
        }} 
    />
}

export type ScrollPostNoneProps = {
    /**
     * La imagen de portada es mas pequeña.
     *
     * @type {boolean}
     */
    small?:boolean, 
    /**
     * El modo mobile acorta el nombre y la categoria para ocupar menos espacio.
     *
     * @type {boolean}
     */
    mobile?: 
    boolean
}
const StyleNone = (props: Omit<ScrollPostProps,"children"> & ScrollPostNoneProps)=>  (
    <Element 
        {...props}
        btnRetryClass={"mt-4"}
        loadingComponent={<PlaceholderPosts count={props.initialSetting.count} itemProps={{small: props.small}}/>}
        loadingMoreComponent={<PlaceholderPosts count={4} className="mt-3" itemProps={{small: props.small}}/>}
    >
        {(items)=> (
            <ConcatY {...props} className={cn({"_mini": props.small}, "space-y-4")}>
                {GroupArray(items,8).map((gpost:any[])=> {
                    const eid = gpost.map(i=> i._id).join()
                    return <Memo key={eid} debs={[eid]}>
                        {()=>
                            <LazyView id={eid} className="flex flex-col space-y-4" observer={{rootMargin: "700px 0px 700px 0px", delay: 250, }}>
                                {gpost.map(post=>(
                                    <ItemPost key={post._id} post={post} mini={props.small} metas={{
                                        large: !props.mobile
                                      }}></ItemPost>
                                ))} 
                            </LazyView>
                        }
                    </Memo>
                })}
            </ConcatY> 
        )}
    </Element>
)

const StyleRecomendados = (items, {className,...props}: Omit<React.ComponentProps<typeof ConcatY["withLine"]>,"children">={})=>  (
    <ConcatY {...props} className={cn(className)}>
        {items.map(post=> {
            post.author = "-"
            return <Memo key={post._id} debs={[post._id]}>
                {()=> <ItemPostReco key={post._id} post={post}></ItemPostReco>}
            </Memo>
        }
        )}
    </ConcatY> 
)

export const ScrollPost = Object.assign(Element,{
    StyleNone,
    StyleRecomendados
})