import React from "react";
import ItemShout, { ShoutOfLink } from "../../../../pages/components/Shout/item-shout";
import { PItemProps } from "./PMain";
import { IShout } from "../../../../hooks/apis/usePost";


export type PShoutProps = PItemProps<IShout, {}>;
export default ({content, ...setting}:PShoutProps)=>{
    return (<div className="media-shout">
        <ShoutOfLink shout={content}></ShoutOfLink>
    </div>);
}
