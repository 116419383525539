import React,{ createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueryParam } from "use-query-params";

const ShoutTag = createContext<ReturnType<typeof BUILD>>({} as any);

function BUILD() {
    const [tag, setTag] = useQueryParam<string | undefined>("tag");

    return {
        tag,
        setTag: (...args: Parameters<typeof setTag>)=>{
            const r = setTag(...args)
            setTimeout(()=>{
                scrollTo({top:0, behavior: "auto"})
            }, 100)
            return r
        }
    };
}
  
export const ShoutTagProvider:React.FC<React.PropsWithChildren> = ({children})=>{
    const _private = BUILD();


    return <ShoutTag.Provider value={_private}>
        {useMemo(()=> children, [])}
    </ShoutTag.Provider>;
}

export const useShoutTags = ()=>{
    return useContext(ShoutTag);
};