import "./contexts/PeluchanContext";


declare global {
    interface Window {
        buildUrl: (typeof _WINDOW)["buildUrl"]
        dev: (typeof _WINDOW)["dev"]
        stg: any
        NodeEnv: any
        user404: (typeof _WINDOW)["user404"]
        POST: (typeof _WINDOW)["POST"]
        GET: (typeof _WINDOW)["GET"]
        GET2: (typeof _WINDOW)["GET2"]
        RPOST: (typeof _WINDOW)["RPOST"]
        api: (typeof _WINDOW)["api"]
    }
}

var host = window.location.origin.includes(":3000")? "http://192.168.0.9:8989": window.location.origin;
host = host.replace(":2082",":2052");

if(window.localStorage.getItem("sv_debug")){
    host = "https://www.peluchan.net"
}

export const HOST = host;

const _WINDOW = {
    buildUrl: (path: string)=>{
        return host + path;
    },

    dev:(b:boolean)=>{
        if(b) window.localStorage.setItem("sv_debug","active");
        else window.localStorage.removeItem("sv_debug");
    },

    user404: {
        photo:"https://i.imgur.com/eRKJZSt.jpg",
        name:"404",
        username:"",
        _id:"404",
        meta_puntos: 0
    },

    POST: <T=any>(path:string, body?:any ,settings?:settingType)=>{
        const host_api = host + path;

        var myHeaders = new Headers({...settings?.headers?settings.headers:{}});
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(body);

        return SettingResquest({requestPath: host_api, requestOptions: {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }},settings) as Promise<T> ;
    },

    GET: <T=any>(path:string, settings?: settingType)=>{
        const host_api = host + path;

        var myHeaders = new Headers();

        return SettingResquest({requestPath: host_api, requestOptions: {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }},settings) as Promise<T>;
    },

    GET2: <T=any, P=unknown>(path: string, body?:P, settings?:settingType)=>{
        const s = serialize(body);
        const host_api = host + path + (s?`?${s}`:``);

        var myHeaders = new Headers();

        return SettingResquest({requestPath: host_api, requestOptions: {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }}, settings) as Promise<T>;
    },

    RPOST: async (path:string, body?:unknown,settings?:(settingType & {color:string})) => {
        var {data,error} = await window.POST(path,body, settings);
        if(error || (!data && !error)){
            if(!data && !error){
                window.log("Servidor no responde","red");
                return false;
            }
            window.log(error,"red");
            return false;
        }
        const {report="", reportColor, reportStatus=true} = data;
        if(report){
            window.log(report,{className: reportColor || settings?.color || "green", autoHideDuration: 5000});
            return reportStatus as boolean;
        }
        window.log("Se produjo un error");
        return undefined
    },

    api: {
        outlogin: async ()=>{
            return await window.POST("/api/user/outlogin");;
        }
    }
}

Object.assign(window, _WINDOW)

export function serialize (obj:any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        if(obj[p] === undefined) continue;
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}

export function encode_query(path:string, body:any) {
    const s = serialize(body);
    return path + (s?`?${s}`:``);
}

type settingType = Parameters<typeof SettingResquest>[1]
function SettingResquest({requestPath, requestOptions}:{requestPath: string, requestOptions: Parameters<typeof fetch>[1]},{
    fast = true,
}:{
    fast?: boolean,
    headers?: Exclude<typeof requestOptions, undefined>["headers"]
}={}) {
    if(fast){
        return new Promise((c,e)=>{
            fetch(requestPath, requestOptions)
            .then(response => {
                return new Promise(c=>{
                    response.text().then((r)=> c({text: r, response}))
                }) as Promise<{text: string, response: typeof response }>
            })
            .then(({text, response}) => {
                const contentType = response.headers.get("Content-Type")
                if(contentType.includes("application/json;")){
                    c(JSON.parse(text))
                }
                if(contentType.includes("text/html;")){
                    c(text)
                }
                e(new Error("Servidor no responde(unknown)"))
            })
            .catch(error => c({error}));
        });
    }
    return new Promise((c,e)=>{
        fetch(requestPath, requestOptions)
        .then(async response => {
            let body = undefined;
            let jsonError = false;

            try {
                body = JSON.parse(await response.text());
            } catch (error) {
                body = undefined;
                jsonError = true;
            }

            c({
                result: body,
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                jsonError
            })
        })
        .catch(error => e(error));
    });
}

export type FullResponse<T> = {
    result: T,
    status: Response["status"],
    statusText: Response["statusText"],
    url: Response["url"],
    jsonError: boolean,
}

// class Status{
//     my(id){
//         return refPeluchan().login.my(id);
//     }
//     get login(){
//         return refPeluchan().isLogin;
//     }
//     get normal(){
//         return refPeluchan().login.user?.priv<=200;
//     }
//     get mod(){
//         return refPeluchan().login.user?.priv>=700;
//     }
//     get admin(){
//         return refPeluchan().login.user?.priv>=900;
//     }
//     get dios(){
//         return refPeluchan().login.user?.priv==1000;
//     }
//     stg(key){
//         return stg && stg.includes(key);
//     }
//     set _stg(v){
//         stg = v;
//     }
//     NodeEnv(key){
//         if(!NodeEnv) NodeEnv = {};
//         return NodeEnv[key];
//     }
//     _NodeEnv(v){
//         NodeEnv = v;
//     }
// }

// window.info = new Status();
