import React, { useEffect } from "react";
import { useEnv } from ".";
import type * as Poll from "../../../../../node_ts/src/routers/poll.route";
import type * as Sticky from "../../../../../node_ts/src/routers/sticky.route";
import { useRest, useLazyRest } from "snresteasy";
import { HOST } from "../../api";
import swr from "swr/immutable";
export type PlantillaType = {
    poll: (typeof Poll)["PollRest"]["restTypes"]
    sticky: (typeof Sticky)["StickyRest"]["restTypes"]
}

export const usePlantilla = (raw:PlantillaType)=>{
    const usePoll = useRest(raw?.poll, HOST + "/api/poll/", {swr})
    const LazyPoll = useLazyRest(raw?.poll, HOST + "/api/poll/")
    const useSticky = useRest(raw?.sticky, HOST + "/api/sticky/", {swr})
    const LazySticky = useLazyRest(raw?.sticky, HOST + "/api/sticky/")
    
    useEffect(()=>{
        if(!raw && window.location.href.includes("localhost")) window.log(`ApiDebug = ON (${typeof raw})`)
    },[raw])
    
    return {
        contexts:{
            api:{
                usePoll,
                LazyPoll,
                useSticky,
                LazySticky
            }
        }
    }
}

export type TagIsProps = {
    id: string
}
export const TagIs:React.FC<React.PropsWithChildren<TagIsProps>> = ({children, id})=>{
    const {tag:{is}} = useEnv();

    if(is(id)) return <>{children}</>;
    
    return null;
}