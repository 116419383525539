import React from "react";
import { PlantillaIconType } from "../../types";
import { MedallaIcon } from "./plantillas/MedallaIcon";
import { MedallaIconWithNivel } from "./plantillas/MedallaIconWithNivel";


export const PlantillaIcon:React.FC<React.PropsWithChildren<PlantillaIconType>> = ({plantilla=null, children, ...args})=>{
    if(plantilla === "MedallaIconWithNivel") return <MedallaIconWithNivel {...args as any}/>
    if(plantilla === "MedallaIcon") return <MedallaIcon {...args as any}/>
    return null;
}