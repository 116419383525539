import { useMemo } from "react"
import { LazyState, GlobalState, LocalState } from "../components/utils/LazyState"
import { refHistory } from "./useHistory"
import { useNavigate,useLocation, NavigationType, useNavigationType } from "react-router-dom";

export const useConstKey = (name:string, ttl=60*60)=>{
    const stateKey = LazyState.getStateKey()
    const constKey = useMemo(()=>{
        const key = `constkey-${name}`
        let constKey = LazyState.get(key)
        if(constKey) return constKey
        const globalState = LocalState.get(key)
        const globalKey = [NavigationType.Pop].includes(refHistory().navigateType)? undefined: globalState
        const newKey =  globalKey || stateKey
        if(!globalKey || !globalState) LocalState.set(key, newKey, ttl)
        LazyState.set(key, newKey)
        return newKey
    }, [name, stateKey])
    return constKey
}