import React from 'react';

function Icon({name}) {
    if(name === "like"){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/><path d="M21 8h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2zm0 4l-3 7H9V9l4.34-4.34L12.23 10H21v2zM1 9h4v12H1z"/></svg>);
    }
    if(name === "dislike"){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/><path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.58-6.59c.37-.36.59-.86.59-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L11.77 14H3v-2l3-7h9v10zm4-12h4v12h-4z"/></svg>);
    }
    if(name === "reply"){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"/></svg>);
    }
    if(name === "comment"){
        return (<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22 2H2v16h16l4 4z"/></svg>);
    }
    if(name === "more"){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/></svg>);
    }
    if(name === "shared"){
        return (<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z"/></svg>)
    }
    return null;
}

function Base({children, name,className, click, title}) {
    var div = null;
    var cl = (e)=>{
        if(click) click(div)
    };
    return (
        <div ref={(e)=>{div = e;}} onClick={cl} className={`BtnFoo ${name} ${className?className:""}`} title={title}>
            {children}
        </div>
    );
}

function get({children=undefined, name="", className="", Click=undefined, title=undefined}){
    return (
        <Base name={name} className={className} click={Click} title={title}>
            <div className="padre">
                <div className="hijo">
                    <div className="_r">
                        <Icon name={name}></Icon>
                    </div>
                </div>
                {
                    children!==undefined?(
                        <div className="hijo">
                            <div className="_l">
                                <span className={children?"nocero":""}>{children}</span>
                            </div>
                        </div>
                    ):null
                }
            </div>
        </Base>
    );
}

export default get;
