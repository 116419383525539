import React, { Fragment } from "react";
import { PImg } from "../../../../../../../contexts/EnvironmentContext/useMedia";
import { useCssHandles } from "../../../../../../../hooks/useCssHandles";
import "./index.scss";

export const Event_navidad:React.FC = ()=>{
    const styles = useCssHandles("logo-event-navidad",[
        'gorro',
        'regalo'
    ] as const);

    return <Fragment>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.gorro} className={styles.gorro} alt="gorro" src="/resources/logos/events/navidad/gorro.png"/>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.regalo} className={styles.regalo} alt="regalo" src="/resources/logos/events/navidad/regalo1.png"/>
    </Fragment>
}