import React from 'react';


class _remoteUpdate_ extends React.Component {
    constructor(){
        super();
    }
    componentWillMount(){
        const {sleep=false, _key} = this.props;
        if(sleep) this.sleep = true;
    }
    componentDidMount(){
        const {_key} = this.props;
        const _this = this;

        if(!window.forceUpdate) window.forceUpdate = {
            run: function(key){
                if(window.forceUpdate && window.forceUpdate[key]){
                    const args = [];
                    const _args = [...arguments];
                    for (let index = 1; index < _args.length; index++) {
                        args.push(_args[index]);
                    }
                    window.forceUpdate[key](...args);
                }
            }
        };
        window.forceUpdate[_key] =  function (){
            _this.sleep = false;
            _this.parse = arguments;
            _this.forceUpdate();
        }
    }
    componentWillUnmount(){
        const {_key} = this.props;
        delete window.forceUpdate[_key];
    }

    render(){
        const {content,children} = this.props;
        if(this.sleep){
            return null;
        }
        if(!content){
            return children;
        }
        const {parse=[]} = this;
        return content(...parse);
    }
}

export default _remoteUpdate_;