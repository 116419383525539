import { SWRResponse } from "swr";
import useSWR from 'swr/immutable'
import { ApiType } from "./useApi";
import { serialize } from "../../api";
import { IShout, SearchShoutResponse } from "./usePost";
import { useSWP } from "./useSWP";

let __unique = 0
const fetcher = (url) => fetch(window.buildUrl(url)).then((res) => res.json()).then(i=> {
  __unique++;
  if(i.data) i.data._unique = __unique
  return i
});

export const ReturnSWR = <T = never, S = never>(args: SWRResponse<ApiType<T>& {_unique?: string}, S>) => {
  const { data: { data, error: errorA, _unique } = {}, error: errorB, mutate } = args;
  const error = errorA || errorB;
  return {
    data,
    mutate,
    _unique,
    isLoading: !error && !data,
    isError: error,
  };
};

type ShoutParams = {
  shout_id: string;
  see: boolean;
  filter: string;
}
export const useShout = (args: Partial<ShoutParams>, deps: any[] = []) => {
  return useSWP<
      {
        previews: any[];
        shout: IShout;
        _unique: string
      }>([`/api/shout/get?${serialize(args)}`, ...deps])
};




export enum LimitShout{
  Create = "create_shout",
  Dislike = "dislike_shout"
}
export enum IntervalShout{
  All = "all",
  Recientes = "recent",
  Hoy = "today",
  Ultimas48hs = "last-48h",
  Ultimas24hs = "last-12h",
  Ultimas12hs = "last-24h",
  Ultimas06hs = "last-6h",
  Ultimas03hs = "last-3h",
  Ultimas01hs = "last-1h",
  Semana = "week",
  AnteriorSemana = "previous-week",
  Ultimos7days = "last-7days",
  UltimaSemana = "last-week",
  Ultimos30days = "last-30days",
  Mes = "month"
}
export enum SoftShout{ 
  Relevance = "relevance",
  RelevanceV2 = "relevance_v2",
  RelevanceV4 = "relevance_v4",
  Recientes = "date_news",
  PorUltimoComentario = "last_comment",
  LosMasAntiguos = "date_olds",
  None = "none"
}

export type SearchShout = {
  q?: string
  tag?: string
  random?: string
  interval?: IntervalShout
  category?: string
  group?: string
  soft?: SoftShout
  user_id?: string
  count: number
  lastid?: string
  nextId?: string
  see_relevance?: boolean
  multiply?: number
  gl?: string
  skip?: number
  uff?: string
  aloneFallows?: boolean,
  filter?: string
  field_portada?: boolean;
  field_body?: boolean;
  populate?: boolean;
}

export const FindShouts = (params: SearchShout)=>{
  return window.GET2<ApiType<SearchShoutResponse>>("/api/shout/search", params)
}