import React, { useRef } from 'react';
import {EditorShoutData, EditorShoutV2, SendShoutData} from "../../pages/components/Shout/components/Editor/editor-shout";
import SR from "../utils/resources";
import RemoteUpdate from "../utils/remoteupdate";
import { useMemoryShoutEditor } from '../desktop/header/components/ProfileMenu';

const SendShout:React.FC<{
    defauld_setting: any,
    defauld: EditorShoutData,
    memory?: boolean,
    onSend: (r:any)=>void
}> = ({defauld_setting, defauld, memory = true, onSend})=>{
    const refButton = useRef<HTMLButtonElement>();
    const {value, clear, onChange} = useMemoryShoutEditor(defauld, {memory});
    
    return (
        <div className="modal fade show"  role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{`Crear shout`}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{
                            (window as any).popup.close();
                        }}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <EditorShoutV2 
                            onChange={onChange}
                            value={value}
                            defauld_setting={defauld_setting}
                            footerRight={(
                                <div className="flex items-center">
                                    {value && <button title="Limpiar datos" className="shout-clears pa2 " onClick={()=> clear()}>{SR._icons._close3({size: 14})}</button>}
                                </div>
                            )}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{
                            (window as any).popup.close();
                        }}>Cancelar</button>
                        <button ref={refButton as any} type="button" className="btn btn-primary" onClick={async (e)=>{
                            if(!e || !value) return;
                            if(refButton.current) {
                                refButton.current.setAttribute("disabled","1");
                                (window as any).forceUpdate.awjhgowagoiawga(true);
                            }
                            const result = await SendShoutData(value);
                            if(result===true){
                                (window as any).popup.close();
                                if(onSend) onSend(result);
                                clear();
                            }
                            else if(refButton.current){
                                refButton.current.removeAttribute("disabled");
                                (window as any).forceUpdate.awjhgowagoiawga(false);
                            }
                        }}><RemoteUpdate _key="awjhgowagoiawga" content={(loading = false)=>(
                                !loading?"Enviar":SR._icons._loading_dual_ring
                            )}/></button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SendShout