import React, { Fragment } from "react";
import { PImg } from "../../../../../../../contexts/EnvironmentContext/useMedia";
import { useCssHandles } from "../../../../../../../hooks/useCssHandles";
import "./index.scss";

export const Event_trabajador:React.FC = ()=>{
    const styles = useCssHandles("logo-event-trabajador",[
        'corbata',
        'casco',
        'maletin'
    ] as const);

    return <Fragment>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.corbata} className={styles.corbata} alt="corbata" src="/resources/logos/events/trabajador/corbata.png"/>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.casco} className={styles.casco} alt="casco" src="/resources/logos/events/trabajador/casco.png"/>
        <PImg cdn={{size: {width: 21, height: 13}}} refId={styles.maletin} className={styles.maletin} alt="maletin" src="/resources/logos/events/trabajador/maletin.png"/>
    </Fragment>
}