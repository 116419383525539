import React from "react";
import { Link } from "react-router-dom";

type ItemProps = {
    text: JSX.Element | string
    to?: string,
    style?: keyof typeof botonStyles
}

const botonStyles = {
  default: "font-[700] px-[15px] py-0 font-[sans-serif] opacity-80 text-[#444852] dark:text-[#daedff] flex items-center rounded-lg min-h-[36px] hover:bg-[#151a2722] dark:hover:bg-[#151a27] dark:hover:text-white ",
  primary: `
  font-[700] px-[15px] py-0 font-[sans-serif] opacity-80 text-[#daedff] flex items-center rounded-lg min-h-[36px] bg-slate-900 hover:bg-slate-900 hover:text-white
  `,
  icon: "font-[700] font-[sans-serif] opacity-80 text-[#444852] dark:text-[#daedff] flex items-center rounded-lg min-h-[36px] hover:bg-[#151a2722] dark:hover:bg-[#151a27] dark:hover:text-white ",
}

export const Item:React.FC<ItemProps & {className?: string}> = ({text, to, style = "default", className=""})=>{
    if(to) return <Link className={botonStyles[style]+" "+className} to={to} >{text}</Link>
    
    return <span className={botonStyles[style]+" "+className}>{text}</span>;
}
