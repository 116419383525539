import React from "react";
import { refPeluchan, usePeluchan } from "../../../contexts/PeluchanContext";
import SR from "../../../components/utils/resources";
import Tooltip from "../../../components/utils/tooltip";
import SendDenuncia from '../../../components/modal/senddenuncia';
import { refEnv } from "../../../contexts/EnvironmentContext";
import { ShowResteasyError } from "../../../components/polls/PollCreator";
import { SendSticky } from "../../../components/modal/sendsticky";
import SendDelete from "../../../components/modal/senddelete";
import { IPost } from "../../../hooks/apis/usePost";

async function MTabs(post:IPost,key:string,active=false){
    const {_id=""}=post || {};
    var {data,error} = await window.POST(`/api/post/mtag`,{
        post_id: _id,
        key: key,
        active: active
    });
    if(error || (!data && !error)){
        if(!data && !error){
            window.log("Servidor no responde","red");
            return false;
        }
        window.log(error,"red");
        return false;
    }
    const {report=""} = data;
    if(report){
        window.log(report,"green");
        if(!post.mtags) post.mtags = [];
        if(active) post.mtags.push(key);
        else{
            for (let index = 0; index < post.mtags.length; index++) {
                if(post.mtags[index] === key) post.mtags.splice(index,1);
            }
        }
        return true;
    }
    window.log("Se produjo un error");
}

export function PostOptions({post, className, forceUpdate, preview=false, IsFav= false, IsSticky=false, DeleteMe}:{
    post: IPost,
    className?: string,
    forceUpdate?: ()=>void,
    preview?: boolean,
    IsFav?: boolean,
    IsSticky?: boolean,
    DeleteMe?: ()=>void
}){
    const {login: {isLogin, user={priv: 0, username: undefined},rol:{ mod }, my}} = usePeluchan();
    const {author,_id,slug, mtags = []} = post;
    const IsMy = my(author? author._id : "");


    const _noveo_ = mtags.includes("noveo")?true:false;
    const _blur_ = mtags.includes("blur")?true:false;
    const _noneed_ = mtags.includes("noneed")?true:false;

    var PFavorite = ()=>{
        const delete_favs = post.fav || IsFav;
        return {
            text: delete_favs? `Quitar`:`Guardar`,
            show: isLogin ,
            click: async ()=>{
                window.RPOST(`/api/fav/${delete_favs?`del`:`add`}`,{_id: post._id, type: "Post"});
                if(delete_favs && DeleteMe) DeleteMe();
            },
            icon: SR._icons._favorite_list4({size: 18})
        };
    }

    return (
        <Tooltip className={className?className:""} offset={[-4,8]} placement={"left"} title={post.title}>
        {
            [
                PFavorite()
                ,{
                text:"Editar",
                show: isLogin && (IsMy || user.priv > 300),
                to:`/post/${_id}/${slug}/editar`,
                icon: SR._icons._editar
            },{
                text:`Resetear miniatura`,
                show: isLogin &&  isLogin &&  ( user.priv >= 300),
                click: async ()=>{window.RPOST("/api/post/resetminiatura",{
                    post_id: post._id
                }) ;},
                icon: SR._icons._mtags_disabled
            },{
                text:`${!IsSticky?"Añadir a":"Quitar"} Sticky`,
                show: isLogin &&  isLogin &&  ( user.priv >= 300),
                click: async ()=> {
                    if(IsSticky) return (refEnv() as any).api.LazySticky.remove({_id: post._id}).then((s)=>{
                        if(s) {
                            window.log("Sticky removido!","green")
                            if(DeleteMe) DeleteMe()
                        }
                        else window.log("Algo fallo al remover el Sticky!","red")
                    }).catch((e)=> ShowResteasyError(e))
                    else (window as any).popup.load( <SendSticky post={post}/> )
                },
                icon: IsSticky?SR._icons._mtags_enabled:SR._icons._mtags_disabled
            },{
                text:"Mover a Uff",
                show: isLogin &&  (!IsMy && user.priv >= 300),
                click: ()=>{MoverAUff(post)},
                icon: SR._icons._mover_uff
            },{
                text:"Eliminar", 
                show: isLogin &&  (IsMy|| user.priv > 300),
                click: ()=>{Eliminar(post)},
                icon: SR._icons._delete
            },{
                text:"Denunciar",
                show: isLogin && !IsMy && !preview,
                click: ()=>{Denunciar(post)},
                icon: SR._icons._post_denunciar2
            },{
                text:"Eliminar x motivo",
                show: isLogin && !IsMy && isLogin && mod,
                click: ()=>{
                    window.popup.load(
                        <SendDelete 
                            title={`Eliminar el post de @${author.username}`} 
                            preview={(text)=>
                                <>
                                    Se elimino el post <a href="#">@</a>{post.title} por <a href="#">@</a>{refPeluchan().login.user.username} {text}
                                </>
                            }
                            _delete={async (msg)=>{
                                Eliminar(post, {porque: msg})
                            }}
                        /> 
                    );
                },
                icon: SR._icons._delete_motivo
            }]
        }
    </Tooltip>
    );
}

async function MoverAUff(post:IPost){
    const {
        _id, 
        //author
    } = post;
    
    const {
        error,
        //data
    } = await window.POST("/api/post/update",{
        post_id: _id,
        post:{
            categoryByCode: "uff"
        }
    });

    if(error){
        window.log(error,"red");
        return;
    }
    
    window.log("El post se movio a uff correctamente","purpure");

}

async function Eliminar(post:IPost, {force=false, porque=undefined}:{force?:boolean, porque?: string}={}){
    const {_id, author} = post;
    
    const {
        error,
        //data
    } = await window.POST("/api/post/delete?post_id",{
        post_id: _id,
        user_id: author? author._id: undefined,
        force_delete: force,
        ...porque?{porque}:{}
    });

    if(error){
        window.log(error,"red");
        return;
    }
    
    window.log("Se elimino correctamente","purpure");
    
}

function Denunciar(post:IPost){
    (window as any).popup.load(
        (
            <>
                <SendDenuncia parent={post} parent_type={"Post"}/> 
            </>
        )
    );
}
