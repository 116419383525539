import React, { useRef, useLayoutEffect } from "react";

export const TextShoutArea:React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({onChange,...args})=>{
    const ref = useRef<HTMLTextAreaElement>();

    const updateSize = () => {
        const lines = args.value.toString().split("\n").length
        const size = parseInt(window.getComputedStyle(ref.current).fontSize, 10);
        ref.current.style.minHeight = (((size*1.500) * lines)+16)+"px";
    };

    useLayoutEffect(()=> updateSize() )


    return  <textarea
        ref={ref}
        {...args}
        onChange={(e)=>{
            onChange(e)
            updateSize()
        }}
    />
}