import React from "react"
import { PlaceholderPulse } from "../../../../components/utils/PlaceholderPulse"
import { RepeatNode } from "../../../../components/utils/RepeatChildren"
import cn from "classnames"
import { ConcatY } from "../../../../components/styleguide/components/Concat"

export const PlaceholderNoti:React.FC<{addLine?: boolean, className?:string}> = ({addLine, className})=>{
    return <div>
        <div className={cn("flex items-start", className)}>
            <PlaceholderPulse className={cn("w-[32px] h-[32px]")}/>
            <div className="flex-grow-1 ml-[10px]">
                <PlaceholderPulse.RangeSize className={cn("h-[12px]")} rangeWidth={[200, 300]}/>
                <div className={cn("flex flex-col space-y-2", "mt-2")}>
                    <PlaceholderPulse.RangeSize className={"h-[11px]"} rangeWidth={[140, 260]}/>
                </div>
            </div>
        </div>
        <PlaceholderPulse.RangeSize className={cn("h-[12px] mt-2")} rangeWidth={[100, 150]}/>
    </div>
}

export const PlaceholderNotis:React.FC<{count: number, className?:string, itemProps?: React.ComponentProps<typeof PlaceholderNoti>}> = ({count, className, itemProps})=>{
    return <ConcatY.withLine className={className} line={{margin: "base2"}}>
        {RepeatNode(count, (_, last)=> <PlaceholderNoti addLine={!last} {...itemProps}/>)}
    </ConcatY.withLine>
}