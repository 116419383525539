import React from "react"
import { ScrollByLastId } from "./utils/ByLastId"
import { FindComments, FindCommentsParams } from "../../../hooks/apis/useComments"
import { PlaceholderComment, PlaceholderComments } from "./utils/PlaceholderComments"
import { ScrollingProps } from "../../../components/utils/Scrolling"
import ItemComment from '../Comment/item-comment';

export const ScrollCommentsStyles = (count: number):Omit<ScrollingProps<any>, "_key" | "fetcher"|"children"|"id" | "initialSetting">=>  ({
    btnRetryClass: "mt-4",
    loadingComponent: <PlaceholderComments count={count}/>,
    loadingMoreComponent: <PlaceholderComments count={3} className="mt-3"/>
})

export type ScrollCommentProps = Omit<React.ComponentProps<typeof ScrollByLastId>, "fetcher" | "initialSetting">  &  {
    children?: (items: unknown[])=> JSX.Element,
    initialSetting: FindCommentsParams
}
const Element:React.FC<ScrollCommentProps> = ({
    initialSetting,
    ...args
})=>{
    
    return <ScrollByLastId 
        {...args}

        initialSetting={{
            pageSize: initialSetting.count,
            lastId: initialSetting.lastid,
            ...initialSetting
        }} 

        fetcher={(params)=>{
            return FindComments({
                count: params.pageSize,
                lastid: params.lastId,
                ...initialSetting
            }).then((data)=>{
                if(data===undefined || data.error) throw new Error(data.error)
                return data.data.comments
            })
        }} 
    />
}

const StyleNone = (props: Omit<ScrollCommentProps, "children"> & {small?: boolean})=>{
    return <Element
        {...props}
        btnRetryClass="mt-4"
        loadingComponent={<PlaceholderComments count={props.initialSetting.count} itemProps={{small: props.small}}/>}
        loadingMoreComponent= {<PlaceholderComments count={3} className="mt-3" itemProps={{small: props.small}}/>}
        children={ (items)=>  (
            <>
                { items.map((e:any, index)=>{
                    return (<ItemComment 
                        replys_show={false} 
                        insertado={true} 
                        comment={e} 
                        key={e._id} 
                        isSmall={props.small}
                        addLine={items.length-1 != index}
                    />);
                })}
            </>
        )}
    />
}

export const ScrollComment = Object.assign(Element,{
    StyleNone
})