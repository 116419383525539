class LS{
    static get _setting_notifications(){
        return {
            local: "notis_setting",
            model:{
                notification_sound: {
                    option_grupo: "General",
                    option_name: "Sonido de notificaciones",
                    type: "boolean",
                    $default: true
                }
            }
        };
    }
    static get(config, key){
        const {model, local} = config;
        const raw = localStorage.getItem(local);
        if(raw){
            try {
                const data = JSON.parse(raw);
                return data[key]
            } catch (error) {

            }
        }
        return model[key].$default;
    }
     
}
export default LS;