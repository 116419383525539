import React from 'react';

type MessageProps = {
    title?:string, 
    message?:string | JSX.Element,
    margin?:string|number,
    className?:string
};

const Message = ({title, message,margin,className}:MessageProps)=>{
    return (
        <div className={`componen_message ${className?className:""}`}>
            <div className="conteiner" style={margin?{margin: `${margin}px 0px`}:{}}>{
                    title?
                    <div className="title">{title}</div>:null
                }
                {
                    message?
                    <div className="message">{message}</div>: null
                }
            </div>
        </div>
    );
}

export const MessageWitchIcon:React.FC<{materialIcon: string, message: string, className?: string, classNameIcon?:string}> = ({materialIcon, message, className, classNameIcon})=>{

    return <div className={`componen_message  ${className?className:""}`}>
        <div className='conteiner'>
            <div className="message">
                <span className={"material-icons text-3xl align-top mr-3 "+classNameIcon} >{materialIcon}</span>
                {message}
            </div>
        </div>
    </div>
}

export default Message;