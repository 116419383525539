import React from 'react';
import { Link } from 'react-router-dom'


const yearNow = new Date().getUTCFullYear()
class Footer extends React.Component {

    render(){
        return (
            <footer id="footer" className="h-[calc(50px+2rem)] text-muted text-center text-small py-4">
                <p className="text-gray-500 inline-block font-bold">© {yearNow}  Peluchan - <span title={window.version}>{window.readVersion}</span></p>
                <ul className="flex gap-3 justify-center mt-1">
                    <li className="text-blue-500 inline-block font-bold"><Link to={`/protocolo`}>Protocolo</Link> </li>
                    <li className="text-blue-500 inline-block font-bold"><Link to="/terms-and-conditions">Términos</Link></li>
                    <li className="text-blue-500 inline-block font-bold"><Link to="/info/history">Historial</Link></li>
                </ul>
            </footer>
        );
    }
}


export default Footer;