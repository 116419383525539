import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import MessageSnack from "../../components/mui/Snackbar/MessageSnack";
import ThemeResponsiveSnackbar from "../../components/mui/Snackbar/NotificacionSnack";
import { useEnv } from "../EnvironmentContext";

const HookProvider = () => {
    const { notify } = useEnv();
    const Snackbar = useSnackbar();
    useEffect(() => {
        notify.set(Snackbar);
    }, []);
    return null;
};

export const NotifyProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    return (
        <SnackbarProvider
            autoHideDuration={7500}
            Components={
                {
                    default: MessageSnack,
                    // success: ThemeResponsiveSnackbar,
                    // error: ThemeResponsiveSnackbar,
                    // warning: ThemeResponsiveSnackbar,

                    notify: ThemeResponsiveSnackbar,
                } as any
            }
        >
            <HookProvider />
            {children}
        </SnackbarProvider>
    );
};
