import React, { useContext, useMemo } from "react";
import { useValid } from "./useValid";
import { useSound } from "./useSound";
import { useHost } from "./useHost";

const _PeluchanContext = React.createContext<ReturnType<typeof _PELUCHAN>["_context"]>({} as any);

declare global {
  interface Window {
    peluchan: ReturnType<typeof _PELUCHAN>["_context"];
  }
}

function _PELUCHAN() {
  const valid = useValid();
  const sound = useSound();
  const host = useHost();
  return {
    _context: {
      ...valid.contexts,
      ...sound.contexts,
      ...host.contexts,
    },
    _tsx: (
      <>
        <valid.childrens />
        <sound.childrens />
      </>
    ),
  };
}

export const PeluchanContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _context, _tsx } = _PELUCHAN();
  window.peluchan = _context;
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_PeluchanContext.Provider value={_context}>
      {useMemo(
        () => (
          <>
            {_tsx}
            {children}
          </>
        ),
        []
      )}
    </_PeluchanContext.Provider>
  );
};

export function usePeluchan() {
  return useContext(_PeluchanContext);
}
export function refPeluchan() {
  return window.peluchan as ReturnType<typeof usePeluchan>;
}
