import React from "react"
import { Scrolling, ScrollFetcherType } from "../../../../components/utils/Scrolling"
import { IFav, FindFavs } from "../../../../hooks/apis/useFavs"

export type LastIdInitProps = {
    pageSize: number,
    lastId?: string 
}
export type LastIdItemProps = {
    _id: string,
}

export type ScrollByLastIdProps = Omit<React.ComponentProps<typeof Scrolling>, "fetcher" | "initialSetting" | "children">  & {
    initialSetting: LastIdInitProps,
    fetcher: (props: LastIdInitProps)=> Promise<LastIdItemProps[]>,
    children: (items: unknown[])=> JSX.Element
}

export const ScrollByLastId:React.FC<ScrollByLastIdProps> = ({
    children, 
    fetcher, 
    initialSetting, 
    ...args
})=>{
    
    const newFetcher:ScrollFetcherType<LastIdInitProps, LastIdItemProps> = (params, state, dispatch)=>{
        fetcher(params).then((newItems)=>{
            dispatch({
                type: "GET_OK",
                newItems: newItems,
                updateSetting: newItems.length > 0?{
                    lastId: newItems[newItems.length-1]._id
                }:{},
                hasMore: newItems.length === params.pageSize 
            })
        }).catch(()=> 
            dispatch({type: "GET_ERROR"})
        )
    }

    return <Scrolling 
        {...args}
        initialSetting={initialSetting} 
        fetcher={newFetcher} 
        children={children}
    />
}