export function deleteFirstMatch<T>(arr:T[], predicate:(t:T, i:number, arr:T[])=> boolean) {
    for (let i = 0; i < arr.length; i++) {
      if (predicate(arr[i], i, arr)) {
        arr.splice(i, 1);
        break;
      }
    }
    return arr;
  }
  
//   // Ejemplo de uso:
//   const array = [1, 2, 3, 4, 5];
//   const result = deleteFirstMatch(array, (element) => element === 3);
//   console.log(result); // [1, 2, 4, 5]
  