import { useMediaQuery } from 'react-responsive'

class MScreen{
    isDesktop = null;
    isTablet = null;
    isMobile = null;
    isNotMobile = null;
    constructor(auto = true){
        if(!auto) return;
        this.isDesktop = useMediaQuery({ minWidth: 992 });
        this.isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
        this.isMobile = useMediaQuery({ maxWidth: 767 });
        this.isMobileMicro = useMediaQuery({ maxWidth: 500 });
        this.isMobileNano = useMediaQuery({ maxWidth: 400 });
        this.isNotMobile = useMediaQuery({ minWidth: 768 });
    }
    media({min=0,max=0}){
        return useMediaQuery({ minWidth: min, maxWidth: max });
    }
    min(nun){
        return useMediaQuery({ minWidth: nun });
    }
    max(nun){
        return useMediaQuery({ maxWidth: nun });
    }
}

export default MScreen;