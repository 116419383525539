import React from "react";
import { useRef } from "react";
import { request, useResource } from "react-request-hook";
import { useCssHandles } from "../../../hooks/useCssHandles";
import type { MedallaType, Niveles } from "./types";
import cn from "classnames";
import { Medalla } from "./medalla";
import { ApiType } from "../../../hooks/apis/useApi";
import { useSWP } from "../../../hooks/apis/useSWP";
import { LazyState } from "../../../components/utils/LazyState";
import { encode_query } from "../../../api";

function Placeholder2() {
    const sizes = useRef({
        size0: Math.random() * 30
    });
    return <div className="lazy-loading mr3 dib" style={{
        width: `${30 + sizes.current.size0}px`,
        height: "20px",
        borderRadius:"3px"
    }}/>;
}
function Placeholder() {
    const sizes = useRef({
        size1: Math.random() * 5
    });
    return <div className="flex-inline">
        {Array(10).fill(0).map(()=><Placeholder2/>)}
    </div>
}

const useMedallas = function({user_id, debs}:{user_id:string, debs:any[]}){
    const url = encode_query("/api/user/medallas", {user_id})
    const {data, isLoading} = useSWP<MedallaType[]>([url, ...debs]);
    return [data, isLoading]  as [typeof data, typeof isLoading]
}

export const Medallas: React.FC<{
    className?:string, 
    conteiner?: (children: JSX.Element)=> JSX.Element;
    params: Parameters<typeof useMedallas>[0]
}> = ({
    className="", 
    conteiner,
    params
})=>{
    const styles = useCssHandles("tags", [
        "item"
    ] as const);
    const [names, loading] = useMedallas(params);
    var CONTENT: any = <Placeholder/>;

    if(names) {
        if(names.length > 0) CONTENT = names.map((t,i)=> 
            <Medalla value={t} key={i} className={cn("dib")}/> 
        )
        else CONTENT = (
            <div className="center" style={{color: "#aaa"}}>Nada por aqui</div>
        );
    }

    const CONTENT_FINAL = <div className={cn(className, "flex gap-x-1.5 gap-y-2 flex-wrap", styles.ROOT)}>
        {CONTENT}
    </div>;

    if(loading || names?.length === 0) return null;

    if(conteiner) return conteiner(CONTENT_FINAL);

    return CONTENT_FINAL
}